const CASE = 'case'
const MODE = 'mode';
const DEVICE = 'device';
const ACTIVE = 'active';
const METRIC = 'metric';
const CURRENT = 'current';
const PATIENT = 'patient';
const RESOLVE = 'resolve';
const DEVICE_V2 = 'deviceV2';
const CLEAR_MSG = 'clear-message';
const NORMAL_MODE = 'normal-mode';

export const API_ROUTES = {
  CASE: {
    CREATE_CASE: `/${CASE}`,
    GET_CASE_BY_ID: (patientId: string, caseId: string) => 
      `/${PATIENT}/${patientId}/${CASE}/${caseId}`,
    GET_CURRENT_CASE: (patientId: string) => `/${PATIENT}/${patientId}/${CASE}/${CURRENT}`,
    GET_ALL_ACTIVE_CASES: `/${CASE}/${ACTIVE}`,
    GET_ALL_PATIENT_CASES: (patientId: string) => `/${PATIENT}/${patientId}/${CASE}`,
    RESOLVE_CASE: (patientId: string) => `/${PATIENT}/${patientId}/${CASE}/${RESOLVE}`,
    UPDATE_CASE: (caseId: string) => `/${CASE}/${caseId}`,
    NOTIFICATION: `/notification`
  },
  DEVICE: {
    NEW: `/${DEVICE}`,
    NEW_V2: `/${DEVICE_V2}`,
    GET_DEVICES: `/${DEVICE}`,
    GET_DEVICES_V2: `/${DEVICE_V2}`,
    GET_DEVICE_BY_ID: (deviceId: string) => `/${DEVICE_V2}/${deviceId}`,
    DELETE_DEVICE_BY_ID: (deviceId: string) => `/${DEVICE}/${deviceId}`,
    GET_CURRENT_CASE_BY_DEVICE_ID: (deviceId: string) => `/${DEVICE}/${deviceId}/${CASE}`,
    SWITCH_SENSOR_MODE: (deviceId: string) => `/${DEVICE}/${deviceId}/${MODE}`,
    CHANGE_NORMAL_MODE: (deviceId: string) => `/${DEVICE}/${deviceId}/${NORMAL_MODE}`,
    DELETE_ERROR: (deviceId: string) => `/${DEVICE_V2}/${deviceId}/${CLEAR_MSG}`,
  },
  METRIC: {
    GET_HISTORY: (metricName: string, patientId: string, caseId: string) => 
      `/${METRIC}/${PATIENT}/${patientId}/${CASE}/${caseId}/${metricName}`,
    GET_AGGREGATED_HISTORY: (caseId: string) =>
      `/${METRIC}/${CASE}/${caseId}/aggregated`,
    GET_NON_AGGREGATED_HISTORY: (caseId: string) =>
      `/${METRIC}/${CASE}/${caseId}/non-aggregated`,
    LAST_HOUR_METRICS: (caseId: string) => `/analytics/${caseId}`,
  },
  USER: {
     SETTINGS: `/user-settings`,
     CHART_SETTINGS: `/chart-settings`,
  }
};
