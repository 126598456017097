import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Fab } from '../../../../lib/components';
import { useHistory } from 'react-router-dom';
import { NavRoute } from '../../../../lib/constants/nav-route.constant';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'absolute',
      right: 15, 
      bottom: 15
    }
  })
);

const CreateCaseFlow: FunctionComponent = memo(() => {
  const styles = useStyles();
  const history = useHistory();

  const navigateToCreateFlow = () => {
    history.push({
      pathname: NavRoute.MAIN.CREATE,
    })
  }
  
  return (
    <div className={styles.container}>
      <Fab onClick={navigateToCreateFlow}>
        <AddIcon />
      </Fab>
    </div>
  );
});

export default CreateCaseFlow;
