import React, {memo, FunctionComponent} from 'react';
import { CompanyLogo, FormContainer } from '../../../lib/components';
import SignInForm from './components/SignInForm';
import { useHistory } from 'react-router-dom';
import { SignInFormValues } from './interfaces/form-values.interface';
import { authService } from '../../../services';
import { take } from 'rxjs/operators';
import { CognitoUserExtended } from '../../../lib/models/cognito-user';
import { NavRoute } from '../../../lib/constants/nav-route.constant';

const SignIn: FunctionComponent = memo(() => {
  const history = useHistory();

  const submitForm = (values: SignInFormValues, onComplete: () => void) => {
    const { email, pass } = values;

    authService.signInProcess(email, pass).pipe(
      take(1)
    ).subscribe({
      next: (user: CognitoUserExtended | null) => {
        if (user) {
          authService.storeUser(user);
          history.push(NavRoute.AUTH.CONFIRM_PASSWORD);
        } else {
          history.push(NavRoute.MAIN.INIT);
        }
      },
      error: () => onComplete(),
      complete: () => {
        onComplete();
      }
    });
  };

  return (
    <FormContainer>
      <CompanyLogo />
      <SignInForm history={history} submitForm={submitForm} />
    </FormContainer>
  );
});

export default SignIn;
