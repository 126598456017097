import React, { FunctionComponent } from 'react';
import {makeStyles, createStyles} from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
    },
    input: {
      width: '100%',
      height: 40,
      paddingLeft: 10,
      paddingRight: 10,
      font: 'inherit',
      fontSize: 14,
      fontWeight: 600,
      borderRadius: 5,
      borderWidth: 1,
      borderStyle: 'solid',
      outline: 'none',
    },
    label: {
      fontSize: 11,
      fontWeight: 600,
      marginBottom: 5,
    },
    errorMessage: {
      marginTop: 2,
      fontSize: 11,
      fontWeight: 600,
    }
  })
);

interface Props {
  label: string;
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  isValid?: boolean;
  errorMessage?: string;
  borderColor?: string;
  textColor?: string;
  labelColor?: string;
  backgroundColor?: string;
  invalidColor?: string;
}

const FormInput: FunctionComponent<Props> = (
  {
    label,
    value,
    onChange,
    disabled = false,
    errorMessage,
    borderColor = 'black',
    textColor = 'black',
    labelColor = 'black',
    backgroundColor = 'white',
    isValid = true,
    invalidColor = 'red'
  }) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.label} style={{
        color: isValid ? labelColor : invalidColor
      }}>{label}</div>
      <input
        className={styles.input}
        disabled={disabled}
        style={{
          color: isValid ? textColor : invalidColor,
          borderColor: isValid ? borderColor : invalidColor,
          backgroundColor
      }}
        value={value}
        onChange={onChange ? (event) => {
          onChange(event.target.value)
        } : undefined}
      />
      <div className={styles.errorMessage} style={{color: invalidColor}}>{errorMessage ? errorMessage : '\u00A0'}</div>
    </div>
  );
};

export default FormInput;
