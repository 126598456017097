import { TableData } from './../interfaces/table-data.interface';
import { Order } from './../interfaces/order.type';
import { descendingComparator, normalDescendingComparator } from './descending-comparator';

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: TableData }, b: { [key in Key]: TableData }) => number {  
  return order === 'desc'
    ? (a, b) => descendingComparator(a[orderBy], b[orderBy])
    : (a, b) => -descendingComparator(a[orderBy], b[orderBy]);
}

export function getNormalComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {  
  return order === 'desc'
    ? (a, b) => normalDescendingComparator(a[orderBy], b[orderBy])
    : (a, b) => -normalDescendingComparator(a[orderBy], b[orderBy]);
}

