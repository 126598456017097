import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EN from './lib/constants/translate/en.json';

const resources = {
  en: {translation: EN},
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    // keySeparator: false, // this was the line that I've had to remove to make it work
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
