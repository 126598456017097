import { makeStyles, createStyles } from "@material-ui/core";
import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { colors } from "../../../../theme/colors";
import useUserSettings from "../../../../hooks/useUserSettings";
import {
  getUnitForHistoryChart,
} from "../MetricsWrapper/LastHourChart/utils";
import { MetricsDropdown } from "../../../../lib/interfaces/metric-history.interface";
import { useContext } from "react";
import { AppContext } from "../../../../state/AppContext";
import { themeCss } from "../../../../lib/utils/theme";
import {MetricEnumToMetricName, MetricsFilterName} from "../../../../lib/constants/metrics-name.enum";
import {formatAggregation} from "../../../../lib/utils/format-date";
import {getNumericMetricValue} from "../../../../lib/utils/get-metric-value";

interface Props {
  metrics: MetricsDropdown;
  savedSelectedAggregationKey: string;
}

const CustomTooltip = ({
  active,
  payload,
  metrics,
  savedSelectedAggregationKey
}: TooltipProps<ValueType, NameType> & Props) => {
  const { theme } = useContext(AppContext);

  const useStyles = makeStyles(() =>
    createStyles({
      chartPopup: {
        border: `1px solid ${colors[themeCss(theme)].chartPopupBorder}`,
        backgroundColor: colors[themeCss(theme)].chartPopupBackground,
        borderRadius: 3,
        fontSize: 10,
        fontWeight: 400,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 9,
        paddingBottom: 9,
        color: colors[themeCss(theme)].chartPopupFontColor,
        display: "flex",
        flexDirection: "column",
        gap: 5,
      },
      chartPopupDate: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
      },
      chartPopupDateText: {
        fontWeight: 600,
        fontSize: 10,
        '&:first-child': {
          marginRight: 5,
        }
      },
      chartPopupValue: {
        display: "flex",
        gap: 6,
        alignItems: "center",
      },
      chartPopupDot: {
        width: 7,
        height: 7,
        borderRadius: "50%",
        display: "inline-block",
      },
      'chartPopupDot--heart-rate': {
        backgroundColor: colors[themeCss(theme)].heartRate
      },
      'chartPopupDot--respiration-rate': {
        backgroundColor: colors[themeCss(theme)].respirationRate
      },
      'chartPopupDot--chest-expansion': {
        backgroundColor: colors[themeCss(theme)].chestExpansion
      },
      'chartPopupDot--body-temperature': {
        backgroundColor: colors[themeCss(theme)].bodyTemperature
      },
      'chartPopupDot--oxygen-saturation-level': {
        backgroundColor: colors[themeCss(theme)].oxygenSaturationLevel
      },
      chartPopupValueNumber: {
        fontWeight: 600,
      },
      chartPopupMessage: {
        fontSize: 8,
      },
    })
  );

  const styles = useStyles();
  const userSettings = useUserSettings();

  if (active && payload && payload.length) {
    const timestamp = payload[0].payload.time;
    return (
      <div className={styles.chartPopup}>
        <div className={styles.chartPopupDate}>
          <span className={styles.chartPopupDateText}>{formatAggregation(timestamp, savedSelectedAggregationKey)}</span>
        </div>
        {
          Object.keys(metrics).map((item, index) => {
            const rawValue = index === 0 ?  payload[index].payload.value : payload[index].payload.value2;
            const value = rawValue === null ? '' : getNumericMetricValue(MetricEnumToMetricName[item], rawValue);
            /*@ts-ignore*/
            const metricName = savedSelectedAggregationKey === 'none' ? `${MetricsFilterName[item]}:` : `${MetricsFilterName[item]} (AVG):`;
            return (
              <div key={index}>
                <div className={styles.chartPopupValue}>
                  {/*@ts-ignore*/}
                  <div className={`${styles.chartPopupDot} ${styles[`chartPopupDot--${item}`]}`}></div>
                  <div>{metricName}</div>
                  <div className={styles.chartPopupValueNumber}>
                    {value}{" "}
                    {getUnitForHistoryChart(item, userSettings)}
                  </div>
                </div>
                <div className={styles.chartPopupMessage}>{payload[index].payload.message}</div>
              </div>
            )
          })
        }
      </div>
    );
  }

  // without this div the first tooltip render will be in top left corner
  return <div>Empty</div>;
};

export default CustomTooltip;
