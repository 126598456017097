import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import MUIPopover, { PopoverOrigin, PopoverPosition } from '@material-ui/core/Popover';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: 16
    }
  })
);


const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
} as PopoverOrigin;
const transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
} as PopoverOrigin;

interface Props {
  open: boolean;
  onClose: () => void;
  anchorPosition?: PopoverPosition
  anchorEl?: null | Element | ((element: Element) => Element);
}

const Popover: FunctionComponent<Props> = memo(({ children, open, onClose, anchorEl, anchorPosition }) => {
  const styles = useStyles();

  return (
    <MUIPopover
      classes={{
        root: styles.root
      }}
      open={open}
      // anchorReference="anchorPosition"
      // anchorPosition={anchorPosition || { top: 0, left: 0 }}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      { children }
    </MUIPopover>
  );
});

export default Popover;
