import React, {memo, FunctionComponent, FocusEvent, ChangeEvent} from 'react';
import { makeStyles, createStyles, InputBase, Theme } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: 16,
      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.15)',
      clipPath: 'inset(0px 0px -6px 0px)',
      fontSize: 18,
      height: 64,
    },
    searchIcon: {
      paddingRight: 16
    }
  })
);


interface Props {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  defaultValue?: string;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

const SearchField: FunctionComponent<Props> = memo(({
  placeholder, defaultValue, onChange, value, onBlur
}) => {
  const styles = useStyles();

  return (
    <InputBase
      className={styles.root}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={onChange}
      inputProps={{ 'aria-label': 'naked' }}
      startAdornment={
        <div className={styles.searchIcon}>
          <SearchIcon />
        </div>
      }
    />
  );
});

export default SearchField;
