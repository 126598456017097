import React, {memo, FunctionComponent, useEffect, useState} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { NewPatientForm } from '../../../../lib/interfaces/new-patient-form.interface';
import { TFunction } from 'i18next';
import { object as yupObject, string as yupString, number as yupNumber, ref } from 'yup';
import { TextField, Typography } from '../../../../lib/components';
import { formSubmission } from '../../../../lib/utils/form-submission';
import { Formik, FormikProps } from 'formik';
import { Select } from '../../../../lib/components';
import SelectDeviceComponent from '../SelectDeviceComponent';
import ThresholdTextField from "./ThresholdTextField";
import Button from "../../../redesign/common/Button";
import useRequestUserSettings from "../../../../hooks/useRequestUserSettings";
import useUserSettings from "../../../../hooks/useUserSettings";
import {ImperialExtension, MetricExtension, UnitsExtension} from "../../../../lib/constants/value-extension.constant";
import {MeasurementFormat} from "../../../../lib/interfaces/user-settings.interface";
import {deviceService} from "../../../../services";
import {Device} from "../../../../lib/interfaces/device.interface";

const useStyles = makeStyles(() =>
  createStyles({
    columnsWrapper: {
      display: 'flex',
      gap: 50,
      justifyContent: 'space-between',
    },
    column: {
      flex: 1,
    },
    item: {
      marginBottom: 24
    },
    input: {
      width: '100%',
    },
    title: {
      paddingTop: 11,
      paddingBottom: 12,
      fontSize: 18,
      fontWeight: 400
    },
    text: {
      paddingTop: 11,
      paddingBottom: 12,
      fontSize: 16,
      fontWeight: 400
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 16,
      paddingBottom: 16
    },
    button: {
      width: 160
    },
    thresholds: {
      fontSize: 16,
    },
    bold: {
      fontWeight: 600
    }
  })
);

interface Props {
  submitForm: (values: NewPatientForm, event: () => void) => void;
}

function createMinimumField(regexp: RegExp) {
  return yupString()
    .matches(regexp, "Wrong format")
    .when('max', {
      is: max => max !== undefined && max !== '' && max !== null,
      then: yupString().required().test('is-less', 'Must be less than max', function (value) {
        return Number(value) < Number(this.resolve(ref('max')));
      }),
      otherwise: yupString()
    })
    .min(0)
}

function createMaximumField(regexp: RegExp) {
  return yupString()
    .matches(regexp, "Wrong format")
    .when('min', {
      is: min => min !== undefined && min !== '' && min !== null,
      then: yupString().required().test('is-greater', 'Must be greater than min', function (value) {
        return Number(value) > Number(this.resolve(ref('min')));
      }),
      otherwise: yupString()
    })
    .min(0)
}

const validationSchema = (t: TFunction) => yupObject().shape({
  firstName: yupString()
    .min(2, t('validation.minLength', {num: 2}))
    .required(t('validation.firstNameRequired')),
  lastName: yupString()
    .min(2, t('validation.minLength', {num: 2}))
    .required(t('validation.lastNameRequired')),
  patientId: yupString()
    .required(t('validation.patientIdRequired'))
    .matches(
      /^[a-zA-Z0-9]*$/, //todo: extract to constant
      t('validation.onlyLettersAndNumbersAreAllowed')
    ),
  sex: yupString()
    .required(t('validation.sexRequired')),
  age: yupNumber()
    .required(t('validation.ageRequired'))
    .min(0)
    .max(150),
  deviceId: yupString()
    .required(t('validation.deviceNameRequired')),
  pulseRate: yupObject().shape({
    min: createMinimumField(/^\d+$/),
    max: createMaximumField(/^\d+$/)
  }, [['min', 'max']]),
  bodyTemperature: yupObject().shape({
    min: createMinimumField(/^\d+(\.\d{1,2})?$/),
    max: createMaximumField(/^\d+(\.\d{1,2})?$/)
  }, [['min', 'max']]),
  respiratoryRate: yupObject().shape({
    min: createMinimumField(/^\d+$/),
    max: createMaximumField(/^\d+$/)
  }, [['min', 'max']]),
  oxygenSaturationLevel: yupObject().shape({
    min: createMinimumField(/^\d+$/),
    max: createMaximumField(/^\d+$/)
  }, [['min', 'max']]),
});

const CreatePatientForm: FunctionComponent<Props> = memo(({ submitForm }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  useRequestUserSettings();
  const userSettings = useUserSettings();
  const [availableDevices, setAvailableDevices] = useState<Device[]|null>(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string|null>(null);
  const selectedDevice = availableDevices?.find(device => device.attribute === selectedDeviceId) || null;

  useEffect(() => {
    const sub = deviceService.getAvailableDevices().subscribe(setAvailableDevices)
    return () => sub.unsubscribe();
  }, []);

  const extensions: Record<MeasurementFormat, UnitsExtension> = {
    metric: MetricExtension,
    imperial: ImperialExtension
  }

  let extension= userSettings ? extensions[userSettings.measurementFormat] : MetricExtension;

  const renderForm = ({
    values,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
    isSubmitting,
    isValid,
    setSubmitting
  }: FormikProps<NewPatientForm>) => {
    const onBlur = (name: string) => () => setFieldTouched(name);
    const onChangeText = (name: string) => (value: string) => {
      return setFieldValue(name, value)
    };
    const keyboardSubmit = () => {
      if (isValid) {
        setSubmitting(true);
        submitForm(values, () => setSubmitting(false));
      }
    };
    const sexList = ['male', 'female', 'unknown'].map(sex => ({value: sex, text: t(`sex.${sex}`)}));
    const isSubmittingDisabled = Object.keys(touched).length === 0 || isSubmitting || !isValid;
    const buttonProps = isSubmittingDisabled ?
      {
        backgroundColor: '#DBDBDB',
        borderColor: '#DBDBDB',
        fontColor: '#404040'
      } : {
        backgroundColor: '#00A1E6',
        borderColor: '#00A1E6',
        fontColor: '#FFFFFF'
      };
    return (
      <>
        <form onSubmit={keyboardSubmit}>
          <div>
            <div className={styles.columnsWrapper}>
              {/*Content with two columns*/}
              <div className={styles.column}>
                {/*Left column*/}
                <Typography className={styles.title}>{t('header.givePatientInformation')}</Typography>
                <div className={styles.item}>
                  <TextField
                    className={styles.input}
                    label={t('placeholder.firstName')}
                    onChange={onChangeText('firstName')}
                    onBlur={onBlur('firstName')}
                    value={values.firstName}
                    error={Boolean(touched.firstName && errors.firstName)}
                    disabled={isSubmitting}
                    required={true}
                    helperText={(touched.firstName && errors.firstName) ? errors.firstName : undefined}
                  />
                </div>

                <div className={styles.item}>
                  <TextField
                    className={styles.input}
                    label={t('placeholder.lastName')}
                    onChange={onChangeText('lastName')}
                    onBlur={onBlur('lastName')}
                    value={values.lastName}
                    error={Boolean(touched.lastName && errors.lastName)}
                    disabled={isSubmitting}
                    required={true}
                    helperText={(touched.lastName && errors.lastName) ? errors.lastName : undefined}
                  />
                </div>

                <div className={styles.item}>
                  <TextField
                    className={styles.input}
                    label={t('placeholder.patientId')}
                    onChange={onChangeText('patientId')}
                    onBlur={onBlur('patientId')}
                    value={values.patientId}
                    error={Boolean(touched.patientId && errors.patientId)}
                    disabled={isSubmitting}
                    required={true}
                    helperText={(touched.patientId && errors.patientId) ? errors.patientId : undefined}
                  />
                </div>

                <div className={styles.item}>
                  <div>
                    <Select
                      elements={sexList}
                      placeholder={t('placeholder.sex')}
                      value={String(values.sex)}
                      onChange={onChangeText('sex')}
                      required
                      disabled={isSubmitting}
                      error={Boolean(touched.sex && errors.sex)}
                      helperText={(touched.sex && errors.sex) ? errors.sex : undefined}
                    />
                  </div>
                </div>

                <div className={styles.item}>
                  <TextField
                    className={styles.input}
                    label={t('placeholder.age')}
                    onChange={onChangeText('age')}
                    type={'number'}
                    onBlur={onBlur('age')}
                    value={String(values.age)}
                    error={Boolean(touched.age && errors.age)}
                    disabled={isSubmitting}
                    required={true}
                    helperText={(touched.age && errors.age) ? errors.age : undefined}
                  />
                </div>
              </div>
              <div className={styles.column}>
                {/*Right column*/}
                <Typography className={styles.title}>{t('header.fillInPatientAlertThresholds')}</Typography>
                <Typography className={styles.text}>{t('text.fillInPatientAlertThresholds')}</Typography>
                <table className={styles.thresholds}>
                  <tbody>
                    <tr>
                      <td></td>
                      <td className={styles.bold}>Min</td>
                      <td></td>
                      <td className={styles.bold}>Max</td>
                      <td></td>
                    </tr>
                    {/*Pulse Rate*/}
                    <tr>
                      <td className={styles.bold}>Pulse Rate:</td>
                      <td>
                        <ThresholdTextField
                          onChange={onChangeText('pulseRate.min')}
                          onBlur={onBlur('pulseRate.min')}
                          value={values.pulseRate.min}
                          error={Boolean((touched.pulseRate?.min || touched.pulseRate?.max) && errors.pulseRate?.min)}
                          disabled={isSubmitting}
                        />
                      </td>
                      <td>-</td>
                      <td>
                        <ThresholdTextField
                          onChange={onChangeText('pulseRate.max')}
                          onBlur={onBlur('pulseRate.max')}
                          value={values.pulseRate.max}
                          error={Boolean((touched.pulseRate?.max || touched.pulseRate?.min) && errors.pulseRate?.max)}
                          disabled={isSubmitting}
                        />
                      </td>
                      <td>{extension.hr}</td>
                    </tr>
                    {/*Body Temperature*/}
                    <tr>
                      <td className={styles.bold}>Skin Temperature:</td>
                      <td>
                        <ThresholdTextField
                          onChange={onChangeText('bodyTemperature.min')}
                          onBlur={onBlur('bodyTemperature.min')}
                          value={values.bodyTemperature.min}
                          error={Boolean((touched.bodyTemperature?.min || touched.bodyTemperature?.max) && errors.bodyTemperature?.min)}
                          disabled={isSubmitting}
                        />
                      </td>
                      <td>-</td>
                      <td>
                        <ThresholdTextField
                          onChange={onChangeText('bodyTemperature.max')}
                          onBlur={onBlur('bodyTemperature.max')}
                          value={values.bodyTemperature.max}
                          error={Boolean((touched.bodyTemperature?.max || touched.bodyTemperature?.min) && errors.bodyTemperature?.max)}
                          disabled={isSubmitting}
                        />
                      </td>
                      <td>{extension.bt}</td>
                    </tr>
                    {/*Respiration Rate*/}
                    <tr>
                      <td className={styles.bold}>Respiration Rate:</td>
                      <td>
                        <ThresholdTextField
                          onChange={onChangeText('respiratoryRate.min')}
                          onBlur={onBlur('respiratoryRate.min')}
                          value={values.respiratoryRate.min}
                          error={Boolean((touched.respiratoryRate?.min || touched.respiratoryRate?.max) && errors.respiratoryRate?.min)}
                          disabled={isSubmitting}
                        />
                      </td>
                      <td>-</td>
                      <td>
                        <ThresholdTextField
                          onChange={onChangeText('respiratoryRate.max')}
                          onBlur={onBlur('respiratoryRate.max')}
                          value={values.respiratoryRate.max}
                          error={Boolean((touched.respiratoryRate?.max || touched.respiratoryRate?.min) && errors.respiratoryRate?.max)}
                          disabled={isSubmitting}
                        />
                      </td>
                      <td>{extension.rr}</td>
                    </tr>
                    {/*Oxygen Saturation Level*/}
                    {selectedDevice && selectedDevice.type === 'eBeat' && <tr>
                      <td className={styles.bold}>Oxygen Saturation Level:</td>
                      <td>
                        <ThresholdTextField
                          onChange={onChangeText('oxygenSaturationLevel.min')}
                          onBlur={onBlur('oxygenSaturationLevel.min')}
                          value={values.oxygenSaturationLevel.min}
                          error={Boolean((touched.oxygenSaturationLevel?.min || touched.oxygenSaturationLevel?.max) && errors.oxygenSaturationLevel?.min)}
                          disabled={isSubmitting}
                        />
                      </td>
                      <td>-</td>
                      <td>
                        <ThresholdTextField
                          onChange={onChangeText('oxygenSaturationLevel.max')}
                          onBlur={onBlur('oxygenSaturationLevel.max')}
                          value={values.oxygenSaturationLevel.max}
                          error={Boolean((touched.oxygenSaturationLevel?.max || touched.oxygenSaturationLevel?.min) && errors.oxygenSaturationLevel?.max)}
                          disabled={isSubmitting}
                        />
                      </td>
                      <td>{extension.spo2}</td>
                    </tr>}
                  </tbody>
                </table>
                <Typography className={styles.text}>{t('text.skipThresholds')}</Typography>
                <Typography className={styles.title}>{t('header.assignDevice')}</Typography>
                <div>
                  <SelectDeviceComponent
                    availableDevices={availableDevices}
                    onChange={(value) => {
                      setSelectedDeviceId(value);
                      const selectedDevice = availableDevices?.find(device => device.attribute === value);
                      if (selectedDevice && selectedDevice.type === 'eDoctor') {
                        onChangeText('oxygenSaturationLevel.min')('');
                        onChangeText('oxygenSaturationLevel.max')('');
                      }
                      return onChangeText('deviceId')(value);
                    }}
                    required
                    disabled={isSubmitting}
                    error={Boolean(touched.deviceId && errors.deviceId)}
                    helperText={(touched.deviceId && errors.deviceId) ? errors.deviceId : undefined}
                  />
                </div>
                {/*Submit button*/}
                <div className={styles.buttonWrapper}>
                  <Button
                    backgroundColor={buttonProps.backgroundColor}
                    borderColor={buttonProps.borderColor}
                    fontColor={buttonProps.fontColor}
                    title={'Submit'}
                    disabled={isSubmittingDisabled}
                    width={161}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    )
  };

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        sex: '',
        age: '',
        patientId: '',
        deviceId: '',
        pulseRate: {
          min: '',
          max: ''
        },
        bodyTemperature: {
          min: '',
          max: ''
        },
        respiratoryRate: {
          min: '',
          max: ''
        },
        oxygenSaturationLevel: {
          min: '',
          max: ''
        }
      }}
      onSubmit={formSubmission<NewPatientForm>(submitForm)}
      validationSchema={validationSchema(t)}
    >
      {renderForm}
    </Formik>
  );
});

export default CreatePatientForm;
