import { createStyles, makeStyles } from "@material-ui/core";
import React, {useContext, useEffect, useState} from "react";
import {
  AggregationDropdown,
  ChartPoint, MergedChartPoint,
  MetricResponse,
  MetricsDropdown, SecondChartPoint,
} from "../../../../../lib/interfaces/metric-history.interface";
import { colors } from "../../../../../theme/colors";
import moment from "moment";
import {authService, caseService, metricService} from "../../../../../services";
import ChartFilters from "../../../../../lib/components/ChartFilters";
import { Case } from "../../../../../lib/interfaces/case.interface";
import LoadingDataSpinner from "../../../../redesign/common/LoadingDataSpinner";
import NoDataAvailable from "../NoDataAvailable";
import { UserSettings } from "../../../../../lib/interfaces/user-settings.interface";
import {
  getMetricColorHistory,
  getUnitForHistoryChart,
} from "../LastHourChart/utils";
import { AppContext } from "../../../../../state/AppContext";
import {AggregateOptions} from "../../../../../lib/constants/aggregation.enum";
import { themeCss } from "../../../../../lib/utils/theme";
import {MetricEnumToMetricName, MetricsFilterName} from "../../../../../lib/constants/metrics-name.enum";
import MetricTable from "./MetricTable";
import {
  DATE_TIME_WITH_MONTH_FULL_FORMAT,
  DAY_WITH_MONTH_FORMAT,
  TIME_FORMAT
} from "../../../../../lib/constants/date-format.constant";
import NoChartDataIcon from "../../../../../theme/icons/NoChartDataIcon";
import {getNumericMetricValue} from "../../../../../lib/utils/get-metric-value";
import {from, Observable, of, Subscription} from "rxjs";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {sortByDate} from "../../../../../lib/utils/stable-sort";
import {convertToMillis, generateTimestampsForDays, generateTimestampsForHours} from "../history-chart-utils";
import OldChart, {ChartData} from "./OldChart";

type Props = {
  patientCase: Case;
  userSettings: UserSettings | null;
  isTableOpened: boolean;
};

type DeviceChartSettings = {
  selectedMetrics: MetricsDropdown;
  startDate: Date;
  endDate: Date;
  selectedAggregation: AggregationDropdown;
};

const HistoryChart = ({ patientCase, userSettings, isTableOpened }: Props) => {
  const { theme, setCases } = useContext(AppContext);
  const [response, setResponse] = useState<MetricResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [savedStartDate, setSavedStartDate] = useState<Date | null>(null);
  const [savedEndDate, setSavedEndDate] = useState<Date | null>(null);
  const [applyDefaultData, setApplyDefaultData] = useState<boolean>(false);
  const [selectedMetrics, setSelectedMetrics] = useState({
    "body-temperature": "Skin Temperature",
  } as MetricsDropdown);
  const [savedSelectedMetrics, setSavedSelectedMetrics] = useState({
    "body-temperature": "Skin Temperature",
  } as MetricsDropdown);
  const [selectedAggregation, setSelectedAggregation] = useState<AggregationDropdown>({
    key: "hour",
    value: "Hourly",
  });
  const [savedSelectedAggregation, setSavedSelectedAggregation] = useState<AggregationDropdown>({
    key: "hour",
    value: "Hourly",
  });


  const useStyles = makeStyles(() =>
    createStyles({
      container: {
        display: "flex",
        flex: 1,
        gap: 31,
      },
      chartContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 31,
        position: "relative",
        flex: 1,
      },
      chartBody: {
        flex: 1,
        backgroundColor: colors[themeCss(theme)].chartBackground,
      },
      chartFooter: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between'
      },
      chartFooterBlock: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'flex-start'
      },
      metricRectangle: {
        width: 16,
        height: 16,
        marginRight: 6,
        marginTop: -2,
        borderRadius: 5,
        backgroundColor: colors[themeCss(theme)].heartRate
      },
      'metricRectangle--heart-rate': {
        backgroundColor: colors[themeCss(theme)].heartRate
      },
      'metricRectangle--respiration-rate': {
        backgroundColor: colors[themeCss(theme)].respirationRate
      },
      'metricRectangle--chest-expansion': {
        backgroundColor: colors[themeCss(theme)].chestExpansion
      },
      'metricRectangle--body-temperature': {
        backgroundColor: colors[themeCss(theme)].bodyTemperature
      },
      'metricRectangle--oxygen-saturation-level': {
        backgroundColor: colors[themeCss(theme)].oxygenSaturationLevel
      },
      metricLegendName: {
        fontSize: 15,
        fontWeight: 600,
        marginRight: 10,
        color: colors[themeCss(theme)].textColor,
      },
      metricTableContainer: {
        width: 296,
        position: "relative",
      },
      metricTableWrapper: {
        height: '100%',
        position: 'relative',
        overflowY: 'auto',
        display: 'flex',

        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-track': {
          background: colors[themeCss(theme)].metricTable.scrollbarBackground,
        },
        '&::-webkit-scrollbar-thumb': {
          background: colors[themeCss(theme)].metricTable.scrollbarColor,
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: colors[themeCss(theme)].metricTable.scrollbarColor,
        },
        scrollbarWidth: 'thin',
        scrollbarColor: `${colors[themeCss(theme)].metricTable.scrollbarColor} ${colors[themeCss(theme)].metricTable.scrollbarBackground}`,
      },
    })
  );

  const styles = useStyles();

  useEffect(() => {
    setIsLoading(true);
    fetchAndStoreChartSettings().subscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientCase.caseId]);

  useEffect(() => {
    fetchAndStoreMetrics({
      selectedMetrics: savedSelectedMetrics,
      startDate: savedStartDate as Date,
      endDate: savedEndDate as Date,
      selectedAggregation: savedSelectedAggregation
    }).subscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientCase.caseId, userSettings?.measurementFormat, applyDefaultData]);

  useEffect(() => {
    let sub: Subscription;
    const interval = setInterval(() => {
      sub = caseService
        .getCasesList()
        .pipe(
          map(sortByDate("initiationDate"))
        )
        .subscribe((cases) => {
          setCases(cases);
        });
    }, 60000);
    return () => {
      clearInterval(interval);
      if (sub) {
        sub.unsubscribe();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateChartSettingsData = () => {
    const metrics = patientCase.deviceType === 'eDoctor' ?
      { metricsEdoctor: Object.keys(selectedMetrics) } :
      { metricsEbeat: Object.keys(selectedMetrics) };

    return authService.updateChartSettings({
      aggregationType: selectedAggregation.key,
      from: moment(startDate).format("YYYY-MM-DD"),
      to: moment(endDate).format("YYYY-MM-DD"),
      ...metrics
    })
  };

  /**
   * Convert date string to local date
   * @param dateString - date string, e.g. "2024-03-02"
   */
  function convertDateStringToLocalDate(dateString: string): Date {
    const dateParts = dateString.split('-').map(Number);
    return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
  }

  function fetchAndStoreChartSettings(): Observable<DeviceChartSettings | null> {
    return from(authService.getChartSettings()).pipe(
      map((chartSettings) => {
        const metricsField = patientCase.deviceType === 'eDoctor' ? 'metricsEdoctor' : 'metricsEbeat';
        const selectedMetrics = chartSettings[metricsField].reduce((prev: any, curr: string) => {
          return {
            ...prev,
            // @ts-ignore
            [curr]: MetricsFilterName[curr]
          }
        }, {});
        return {
          selectedMetrics,
          startDate: convertDateStringToLocalDate(chartSettings.from),
          endDate: convertDateStringToLocalDate(chartSettings.to),
          // @ts-ignore
          selectedAggregation: {key: chartSettings.aggregationType, value: AggregateOptions[chartSettings.aggregationType]}};
      }),
      tap(({selectedMetrics, startDate, endDate, selectedAggregation}) => {
        setSelectedMetrics(selectedMetrics);
        setSavedSelectedMetrics(selectedMetrics);
        setStartDate(startDate);
        setSavedStartDate(startDate);
        setEndDate(endDate);
        setSavedEndDate(endDate);
        // @ts-ignore
        setSelectedAggregation(selectedAggregation);
        // @ts-ignore
        setSavedSelectedAggregation(selectedAggregation);
        setApplyDefaultData(true);
      }),
      catchError(() => {
        setStartDate(new Date());
        setSavedStartDate(new Date());
        setEndDate(new Date());
        setSavedEndDate(new Date());
        setSelectedAggregation({
          key: "hour",
          value: "Hourly",
        });
        setSavedSelectedAggregation({
          key: "hour",
          value: "Hourly",
        });
        setSelectedMetrics({
          "body-temperature": "Skin Temperature",
        })
        setSavedSelectedMetrics({
          "body-temperature": "Skin Temperature",
        })
        setApplyDefaultData(true);
        setIsLoading(false);
        return of(null);
      })
    );
  }

  const fetchAndStoreMetrics = ({selectedMetrics, startDate, endDate, selectedAggregation}: DeviceChartSettings) => {
    return new Observable((observer) => {
      if (!patientCase || !applyDefaultData) {
        observer.complete();
        return;
      }
      const from = moment(startDate).format("YYYY-MM-DD");
      const to = moment(endDate).format("YYYY-MM-DD");
      const timezoneOffset = -new Date().getTimezoneOffset();
      setIsLoading(true);
      if (selectedAggregation.key !== 'none') {
        metricService.getAggregatedHistory(
          patientCase.caseId,
          from,
          to,
          Object.keys(selectedMetrics).join(";"),
          timezoneOffset,
          selectedAggregation.key,
        ).subscribe({
          next: (response) => {
            setIsLoading(false);
            setResponse(response);
            observer.next();
            observer.complete();
          },
          error: () => {
            setIsLoading(false);
            observer.error();
          }
        });
      } else {
        metricService.getNonAggregatedHistory(
          patientCase.caseId,
          from,
          to,
          Object.keys(selectedMetrics).join(";"),
          timezoneOffset,
        ).subscribe({
          next: (response) => {
            setIsLoading(false);
            setResponse(response);
            observer.next();
            observer.complete();
          },
          error: () => {
            setIsLoading(false);
            observer.error();
          }
        });
      }
    });
  }

  function updateMetrics() {
    setIsLoading(true);
    from(updateChartSettingsData())
      .pipe(
        switchMap(() => fetchAndStoreChartSettings()),
        switchMap((deviceChartSettings) => {
          if (!deviceChartSettings) {
            return of(null);
          }
          return fetchAndStoreMetrics(deviceChartSettings);
        })
      )
      .subscribe({
        next: () => setIsLoading(false),
        error: () => setIsLoading(false)
      });
  }

  function deletePositionAndActivityIntensity(response: MetricResponse): MetricResponse {
    const metrics = response.metrics.filter(metric => !(metric.name === "Position" || metric.name === "ActivityIntensity"));
    return {
      ...response,
      metrics
    };
  }

  // we need remove Position and ActivityIntensity metrics from response because they are not displayed on the chart
  const responseWithChartMetrics = response ? deletePositionAndActivityIntensity(response) : null;

  const disabledKeys = ['position', 'activity-intensity'];
  let filteredSavedSelectedMetrics: MetricsDropdown = Object.keys(savedSelectedMetrics)
    .filter(key => !disabledKeys.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: savedSelectedMetrics[key]
      };
    }, {} as MetricsDropdown);

  const chartPoints: ChartPoint[] =
    responseWithChartMetrics === null || responseWithChartMetrics.metrics.length === 0
      ? []
      : responseWithChartMetrics.metrics[0].data.map((item) => {
          return {
            time: convertToMillis(item.datetime),
            value: item.value,
            message: item.chartTooltipMessage,
          };
        });

  let chartPoints2: SecondChartPoint[] = [];
  let min2 = 0, max2 = 0;
  if (Object.keys(filteredSavedSelectedMetrics).length > 1) {
    chartPoints2 =
      responseWithChartMetrics === null
        ? []
        : responseWithChartMetrics.metrics.length > 1 ? responseWithChartMetrics.metrics[1].data?.map?.((item) => {
          return {
            time: convertToMillis(item.datetime),
            value2: item.value,
            message: item.chartTooltipMessage,
          };
        }) : [];
  }

  const aggregationKey = savedSelectedAggregation.key;
  const xAxisTimestamps =
    responseWithChartMetrics === null
      ? []
      : aggregationKey === 'day' ? generateTimestampsForDays(responseWithChartMetrics.startPeriod, responseWithChartMetrics.endPeriod) : generateTimestampsForHours(responseWithChartMetrics.startPeriod, responseWithChartMetrics.endPeriod);

  const minValue = Math.min(
    ...chartPoints
      .filter((point) => point.value !== null)
      .map((point) => point.value as number)
  );

  const maxValue = Math.max(
    ...chartPoints
      .filter((point) => point.value !== null)
      .map((point) => point.value as number)
  );

  const max = +(maxValue + (maxValue * 0.05));
  const min = +(minValue - (minValue * 0.05));

  if (chartPoints2.length > 0) {
    const minValue2 = Math.min(
      ...chartPoints2
        .filter((point) => point.value2 !== null)
        .map((point) => point.value2 as number)
    );
    const maxValue2 = Math.max(
      ...chartPoints2
        .filter((point) => point.value2 !== null)
        .map((point) => point.value2 as number)
    );
    max2 = +(maxValue2 + (maxValue2 * 0.05));
    min2 = +(minValue2 - (minValue2 * 0.05));
  }

  let mergedChartPoints: MergedChartPoint[];
  if (chartPoints.length > 0) {
    mergedChartPoints = chartPoints.map((item, index) => ({
      ...item,
      ...chartPoints2[index],
    }));
  } else {
    mergedChartPoints = chartPoints2.map((item, index) => ({
      ...item,
      ...chartPoints[index],
    }));
  }

  const notNullPoints = mergedChartPoints.filter((point) => !((point.value === null) && (point.value2 === null)));
  const noDataAvailable = notNullPoints.length === 0;

  const renderFooterMetrics = () => {
    return Object.keys(filteredSavedSelectedMetrics).map((item: string, index: number) => {
      return (
        <div className={styles.chartFooterBlock} key={index}>
          {/*@ts-ignore*/}
          <div className={`${styles.metricRectangle} ${styles[`metricRectangle--${item}`]}`}/>
          <div className={styles.metricLegendName}>
            {filteredSavedSelectedMetrics[item]}
          </div>
        </div>
      )
    })
  }

  function formatDateTime(timestamp: number, selectedAggregation: string) {
    const formats : Record<string, string> = {
      'none': DATE_TIME_WITH_MONTH_FULL_FORMAT,
      'hour': DATE_TIME_WITH_MONTH_FULL_FORMAT,
      'day': DAY_WITH_MONTH_FORMAT,
    }
    return moment(timestamp).format(formats[selectedAggregation]) || moment(timestamp).format(formats['none']);
  }
  const wrappedXAxis = aggregationKey === 'hour' || aggregationKey === 'none';

  function getYAxisTickFormatter(metricKey: string) : (value: number, index: number) => string {
    return (value: number, index: number) => {
      return getNumericMetricValue(MetricEnumToMetricName[metricKey], value)
    };
  }

  function generateTicksForYAxis(min: number, max: number, tickFormatter: (value: number, index: number) => string): number[] {
    const numberOfTicks = 5;
    const step = (max - min) / (numberOfTicks - 1);
    const ticksSet = new Set<number>();
    for (let i = 0; i < numberOfTicks; i++) {
      ticksSet.add(+tickFormatter(min + (i * step), 0));
    }
    return Array.from(ticksSet);
  }
  const leftTicks = generateTicksForYAxis(min, max, getYAxisTickFormatter(Object.keys(filteredSavedSelectedMetrics)[0]));

  const oldChartIsActive = true;

  let chartData: any = {};

  function createChartData(
    userSettings: UserSettings | null,
    wrappedXAxis: boolean,
    aggregationKey: string,
    responseWithChartMetrics: MetricResponse,
    filteredSavedSelectedMetrics: MetricsDropdown,
    xAxisTimestamps: number[],
    yAxisDomain: [number, number],
    yAxisTicks: number[],
    yAxisMin2: number,
    yAxisMax2: number,
    mergedChartPoints: MergedChartPoint[]
  ) : ChartData {
    chartData.filteredSavedSelectedMetrics = filteredSavedSelectedMetrics;
    chartData.aggregationKey = aggregationKey;
    chartData.wrappedXAxis = wrappedXAxis;
    chartData.xAxisDomain = aggregationKey === 'day' ?
      [
        convertToMillis(responseWithChartMetrics.startPeriod.slice(0, 10)),
        convertToMillis(responseWithChartMetrics.endPeriod.slice(0, 10)),
      ]
      : [
        convertToMillis(responseWithChartMetrics.startPeriod),
        convertToMillis(responseWithChartMetrics.endPeriod),
      ];
    chartData.xAxisTickFormatter = wrappedXAxis ?
      () => {
        return "00:00 PM";
      } :
      (value: number) => {
        return formatDateTime(value, aggregationKey);
      }
    chartData.xAxisTick = wrappedXAxis ?
      // @ts-ignore
      ({ x, y, payload }) => {
        const day = moment(payload.value).format(DAY_WITH_MONTH_FORMAT);
        const time = moment(payload.value).format(TIME_FORMAT);
        return (
          <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="middle"
                  fill={colors[themeCss(theme)].chartGridColor} fontSize={13}>
              {day}
            </text>
            <text x={0} y={0} dy={30} textAnchor="middle"
                  fill={colors[themeCss(theme)].chartGridColor} fontSize={13}>
              {time}
            </text>
          </g>
        );
      } : { fontSize: 13 };
    chartData.xAxisTimestamps = xAxisTimestamps;
    chartData.mergedChartPoints = mergedChartPoints;

    chartData.axes = [];
    const metricColor = getMetricColorHistory(Object.keys(filteredSavedSelectedMetrics)[0], theme);
    chartData.axes.push({
      orientation: "left",
      dataKey: "value",
      name: "Value",
      yAxisId: "left",
      color: metricColor,
      tickFormatter: getYAxisTickFormatter(Object.keys(filteredSavedSelectedMetrics)[0]),
      domain: yAxisDomain,
      ticks: yAxisTicks,
      labelValue: getUnitForHistoryChart(Object.keys(filteredSavedSelectedMetrics)[0], userSettings),
    });

    if (responseWithChartMetrics.metrics.length > 1) {
      const metricColorTwo = getMetricColorHistory(Object.keys(filteredSavedSelectedMetrics)[1], theme);
      chartData.axes.push({
        orientation: "right",
        dataKey: "value2",
        name: "Value2",
        yAxisId: "right",
        color: metricColorTwo,
        tickFormatter: getYAxisTickFormatter(Object.keys(filteredSavedSelectedMetrics)[1]),
        domain: [yAxisMin2, yAxisMax2],
        ticks: generateTicksForYAxis(yAxisMin2, yAxisMax2, getYAxisTickFormatter(Object.keys(filteredSavedSelectedMetrics)[1])),
        labelValue: getUnitForHistoryChart(Object.keys(filteredSavedSelectedMetrics)[1], userSettings),
      });
    }
    return chartData;
  }

  return (
    <>
      {isLoading ? (
        <LoadingDataSpinner
          text={"Loading ..."}
          textColor={colors.lightTheme.spinnerText}
        />
      ) : (
        <>
          <ChartFilters
            onApply={() => updateMetrics()}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setMetrics={setSelectedMetrics}
            metrics={selectedMetrics}
            setSelectedAggregation={setSelectedAggregation}
            selectedAggregation={selectedAggregation}
            deviceType={patientCase.deviceType}
            isTableOpened={isTableOpened}
          />
          <div className={styles.container}>
          <div className={styles.chartContainer}>
            {noDataAvailable && (
              <NoDataAvailable icon={<NoChartDataIcon/>} firstLineText={"Please adjust your selection"} transparent={!isTableOpened}/>
            )}
            {!noDataAvailable && (
              <>
                <div className={styles.chartBody}>
                  {
                    responseWithChartMetrics && chartPoints && (
                      oldChartIsActive ?
                        (
                          <OldChart {...createChartData(
                            userSettings,
                            wrappedXAxis,
                            aggregationKey,
                            responseWithChartMetrics,
                            filteredSavedSelectedMetrics,
                            xAxisTimestamps,
                            [min, max],
                            leftTicks,
                            min2,
                            max2,
                            mergedChartPoints
                          )} />
                        ) : (
                          <div>New implementation</div>
                        )
                    )
                  }
                </div>
                <div className={styles.chartFooter}>
                  {renderFooterMetrics()}
                </div>
              </>
            )}
          </div>
          { isTableOpened && (
            <div className={styles.metricTableContainer}>
              <div className={styles.metricTableWrapper}>
                <MetricTable
                  response={response}
                  userSettings={userSettings}
                  savedSelectedAggregationKey={aggregationKey}
                />
              </div>
            </div>
          )}
          </div>
        </>
      )}
    </>
  );
};

export default HistoryChart;
