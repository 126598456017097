import React, {memo, FunctionComponent} from 'react';
import { CompanyLogo, FormContainer } from '../../../lib/components';
import { ForgotPasswordFormValues } from './interfaces/forgot-password-form-values.interface';
import { take } from 'rxjs/operators';
import { useHistory, useLocation } from 'react-router-dom';
import { authService } from '../../../services';
import { NavRoute } from '../../../lib/constants/nav-route.constant';
import ForgotPasswordForm from './components/ForgotPasswordForm';

const ForgotPassword: FunctionComponent = memo(() => {
  const history = useHistory();
  const location = useLocation<{email:string}>();

  const submitForm = ({ email }: ForgotPasswordFormValues, onComplete: () => void) => {
    authService.forgotPassword(email).pipe(
      take(1)
    ).subscribe({
      next: () => {
        history.push({
          pathname: NavRoute.AUTH.RESET_PASSWORD,
          state: { email: email }
        })
        onComplete();
      },
      error: () => onComplete()
    })
  }

  return (
    <FormContainer>
      <CompanyLogo />
      <ForgotPasswordForm email={location.state.email} submitForm={submitForm} />
    </FormContainer>
  );
});

export default ForgotPassword;
