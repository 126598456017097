import {CartesianGrid, Label, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis} from "recharts";
import {colors} from "../../../../../theme/colors";
import {themeCss} from "../../../../../lib/utils/theme";
import CustomTooltip from "../../CustomTooltip";
import React, {useContext} from "react";
import {createStyles, makeStyles} from "@material-ui/core";
import {AppContext} from "../../../../../state/AppContext";
import {
  MergedChartPoint,
  MetricResponse,
  MetricsDropdown
} from "../../../../../lib/interfaces/metric-history.interface";

export type ChartData = {
  // true - for hour or no aggregation
  wrappedXAxis: boolean;
  filteredSavedSelectedMetrics: MetricsDropdown;
  mergedChartPoints: MergedChartPoint[];
  aggregationKey: string;
  responseWithChartMetrics: MetricResponse | null;
  xAxisTimestamps: number[];
  xAxisDomain: number[];
  xAxisTickFormatter: (value: number) => string;
  xAxisTick: any;
  axes: ChartAxisData[];
};

export type ChartAxisData = {
  dataKey: string;
  orientation: 'left' | 'right';
  name: string;
  yAxisId: string;
  color: string;
  domain: [number, number];
  ticks: number[];
  tickFormatter: (value: number) => string;
  labelValue: string;
}

const OldChart = (chartData: ChartData) => {
  const { theme } = useContext(AppContext);
  const useStyles = makeStyles(() =>
    createStyles({
      scatterChart: {
        backgroundColor: colors[themeCss(theme)].chartBackground,
      },
      chartAxisYLabel: {
        fontSize: 12,
        fontWeight: 600,
        textAnchor: "end",
      },
    })
  );
  const styles = useStyles();
  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <ScatterChart
        className={styles.scatterChart}
        margin={{
          top: 30,
          bottom: chartData.wrappedXAxis ? 20 : 0,
        }}
      >
        <XAxis
          dataKey="time"
          // @ts-ignore
          domain={[chartData.xAxisDomain]}
          stroke={colors.blackTheme.tickColor}
          ticks={chartData.xAxisTimestamps}
          tickFormatter={chartData.xAxisTickFormatter}
          tickMargin={11}
          type="number"
          minTickGap={2}
          tickLine={false}
          textAnchor="middle"
          tick={chartData.xAxisTick}
        />
        {chartData.axes.map((axis: ChartAxisData) => {
          return (
            <YAxis
              key={axis.dataKey}
              orientation={axis.orientation}
              dataKey={axis.dataKey}
              name={axis.name}
              yAxisId={axis.yAxisId}
              stroke={axis.color}
              strokeWidth={2}
              tick={{ fontSize: 13 }}
              tickMargin={10}
              tickLine={false}
              domain={axis.domain}
              ticks={axis.ticks}
              type="number"
              tickFormatter={axis.tickFormatter}
            >
              <Label
                value={axis.labelValue}
                position="top"
                fill={axis.color}
                className={styles.chartAxisYLabel}
                dy={-15}
                dx={15}
              />
            </YAxis>
          )
        })}
        <CartesianGrid
          stroke={colors.blackTheme.tickColor}
          strokeDasharray="3 3"
          syncWithTicks={true}
        />
        {chartData.axes.map((axis: ChartAxisData) => {
          return (
            <Scatter
              key={axis.dataKey}
              data={chartData.mergedChartPoints}
              line={{
                stroke: axis.color,
                strokeWidth: 2,
              }}
              lineJointType="monotoneX"
              fill={axis.color}
              shape={"circle"}
              name={axis.name}
              dataKey={axis.dataKey}
              isAnimationActive={false}
              yAxisId={axis.yAxisId}
            />
          )
        })}
        <Tooltip
          isAnimationActive={false}
          cursor={false}
          filterNull={true}
          content={<CustomTooltip metrics={chartData.filteredSavedSelectedMetrics} savedSelectedAggregationKey={chartData.aggregationKey} />}
        />
      </ScatterChart>
    </ResponsiveContainer>
  )
}

export default OldChart;
