import React, { FunctionComponent, ReactNode } from 'react';
import {makeStyles, createStyles} from '@material-ui/core';
import {colors} from "../../../theme/colors";
import MenuIcon from "./MenuIcon";

const useStyles = makeStyles(() =>
  createStyles({
    menuItem: {
      height: 50,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 12,
      cursor: 'pointer'
    },
    menuItemText: {
      fontWeight: 'bold',
    },
    menuItemIcon: {
      marginRight: 18,
    },
  })
);

interface Props {
  children: ReactNode;
  IconComponent: MenuIcon;
  active?: boolean;
  onClick?: () => void;
}

const MenuItem: FunctionComponent<Props> = ({ children, IconComponent, active = false, onClick }) => {
  const styles = useStyles();
  const color = active ? colors.blackTheme.menuActiveTextColor : colors.blackTheme.menuTextColor;
  return (
    <div className={styles.menuItem} onClick={onClick}>
      <div className={styles.menuItemIcon}>
        <IconComponent color={color} />
      </div>
      <div className={styles.menuItemText} style={{color}}>
        {children}
      </div>
    </div>
  );
};

export default MenuItem;
