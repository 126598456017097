import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MUIDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../Button';
import Typography from '../Typography';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '30px 35px',
      borderRadius: 16,
      maxWidth: 800
    },
    actionsRoot: {
      justifyContent: 'center'
    },
    title: {
      fontSize: 20
    },
    smallPopup: {
      padding: '40px 25px',
      borderRadius: 10,
      maxWidth: 470
    }
  })
);

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  onCancel?: () => void;
  submit?: () => void;
  title?: string;
  contentText?: string;
  noButtons?: boolean;
  okButton?: boolean;
  smallStyle?: boolean;
  btnText?: string;
}

const Dialog: FunctionComponent<Props> = memo(({ 
  children, open, setOpen, title, contentText, submit, onCancel, noButtons, okButton, smallStyle, btnText
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const onClose = () => setOpen(false);
  const onCancelHandler = () => {
    onCancel && onCancel();
    onClose();
  };
  const onSubmit = () => {
    submit && submit();
    onClose();
  };

  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      classes={{
        paper: smallStyle ? styles.smallPopup : styles.root
      }}
    >
      {
        title && <DialogTitle>
          <Typography className={styles.title} align="center">{title}</Typography>
        </DialogTitle>
      }
      <DialogContent>
        <DialogContentText align="center">{contentText}</DialogContentText>
        {children}
      </DialogContent>

        {
          !noButtons &&
            <DialogActions classes={{root: styles.actionsRoot}}>
              <Button variant="text" onClick={onCancelHandler}>
                {t('button.cancel')}
              </Button>
              <Button  onClick={onSubmit}>
                {t('button.submit')}
              </Button>
            </DialogActions>
        }
      {
        okButton && <DialogActions classes={{root: styles.actionsRoot}}>
          <Button  onClick={onSubmit}>
            {btnText ? btnText : t('button.ok')}
          </Button>
        </DialogActions>
      }

    </MUIDialog>
  );
});

export default Dialog;
