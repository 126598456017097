import React, {memo, FunctionComponent, ChangeEvent} from 'react';
import MUISwitch from '@material-ui/core/Switch'

interface Props {
  size?: 'small' | 'medium';
  name: string;
  value?: unknown;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

const Switch: FunctionComponent<Props> = memo(({
  size, value, checked, name, onChange
}) => {
  return (
    <MUISwitch 
      size={'medium' || size}
      value={value}
      checked={checked}
      name={name}
      onChange={onChange}
    />
  );
});

export default Switch;
