import React, {FunctionComponent, useContext,} from 'react';
import {makeStyles, createStyles} from '@material-ui/core';
import TableTooltip from "../../HistoryChart/MetricTable/TableTooltip";
import {AppContext} from "../../../../../../state/AppContext";
import {colors} from "../../../../../../theme/colors";
import {themeCss} from "../../../../../../lib/utils/theme";
import {hexToRgbA} from "../../../../../../lib/utils/color-utils";
import {BellRingIcon} from "../../../../../../theme/icons/BellRingIcon";

type Props = {
  color: string;
  label: string;
  value: string;
  units: string;
  message: string | null;
  min: string;
  max: string;
  thresholdMin: string;
  thresholdMax: string;
  showThresholds: boolean;
  isFullScreen: boolean;
  outOfRange: boolean;
}

const MetricsTile: FunctionComponent<Props> = (
  {isFullScreen, color, label, value, units, message, min, max, thresholdMin, thresholdMax, showThresholds, outOfRange}: Props
) => {
  const { theme } = useContext(AppContext);
  const borderColor = outOfRange ? colors.formInvalid : colors[themeCss(theme)].chartGridColor;
  const useStyles = makeStyles(() =>
    createStyles({
      container: {
        display: 'flex',
        border: `1px solid ${borderColor}`,
        borderBottom: 'none',
        position: 'relative',
        '&:last-child': {
          borderBottom: `1px solid ${borderColor}`,
        },
      },
      mainContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: isFullScreen ? 12 : 10,
        paddingLeft: isFullScreen ? 25 : 19,
        paddingTop: isFullScreen ? 16 : 13,
        paddingBottom: isFullScreen ? 16 : 13,
        marginRight: 'auto',
      },
      containerOutOfRange: {
        backgroundColor: hexToRgbA(colors.formInvalid, 0.1),
        border: `1px solid ${borderColor}`,
        '& + div': {
          borderTop: 'none',
        },
      },
      bellRingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.formInvalid,
        width: isFullScreen ? 50 : 38,
      },
      label: {
        fontSize: isFullScreen ? 20 : 15,
        fontWeight: 600,
        lineHeight: '100%',
      },
      valueWithThresholds: {
        display: 'flex',
        gap: isFullScreen ? 7 : 5,
      },
      value: {
        fontSize: isFullScreen ? 72 : 50,
        fontWeight: 800,
        lineHeight: '73%',
      },
      thresholds: {

      },
      thresholdsTitle: {
        fontSize: isFullScreen ? 13 : 10,
        fontWeight: 700,
        lineHeight: '100%',
        color: colors[themeCss(theme)].chartGridColor,
      },
      thresholdsValue: {
        fontSize: isFullScreen ? 17 : 13,
        fontWeight: 800,
        lineHeight: '100%',
        color: color,
      },
      minMax: {
        color: colors[themeCss(theme)].chartGridColor,
        gap: 2,
        display: 'flex',
        flexDirection: 'column',
      },
      minMaxTitle: {
        fontSize: isFullScreen ? 13 : 10,
        fontWeight: 700,
        lineHeight: isFullScreen ? '100%' : '95.2%',
      },
      minMaxValues: {
        fontSize: isFullScreen ? 17 : 13,
        fontWeight: 600,
        lineHeight: '95.2%',
        display: 'flex',
        gap: isFullScreen ? 10 : 8,
      },
    })
  );
  const styles  = useStyles();
  const iconSize = isFullScreen ? 32 : 24;
  return (
    <div className={`${styles.container} ${outOfRange ? styles.containerOutOfRange : ''}`}>
      <div className={styles.mainContent}>
        <div className={styles.label} style={{color}}>{label}, {units}</div>
        <div className={styles.valueWithThresholds}>
          <div className={styles.value} style={{color}} data-tip={message ? message : undefined}
               data-for={`tooltip-${label}`}>{value}</div>
          {message && <TableTooltip id={`tooltip-${label}`}/>}
          {showThresholds && <div className={styles.thresholds}>
            <div className={styles.thresholdsTitle}>Thresholds</div>
            <div className={styles.thresholdsValue}>{thresholdMin}</div>
            <div className={styles.thresholdsValue}>{thresholdMax}</div>
          </div>}
        </div>
        <div className={styles.minMax}>
          <div className={styles.minMaxTitle}>Last Hour</div>
          <div className={styles.minMaxValues}>
            <div>min: {`${min} ${units}`}</div>
            <div>max: {`${max} ${units}`}</div>
          </div>
        </div>
      </div>
      {outOfRange && <div className={styles.bellRingContainer}>
        <BellRingIcon width={iconSize} height={iconSize}/>
      </div>}
    </div>
  );
};

export default MetricsTile;
