import React from "react";

function UncheckedIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="white" />
      <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#E4E4E4" />
    </svg>
  );
}

export default UncheckedIcon;
