import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { ReactComponent as EmptyDetails } from '../../../../theme/empty-details.svg';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      height: '90%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
);

const PatientMetricsEmpty: FunctionComponent = memo(() => {
  const styles = useStyles();
  
  return (
    <div className={styles.container}>
      <EmptyDetails />
    </div>
  );
});

export default PatientMetricsEmpty;
