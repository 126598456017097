import { Case, RawCase } from './../../lib/interfaces/case.interface';
import { UrgentMode } from './../../lib/constants/urgent-mode.enum';
import { catchError, mapTo, switchMap, expand, take, takeWhile, reduce, mergeMap, map, tap, filter } from 'rxjs/operators';
import { API_ROUTES } from './../../lib/constants/api-routes.constant';
import { Api } from './../../lib/api/api';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import errorService from '../errorService';
import caseService from '../caseService';
import {
  Device,
  DeviceDto,
  DeviceOptions,
  DevicesResponse,
  DeviceV2,
  NewDevice
} from '../../lib/interfaces/device.interface';
import { isNullable } from '../../lib/utils/checkValue';


const DeviceService = () => {
  const devices$ = new BehaviorSubject<Device[]|null>(null);

  const saveNewDevice = (body: NewDevice): Observable<null> => {
    return Api.post(API_ROUTES.DEVICE.NEW, body).pipe(
      switchMap(() => getAllDevices()),
      catchError(e => {
        const error = e.message || e.error || e;
        errorService.addError(error);
        return throwError(e);
      }),
      mapTo(null)
    );
  };

  const updateDevice = (body: DeviceDto): Observable<null> => {
    return Api.put(API_ROUTES.DEVICE.NEW_V2, body).pipe(
      mapTo(null)
    );
  };

  const getDevices = (options?: DeviceOptions): Observable<Device[]> => {
    const urlParams = { ...options };
    return Api.get(API_ROUTES.DEVICE.GET_DEVICES, 
      urlParams ? {urlParams} : undefined
      ).pipe(
      catchError(e => {
        const error = e.message || e.error || e;
        errorService.addError(error);
        return throwError(e);
      })
    );
  };

  const getDevicesV2 = (): Observable<DevicesResponse> => {
    return Api.get(API_ROUTES.DEVICE.GET_DEVICES_V2);
  };

  const getAllDevices = (): Observable<Device[]> => {
    return getDevices().pipe(
      expand((value: any) => {
        if (value.lastEvaluatedKey) {
          const evaluationOptions = {
            'lastEvaluatedKey[id].s': value.lastEvaluatedKey.id.s || '',
            'lastEvaluatedKey[timestamp].n': value.lastEvaluatedKey.timestamp.n || '',
          };

          return getDevices(evaluationOptions);
        }
        return of(value);
      }),
      takeWhile((devices => Boolean(devices.lastEvaluatedKey)), true),
      reduce((acc, val) => {
        return acc.concat(val.results)
      }, [] as Device[]),
      tap((deviceList: Device[]) => devices$.next(deviceList))
    );
  }

  const getAllDevicesV2 = (): Observable<DeviceV2[]> => {
    return getDevicesV2().pipe(
      map((response: DevicesResponse) => {
        return response.content;
      })
    );
  }

  const getAvailableDevices = (): Observable<Device[]> => {
    return caseService.getCasesList().pipe(
      mergeMap((caseList: Case[]) => getAllDevices().pipe(
        map((deviceList: Device[]) => ({caseList, deviceList}))
      )),
      map(({caseList, deviceList}: {caseList: Case[], deviceList: Device[]}) => {
        const currentDeviceList: {[key: string]: string} = caseList.reduce((acc, patientCase) => {
          return { ...acc, [patientCase.deviceId]: patientCase.deviceName }
        }, {})
        return deviceList.filter(device => !currentDeviceList[device.attribute]);
      })
    )
  }

  const getDeviceById = (id: string): Observable<DeviceDto> => {
    return Api.get(API_ROUTES.DEVICE.GET_DEVICE_BY_ID(id));
  };

  const deleteDevice = (id: string): Observable<any> => {
    return Api.delete(API_ROUTES.DEVICE.DELETE_DEVICE_BY_ID(id)).pipe(
      catchError(e => {
        const error = e.message || e.error || e;
        errorService.addError(error);
        return throwError(e);
      })
    );
  };

  const deleteDeviceFlow = (id: string): Observable<any> => {
    return caseService.getActiveCases().pipe(
      take(1),
      switchMap((cases: RawCase[]) => {
        const deviceExistsInCase = cases.some((patientCase: RawCase) => patientCase.deviceId === id);
        if (deviceExistsInCase) {
          return throwError({message: 'Device exists in active patient case'})
        }
        return deleteDevice(id);
      }),
      switchMap(() => getAllDevices()),
      catchError(e => {
        const error = e.message || e.error || e;
        errorService.addError(error);
        return throwError(e);
      }),
    )
  }

  const switchSensorMode = (deviceId: string, mode: UrgentMode): Observable<Case[]> => {
    return Api.post(API_ROUTES.DEVICE.SWITCH_SENSOR_MODE(deviceId), {mode}).pipe(
      catchError(e => {
        const error = e.message || e.error || e;
        errorService.addError(error);
        return throwError(e);
      }),
      switchMap(() => caseService.getCasesList())
    );
  };

  const deleteErrorFromDevice = (deviceId: string): Observable<boolean> => {
    return Api.get(API_ROUTES.DEVICE.DELETE_ERROR(deviceId)).pipe(
      catchError(e => {
        const error = e.message || e.error || e;
        errorService.addError(error);
        return throwError(e);
      }),
      switchMap(() => caseService.getCasesList()),
      mapTo(true)
    );
  };

  const changeNormalReportingMode = (deviceId: string, normalModeQuantity: number): Observable<Case[]> => {
    return Api.post(API_ROUTES.DEVICE.CHANGE_NORMAL_MODE(deviceId), {normalMode: normalModeQuantity}).pipe(
      catchError(e => {
        const error = e.message || e.error || e;
        errorService.addError(error);
        return throwError(e);
      }),
      switchMap(() => caseService.getCasesList())
    );
  };

  return {
    deviceState: devices$.asObservable().pipe(filter(value => !isNullable(value))),
    saveNewDevice,
    updateDevice,
    getDeviceById,
    deleteDeviceFlow,
    switchSensorMode,
    getAllDevices,
    getAllDevicesV2,
    getAvailableDevices,
    deleteErrorFromDevice,
    changeNormalReportingMode
  };
};

const singleton = DeviceService();
export default Object.freeze(singleton);
