import { AggregateBy } from './../../lib/constants/aggregation.enum';
import { useState, useEffect } from 'react';
import { AggregationState } from '../../lib/interfaces/aggregation-state.interface';
import { metricService } from '../../services';

const initialAggregationState = {
  aggregateBy: AggregateBy.HOURS,
  from: '',
  to: ''
}

const useAggreationState = () => {
  const [state, setState] = useState<AggregationState>(initialAggregationState);

  useEffect(() => {
    const sub = metricService.aggregationState.subscribe(setState)

    return () => sub.unsubscribe();
  }, []);

  return state;
};

export default useAggreationState;
