import {DATE_FORMAT, DATE_TIME_FULL_FORMAT, DAY_FORMAT, TIME_FORMAT} from '../constants/date-format.constant';
import { AggregateBy } from '../constants/aggregation.enum';
import moment from 'moment';

export function formatDateTimeFullForm(date: string|null): string|null {
  return date ? moment(date).format(DATE_TIME_FULL_FORMAT) : null;
}

function formatDay(timestamp: number) {
  return moment(timestamp).format(DAY_FORMAT);
}

function formatDaytime(timestamp: number) {
  return moment(timestamp).format(DATE_TIME_FULL_FORMAT);
}

function formatDayWithTimeForHourlyAggregation(timestamp: number) {
  const fromTime = moment(timestamp).subtract(1, "hour").format(TIME_FORMAT);
  const toTime = moment(timestamp).subtract(1, "minute").format(TIME_FORMAT);
  const day = moment(timestamp).subtract(1, "hour").format(DAY_FORMAT);
  return `${day} ${fromTime} - ${toTime}`;
}

// timestamp in milliseconds
export function formatAggregation(timestamp: number, selectedAggregation: string) {
  const formats : Record<string, (timestamp : number) => string> = {
    'none': formatDaytime,
    'hour': formatDayWithTimeForHourlyAggregation,
    'day': formatDay,
  }
  return formats[selectedAggregation](timestamp) || formats['none'](timestamp);
}

// deprecated
export function extractTime(date: string): string {
  return moment(date).format('HH:mm');
}

// deprecated
export function extractDate(date: string): string {
  return moment(date).format('MM.DD.YYYY');
}

export function extractDateAggregation(date: string) {
  const dateDif = Math.ceil(moment().diff(date, 'days', true));
  if (dateDif > 1) {
    return (timestamp: string) => String(moment(timestamp).startOf('day').format());
  }
  return (timestamp: string) => String(moment(timestamp).startOf('hour').format());
}

export function extractAggregatedDate(aggregationType: AggregateBy|string): 
  (timestamp: string) => string {
  switch (aggregationType) {
    case AggregateBy.MINUTES:
      return (timestamp: string): string => String(moment(timestamp, DATE_FORMAT).startOf('minute').format());
      case AggregateBy.DAYS:
        return (timestamp: string) => String(moment(timestamp, DATE_FORMAT).startOf('day').format());
      case AggregateBy.HOURS:
      default:
      return (timestamp: string) => String(moment(timestamp, DATE_FORMAT).startOf('hour').format());
  }
}
