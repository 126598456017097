import { Subject } from 'rxjs';

const createErrorService = () => {
  const state$ = new Subject();

  const addError = (error: any): void => {
    state$.next(error);
  }

  return {
    state: state$.asObservable(),
    addError
  }
};

const singleton = createErrorService();
export default Object.freeze(singleton);
