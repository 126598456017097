import React, {memo, FunctionComponent, FocusEvent, useState, useEffect} from 'react';
import { InputAdornment } from '@material-ui/core';
import TextField from '../TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '../IconButton';
import PasswordCheck from '../PasswordCheck';

interface Props {
  value: string;
  onChange: (value: string) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  label?: string;
  type?: string;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  showHelp?: boolean;
}

const checkLength = (value: string): boolean => (value.length >= 8);
const checkLetter = (value: string): boolean => (/[a-zA-Z]/).test(value);
const checkNumber = (value: string) => (/\d/).test(value);

const PasswordField: FunctionComponent<Props> = memo((props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [validation, setValidation] = useState({
    isLength: false,
    isLetter: false,
    isNumber: false
  });

  useEffect(() => {
    const validationCheck = {
      isLength: checkLength(props.value),
      isLetter: checkLetter(props.value),
      isNumber: checkNumber(props.value)
    };
    setValidation(validationCheck);
  }, [props.value])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <TextField
        {...props}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {
        props.showHelp ? <PasswordCheck validation={validation} /> : null
      }
    </>
  );
});

export default PasswordField;
