import React, {memo, FunctionComponent} from 'react';
import { useTranslation } from 'react-i18next';
import MUIMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { MenuItemInterface } from '../../interfaces/menu-item.interface';

interface Props {
  elements: MenuItemInterface[];
  handleClose: () => void;
  anchorEl: null | Element | ((element: Element) => Element);
}

const Menu: FunctionComponent<Props> = memo(({ elements, anchorEl, handleClose }) => {
  const { t } = useTranslation();
  const onMenuItemPress = (handler: () => void) => () => {
    handler();
    handleClose();
  }

  return (
    <MUIMenu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      keepMounted
      onClose={handleClose}
    >
      {
        elements && elements.map(element => 
          <MenuItem 
            key={element.titleKey} 
            onClick={onMenuItemPress(element.handler)}
          >
            {t(`menuItem.${element.titleKey}`)}
          </MenuItem>
        )
      }
    </MUIMenu>
  );
});

export default Menu;
