import React, {memo, FunctionComponent, useState} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Device } from '../../../../lib/interfaces/device.interface';
import DeviceListItem from '../DeviceListItem';
import { Dialog } from '../../../../lib/components';
import { useTranslation } from 'react-i18next';
import { deviceService, messageService } from '../../../../services';

const useStyles = makeStyles(() =>
  createStyles({
    container: {}
  })
);

interface Props {
  devices: Device[];
}

const DeviceList: FunctionComponent<Props> = memo(({ devices }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [idDelete, setDeleteId] = useState('');

  const onCancel = () => {
    setOpen(false);
    setDeleteId('');
  }

  const onDelete = (id: string) => () => {
    setOpen(true);
    setDeleteId(id);
  }
  const submitDelete = () => {
    deviceService.deleteDeviceFlow(idDelete).subscribe({
      next: () => messageService.addMessage(t('text.deviceWasRemoved', {idDelete})),
      error: () => {},
      complete: () => setDeleteId('')
    })
  }

  return (
    <div className={styles.container}>
      {
        devices.map(device => {
          return (
            <DeviceListItem 
              key={device.attribute}
              device={device}
              onDelete={onDelete(device.attribute)}
            />
          )
        })
      }

      <Dialog
        open={open}
        setOpen={setOpen}
        title={t('header.removeDevice')}
        contentText={t('text.removeDeviceText')}
        submit={submitDelete}
        onCancel={onCancel}
      />
    </div>
  );
});

export default DeviceList;
