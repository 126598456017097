import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Header from '../Header';
import UserMenu from '../UserMenu';
import CompanyLogo from '../CompanyLogo';
import LoaderComponent from '../LoaderComponent';
import { useHistory } from 'react-router-dom';
import { NavRoute } from '../../constants/nav-route.constant';
import IconButton from '../IconButton';

const useStyles = makeStyles(() =>
  createStyles({
    utils: {
      display: 'flex',
      alignItems: 'center'
    },
    utilsImage: {
      paddingLeft: 13,
      height: 26,
      width: '100%'
    },
    imgSVG: {
      height: 80,
      width: 130
    },
    versionBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end'
    },
    version: {
      fontSize: 12,
      color: '#404040',
      fontWeight: 400,
    }
  })
);

interface Props {
  title: string;
  shouldGoBack?: boolean;
}

const CommonHeader: FunctionComponent<Props> = memo(({ title, shouldGoBack }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const history = useHistory();
  const goToMain = () => history.replace(NavRoute.MAIN.INIT);

  return (
    <>
      <Header
        title={t(`header.${title}`)}
        actionItem={
          shouldGoBack ? <IconButton onClick={goToMain}>chevron_left</IconButton> : null
        }
        utility={
          <div className={styles.utils}>
            <UserMenu />
            <div className={styles.versionBlock}>
              <CompanyLogo />
              <span className={styles.version}>Version {process.env.REACT_APP_VERSION}</span>
            </div>
          </div>
        }  
      />
      <LoaderComponent />
    </>
  );
});

export default CommonHeader;
