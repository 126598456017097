import React, { FunctionComponent, } from 'react';
import {makeStyles, createStyles} from '@material-ui/core';
import { ReactComponent as BatteryIcon } from '../../../../theme/icons/battery.svg';
import { ReactComponent as Battery75Icon } from '../../../../theme/icons/battery-75.svg';
import { ReactComponent as Battery50Icon } from '../../../../theme/icons/battery-50.svg';
import { ReactComponent as Battery25Icon } from '../../../../theme/icons/battery-25.svg';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
    },
    label: {
      fontSize: 12,
      fontWeight: 500,
    },
    value: {
      display: 'flex',
      gap: 2,
      fontSize: 13,
      fontWeight: 600,
      alignItems: 'center',
    },
    valueText: {
      lineHeight: '20px',
    },
  })
);

interface Props {
  value: number | undefined;
}

const BatteryLevel: FunctionComponent<Props> = ({value}) => {
    const styles  = useStyles();
    let Icon = <BatteryIcon/>;
    if (value === undefined || value >= 75) {
      Icon = <BatteryIcon/>;
    } else if (value >= 50) {
      Icon = <Battery75Icon/>;
    } else if (value >= 25) {
      Icon = <Battery50Icon/>;
    } else {
      Icon = <Battery25Icon/>;
    }

    return (
      <div className={styles.container}>
        <div className={styles.label}>Battery Level</div>
        <div className={styles.value}>
          {Icon}
          <div className={styles.valueText}>{value === undefined ? '-' : value < 20 ? 'Low' : value + '%'}</div>
        </div>
      </div>
    );
};

export default BatteryLevel;
