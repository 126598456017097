import { MetricOrder } from './../constants/metric-order.enum';
export interface MetricHistory {
  results: Array<MetricHistoryItem>,
  lastEvaluatedKey: LastEvaluatedKey|null
}

export interface MetricHistoryItem {
  timestamp: string;
  value: number|boolean;
  aggregatingTime: string;
  message: string[] | null;
  previousDataDate: string | null;
}

export type MetricsDropdown = {
  [key: string]: string;
};

export type AggregationDropdown = {
  key: string;
  value: string;
};

export interface LastEvaluatedKey {
  id: {
    n: string|null;
    s: string|null;
  },
  timestamp: {
    n: string|null;
    s: string|null;
  }
}

export interface MetricOptions {
  from: string,
  to: string|undefined,
  sortOrder: MetricOrder|undefined,
  'lastEvaluatedKey[id].s': string|undefined,
  'lastEvaluatedKey[timestamp].n': string|undefined,
}

export interface MetricsView {
  min: number|boolean;
  max: number|boolean;
  avg: number;
  avgView: number;
  maxView: number;
  aggregatedTime: string;
  message: string[] | null;
  previousDataDate: string | null;
}

export type UIMetricOptions = Pick<MetricOptions, 'from'|'to'|'sortOrder'>

type DataItem = {
  datetime: string;
  value: number | null;
  tableTooltipMessage: string | null;
  chartTooltipMessage: string | null;
  dataIsValid: boolean;
  showInTable: boolean;
  outOfRange: boolean;
};

export type Metric = {
  name: string;
  data: DataItem[];
};

export type MetricResponse = {
  startPeriod: string;
  endPeriod: string;
  metrics: Metric[];
};

export interface AggregatedDataObject {
  caseId: string,
  from: string,
  to: string,
  metrics: string,
  timezoneOffset: number,
  aggregationType: string
}

export type ChartPoint = {
  time: number;
  value: number | null;
};

export type SecondChartPoint = {
  time: number;
  value2: number | null;
};

export type MergedChartPoint = {
  time: number;
  value: number | null;
  value2: number | null;
};

export const EDOCTOR_METRICS = ['BatteryStatus', 'BodyTemperature', 'Position', 'ActivityIntensity', 'ChestExpansion', 'RespirationRate', 'HeartRate'];
export const EBEAT_METRICS = ['BatteryStatus', 'BodyTemperature', 'RespirationRate', 'HeartRate', 'OxygenSaturationLevel'];

export type MetricName = typeof EDOCTOR_METRICS[number] | typeof EBEAT_METRICS[number];

export const MetricLabels: Record<MetricName, string> = {
  HeartRate: 'Pulse Rate',
  BodyTemperature: 'Skin Temperature',
  Position: 'Position',
  ActivityIntensity: 'Activity Level',
  ChestExpansion: 'Chest Expansion',
  RespirationRate: 'Respiration Rate',
  BatteryStatus: 'Battery Level',
  OxygenSaturationLevel: 'Oxygen Saturation Level',
}

export type MetricItemUnit = {
  y: number | null, // value - 73.0
  x: string // datetime - "2024-04-08T22:24:24"

}

export type MetricItem = {
  metric: MetricName,
  units: MetricItemUnit[]
}

export type LastMeasurements = {
  [key in MetricName]: {
    min: number | null;
    max: number | null;
    changeForHour: number | null;
    value: number | null;
    message: string | null;
    outOfRange: boolean;
  }
}

export type LastHourMetrics = {
  lastMeasurements: LastMeasurements,
  from: string,
  to: string,
  chartData: MetricItem[]
}
