import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoDataImage } from '../../../theme/no-data.svg';
import Typography from '../Typography';


const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flex: 1,
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    text: {
      paddingTop: 16
    }
  })
);

const EmptyMetrics: FunctionComponent = memo(() => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <NoDataImage style={{width: 126, height: 126}} />
      <Typography className={styles.text}>{t('text.noDataFound')}</Typography>
    </div>
  );
});

export default EmptyMetrics;
