import React, {memo, FunctionComponent, useState} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Device } from '../../../../lib/interfaces/device.interface';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../lib/components';
// import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';
import DeviceAddNewForm from '../DeviceAddNewForm';
import { DeviceForm } from '../../interfaces/form.interface';
import { deviceService } from '../../../../services';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      padding: 10
    },
    item: {
      minWidth: 200,
      padding: 10,
      fontSize: 16,
      marginRight: 10
    },
    title: {
      fontSize: 12,
      color: '#9EA2A5'
    },
    value: {
      paddingTop: 5,
      fontWeight: 700
    },
    name: {
      borderLeft: '1px solid #9EA2A5',
      paddingLeft: 35
    },
    icon: {
      margin: 5,
      padding: 10,
      borderRadius: '50%'
    }
  })
);


interface Props {
  device: Device;
  onDelete: () => void;
}

const DeviceListItem: FunctionComponent<Props> = memo(({ device, onDelete }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [isEdit, setEdit] = useState(false);

  const submit = (value: DeviceForm) => {
    deviceService.saveNewDevice(value).subscribe({
      complete: toggleEditMode
    })
  };

  const toggleEditMode = () => setEdit(!isEdit);
  
  return (
    <div className={styles.container}>
      {
        isEdit ?
          <DeviceAddNewForm 
            shouldDisableAttribute
            name={device.name}
            attribute={device.attribute}
            submitForm={submit} 
            cancel={toggleEditMode}
          />
        :
        <>
          <div className={styles.item}>
            <Typography className={styles.title}>{t('placeholder.deviceAttribute')}</Typography>
            <Typography className={styles.value}>{device.attribute}</Typography>
          </div>
          <div className={`${styles.item} ${styles.name}`}>
            <Typography className={styles.title}>{t('placeholder.deviceName')}</Typography>
            <Typography className={styles.value}>{device.name}</Typography>
          </div>
          <div className={`${styles.item} ${styles.name}`}>
            <Typography className={styles.title}>{t('placeholder.deviceType')}</Typography>
            <Typography className={styles.value}>{device.type ? t(`deviceType.${device.type}`) : '-'}</Typography>
          </div>
          {/*<div className={styles.item}>*/}
          {/*  <ButtonBase className={styles.icon} onClick={toggleEditMode}>*/}
          {/*    <EditIcon />*/}
          {/*  </ButtonBase>*/}
          {/*  <ButtonBase className={styles.icon} onClick={onDelete}>*/}
          {/*    <DeleteIcon />*/}
          {/*  </ButtonBase>*/}
          {/*</div>*/}

        </>
      }
    </div>
  );
});

export default DeviceListItem;
