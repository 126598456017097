import React, {FunctionComponent, ReactElement,} from 'react';
import {createStyles, makeStyles} from "@material-ui/core";
import {PositionHorizontalIcon} from "../../../../../../theme/icons/PositionHorizontalIcon";
import {PositionUprightIcon} from "../../../../../../theme/icons/PositionUprightIcon";
import {ActivityHighIcon} from "../../../../../../theme/icons/ActivityHighIcon";
import {ActivityMediumIcon} from "../../../../../../theme/icons/ActivityMediumIcon";
import {ActivityLowIcon} from "../../../../../../theme/icons/ActivityLowIcon";
import {QuestionIcon} from "../../../../../../theme/icons/QuestionIcon";
import TableTooltip from "../../HistoryChart/MetricTable/TableTooltip";

type Props = {
  titleValue: 'Position' | 'Activity Level';
  color: string;
  value: string;
  message: string | null;
  size?: 'small' | 'large';
}

const AdditionalMetricTile: FunctionComponent<Props> = (props: Props) => {
  const {titleValue, color, value, message, size = 'small'} = props;
  const useStyles = makeStyles(() =>
    createStyles({
      container: {
        display: 'flex',
        gap: 8,
      },
      titleWithValue: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      },
      title: {
        fontSize: size === 'small' ? 15 : 20,
        fontWeight: 600,
        lineHeight: '100%',
      },
      icon: {
        height: 30,
      },
      value: {
        fontSize: size === 'small' ? 15 : 20,
        fontWeight: 500,
        lineHeight: '20px',
      },


    })
  );
  const styles  = useStyles();
  const iconSize = 42;
  const valueToIcons : Record<string, ReactElement> = {
    '-': <QuestionIcon width={iconSize} height={iconSize} color={color}/>,
    'Horizontal': <PositionHorizontalIcon height={iconSize} width={iconSize}/>,
    'Upright': <PositionUprightIcon height={iconSize} width={iconSize}/>,
    'High': <ActivityHighIcon height={iconSize} width={iconSize} color={color}/>,
    'Medium': <ActivityMediumIcon height={iconSize} width={iconSize} color={color}/>,
    'Low': <ActivityLowIcon height={iconSize} width={iconSize} color={color}/>,
  }
  return (
    <div className={styles.container} style={{color}}>
      <div className={styles.icon}>{valueToIcons[value]}</div>
      <div className={styles.titleWithValue}>
        <div className={styles.title}>{titleValue}</div>
        <div className={styles.value} data-tip={message ? message : undefined} data-for={`tooltip-${titleValue}`}>{value}</div>
        {message && <TableTooltip id={`tooltip-${titleValue}`} />}
      </div>
    </div>
  )
}

export default AdditionalMetricTile;
