import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { colors } from '../../theme/colors';
import CreatePatientHeader from './components/CreatePatientHeader';
import CreatePatientForm from './components/CreatePatientForm';
import {NewPatient, NewPatientForm, ThresholdMetric} from '../../lib/interfaces/new-patient-form.interface';
import { caseService, messageService } from '../../services';
import { useHistory } from 'react-router-dom';
import { NavRoute } from '../../lib/constants/nav-route.constant';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100vh',
      width: '100%',
      backgroundColor: colors.backgroundBlue,
      display: 'flex',
      overflow: 'auto'
    },
    wrapper: {
      maxWidth: 870,
      width: 870,
      marginRight: 'auto',
      marginLeft: 'auto',
      marginTop: 80,
    },
    body: {
      paddingLeft: 52
    }
  })
);

const CreatePatientCase: FunctionComponent = memo(() => {
  const styles = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  function convertFormToPatient(form: NewPatientForm): NewPatient {
    let patient: NewPatient = {
      firstName: form.firstName,
      lastName: form.lastName,
      sex: form.sex,
      age: Number(form.age),
      patientId: form.patientId,
      deviceId: form.deviceId,
      thresholds: [],
    }
    if (form.pulseRate.min !== '' && form.pulseRate.max !== '') {
      patient.thresholds.push({
        metric: 'HeartRate' as ThresholdMetric,
        lowRange: Number(form.pulseRate.min),
        highRange: Number(form.pulseRate.max),
      });
    }
    if (form.bodyTemperature.min !== '' && form.bodyTemperature.max !== '') {
      patient.thresholds.push({
        metric: 'BodyTemperature' as ThresholdMetric,
        lowRange: Number(form.bodyTemperature.min),
        highRange: Number(form.bodyTemperature.max),
      });
    }
    if (form.respiratoryRate.min !== '' && form.respiratoryRate.max !== '') {
      patient.thresholds.push({
        metric: 'RespirationRate' as ThresholdMetric,
        lowRange: Number(form.respiratoryRate.min),
        highRange: Number(form.respiratoryRate.max),
      });
    }
    if (form.oxygenSaturationLevel.min !== '' && form.oxygenSaturationLevel.max !== '') {
      patient.thresholds.push({
        metric: 'OxygenSaturationLevel' as ThresholdMetric,
        lowRange: Number(form.oxygenSaturationLevel.min),
        highRange: Number(form.oxygenSaturationLevel.max),
      });
    }
    return patient;
  }

  const submit = (values: NewPatientForm, event: () => void) => {
    caseService.createCaseFlow(convertFormToPatient(values)).subscribe({
      next: () => {messageService.addMessage(t('text.patientWasCreated'))},
      complete: () => {history.replace(NavRoute.MAIN.INIT)}
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <CreatePatientHeader />
        <div className={styles.body}>
          <CreatePatientForm submitForm={submit} />
        </div>
      </div>
    </div>
  );
});

export default CreatePatientCase;
