export const colors = {
  blue: '#5A69AE',
  secondaryBlue: '#62AEDF',
  blueDisabled: '#E1ECF9',
  black: '#404040',
  gray: '#9EA2A5',
  lightGray: '#9EA2A5',
  textGray: '#7E7E7E',
  backgroundBlue: '#F0F2F7',
  placeholderText: '#9C9A9A',
  redesign: {
    chartBackground: '#313131',
    toggleBorder: '#3E3E3E',
    toggleBackgroundInactive: '#313131',
  },
  formInvalid: '#EC523C',
  blackTheme: {
    textColor: '#FFF',
    buttonPrimaryBackground: '#00A1E6',
    buttonPrimaryDisabledBackground: '#9C9A9A',
    buttonSecondaryBackground: 'transparent',
    buttonTextColor: '#FBFCFD',
    buttonSecondaryTextColor: '#00A1E6',
    buttonSecondaryBorder: '#00A1E6',
    background: '#1E1E1E',
    menuBackground: '#1E1E1E',
    contentWrapperBackground: '#313131',
    headerBackground: '#3E3E3E',
    menuActiveTextColor: '#00A1E6',
    menuTextColor: '#FBFCFD',
    sidebarBackground: '#313131',
    tableBorder: '#4D4D4D',
    tableHeaderBackground: '#3E3E3E',
    popupBackground: '#313131',
    popupTextActiveColor: '#FBFCFD',
    popupTextInactiveColor: '#9C9A9A',
    formInputBorder: '#4D4D4D',
    formInputBackground: '#3E3E3E',
    formInputText: '#FBFCFD',
    formLabelText: '#FBFCFD',
    linkColor: '#00A1E6',
    toggleBorder: '#4D4D4D',
    toggleBackgroundInactive: '#FFF',
    toggleBackgroundActive: '#00A1E6',
    toggleText: '#FFF',
    csvButtonBorder: '#00A1E6',
    csvButtonBackground: '#313131',
    chartFilterBackground: '#313131',
    chartFilterSelectBorder: '#4D4D4D',
    chartFilterSelectBackground: '#3E3E3E',
    chartContainerBackground: '#313131',
    chartGridColor: '#A0A0A0',
    averageLabelBorder: '#4D4D4D',
    tickColor: '#A0A0A0',
    chartPopupBorder: '#4D4D4D',
    chartPopupBackground: '#3E3E3E',
    chartPopupFontColor: '#FBFCFD',
    mainBackground: '#FFFFFF',
    chartBackground: '#313131',
    //metrics
    heartRate: '#00B180',
    respirationRate: '#FAFF00',
    chestExpansion: '#FF00D6',
    position: '#EF7A04',
    activityLevel: '#E15759',
    oxygenSaturationLevel: '#46FDFC',
    bodyTemperature: '#FBFCFD',
    noLastHourDataBackground: '#3E3E3E',
    metricTable: {
      headerBackground: '#313131',
      text: '#FFFFFF',
      date: '#A0A0A0',
      scrollbarBackground: '#313131',
      scrollbarColor: '#9C9A9A',
      popup: {
        font: '#FFFFFF',
        background: '#3E3E3E',
        border: '#4D4D4D',
      }
    }
  },
  lightTheme: {
    textColor: '#404040',
    toggleBorder: '#E4E4E4',
    toggleBackgroundInactive: '#FFF',
    toggleBackgroundActive: '#59669B',
    toggleText: '#404040',
    toggleTextActive: '#FFF',
    metricTileMinMax: '#9C9A9A',
    metricTileDelta: '#9C9A9A',
    chartGridColor: '#A0A0A0',
    noLastHourDataText: '#9C9A9A',
    spinnerText: '#9C9A9A',
    chartPopupBorder: '#E4E4E4',
    chartPopupBackground: '#F1F2F7',
    chartPopupFontColor: '#404040',
    chartBackground: '#FFFFFF',
    menuBackground: '#F1F2F7',
    //metrics
    heartRate: '#00B180',
    respirationRate: '#C4B533',
    chestExpansion: '#FF00D6',
    position: '#EF7A04',
    activityLevel: '#E15759',
    oxygenSaturationLevel: '#0978C8',
    bodyTemperature: '#404040',
    chartFilterBackground: '#F1F2F7',
    chartFilterSelectBorder: '#E4E4E4',
    chartFilterSelectBackground: '#FFFFFF',
    chartContainerBackground: '#FFFFFF',
    noLastHourDataBackground: '#F1F2F7',
    metricTable: {
      headerBackground: 'white',
      text: '#404040',
      date: '#A0A0A0',
      scrollbarBackground: '#FFFFFF',
      scrollbarColor: '#E4E4E4',
      popup: {
        font: 'black',
        background: '#F6F6F6',
        border: '#E4E4E4',
      }
    }
  },
  lightBlue: '#F2F4FF',
  backgroundGray: '#F8F8F8',
  borderBlue: '#F0F2F7',
  red: '#E26653',
  lightRed: '#FFF9F8',
  green: '#54BC57',
  white: '#ffffff',
  disabledGray: '#9C9A9A'
};

