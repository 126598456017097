import React, {memo, FunctionComponent, useState} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Select } from '../../../../lib/components';
import { Device } from '../../../../lib/interfaces/device.interface';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex'
    }
  })
);


interface Props {
  onChange: (value: string) => void;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  availableDevices: Device[] | null;
}

const SelectDeviceComponent: FunctionComponent<Props> = memo(({
  availableDevices, error, disabled, required, onChange, helperText
}) => {
  const styles = useStyles();
  const [value, setValue] = useState('');
  const { t } = useTranslation();
  const elements = availableDevices ? availableDevices.map(device => ({value: device.attribute, text: device.name})) : null;

  const handleChange = (value: string) => {
    onChange(value);
    setValue(value);
  }

  return (
    <div className={styles.container}>
      <Select 
        elements={elements || []}
        required={required}
        placeholder={(elements && !elements.length) ? t('placeholder.noDevicesAvailable') : t('placeholder.assignDevice')}
        value={value}
        onChange={handleChange}
        disabled={!elements || (elements && !elements.length) || disabled}
        error={error}
        helperText={helperText}
      />

      {
        !elements && <CircularProgress />
      } 
    </div>
    
  );
});

export default SelectDeviceComponent;
