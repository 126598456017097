import React, {FunctionComponent, useContext, useState} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { CommonHeader } from '../../lib/components';
import PatientInfo from './components/PatientInfo';
import PatientMetricsFooter from './components/PatientMetricsFooter';
import { colors } from '../../theme/colors';
// import { useUserInfo } from '../../hooks';
import PatientMetricsEmpty from './components/PatientMetricsEmpty';
// import { buttonPermissions } from '../../lib/utils/button-permissions';
import moment from "moment";
import {DATE_FORMAT} from "../../lib/constants/date-format.constant";
import MetricsWrapper from "./components/MetricsWrapper";
import {Case} from "../../lib/interfaces/case.interface";
import {AppContext} from "../../state/AppContext";
import useRequestUserSettings from "../../hooks/useRequestUserSettings";
import useUserSettings from "../../hooks/useUserSettings";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      flexFlow: 'column',
      position: 'relative'
    },
    main: {
      flex: 1,
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
      backgroundColor: colors.backgroundBlue,
      overflowY: 'auto'
    },
    inner: {
      display: 'flex',
      flexFlow: 'column',
      height: '100%',
      overflowY: 'auto',
    }
  })
);

interface Props {
  patientCase: Case|null;
}

const PatientMetrics: FunctionComponent<Props> = ({patientCase}) => {
  const { isFullScreen } = useContext(AppContext);
  const styles = useStyles();
  //todo: temporary comment(MD-819)
  // const user = useUserInfo();
  // const canShowButton = buttonPermissions(user, patientCase);
  //todo: delete, is not used
  const [filter, setFilter] = useState(moment.utc().subtract(1, 'day').format(DATE_FORMAT));
  //todo: delete, is not used
  const [, setChosenElement] = useState(0);
  //todo: delete, is not used
  const [graphClick, setGraphClick] = useState(false);
  //todo: delete, is not used
  const [buttonDisable, setButtonDisable] = useState(false);

  useRequestUserSettings();
  const userSettings = useUserSettings();

  return (
    <div className={styles.container}>
      {isFullScreen || <CommonHeader title={"details"} />}

      {patientCase ? (
        <div className={styles.main}>
          <div className={styles.inner}>
            {isFullScreen || (
              <PatientInfo
                patientCase={patientCase}
                canShowButton={true}
                userSettings={userSettings}
              />
            )}
            <MetricsWrapper
              patientCase={patientCase}
              userSettings={userSettings}
            />
          </div>
          {isFullScreen || (
            <PatientMetricsFooter
              //todo: fix - delete unused properties
              patientCase={patientCase}
              userSettings={userSettings}
              setFilter={setFilter}
              setChosenElement={setChosenElement}
              filter={filter}
              graphClick={graphClick}
              buttonDisable={buttonDisable}
              setGraphClick={setGraphClick}
              setButtonDisable={setButtonDisable}
            />
          )}
        </div>
      ) : (
        <PatientMetricsEmpty />
      )}
    </div>
  );
};

export default PatientMetrics;
