const MAIN = 'main';
const AUTH = 'auth';
const CREATE = 'create';
const SIGN_IN = 'sign-in';
const MANAGE_DEVICES = 'manage-devices';
const RESET_PASSWORD = 'reset-password';
const FORGOT_PASSWORD = 'forgot-password';
const CONFIRM_PASSWORD = 'confirm-password';
const PATIENT = 'patient';

export const NavRoute = {
  AUTH: {
    INIT: `/${AUTH}`,
    SIGN_IN: `/${SIGN_IN}`,
    RESET_PASSWORD: `/${RESET_PASSWORD}`,
    FORGOT_PASSWORD: `/${FORGOT_PASSWORD}`,
    CONFIRM_PASSWORD: `/${CONFIRM_PASSWORD}`
  },
  MAIN: {
    INIT: `/${MAIN}`,
    CREATE: `/${CREATE}`,
    MANAGE_DEVICES: `/${MANAGE_DEVICES}`,
    PATIENT: `/${PATIENT}`
  }
};
