import React, { FunctionComponent } from 'react';

interface Props {
  width: number;
  height: number;
}

export const LightThemeIcon: FunctionComponent<Props> = ({width, height}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6043_18490)">
        <path
          d="M15.9998 21.3334C18.9453 21.3334 21.3331 18.9455 21.3331 16C21.3331 13.0545 18.9453 10.6667 15.9998 10.6667C13.0543 10.6667 10.6664 13.0545 10.6664 16C10.6664 18.9455 13.0543 21.3334 15.9998 21.3334Z"
          stroke="white" strokeWidth="2" strokeLinejoin="round"/>
        <path
          d="M26.6667 16H28M4 16H5.33333M16 26.6667V28M16 4V5.33333M23.5427 23.5427L24.4853 24.4853M7.51467 7.51467L8.45733 8.45733M8.45733 23.5427L7.51467 24.4853M24.4853 7.51467L23.5427 8.45733"
          stroke="white" strokeWidth="2" strokeLinecap="round"/>
      </g>
      <rect x="0.5" y="0.5" width="31" height="31" rx="2.5" stroke="white"/>
      <defs>
        <clipPath id="clip0_6043_18490">
          <rect width="32" height="32" rx="3" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
