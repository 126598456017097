import { debounceTime, tap, switchMap } from 'rxjs/operators';
import { useState, useEffect } from 'react';
import { messageService } from '../../services';
import { timer } from 'rxjs';

const useMessage = () => {
  const [message, setMessage]: [any, (arg: any) => void] = useState(null);

  useEffect(() => {
    const sub = messageService.state.pipe(
      debounceTime(100),
      tap(setMessage),
      switchMap(() => timer(3000).pipe(
        tap(() => setMessage(null))
      )),
    ).subscribe()

    return () => sub.unsubscribe();
    /* eslint-disable */
  }, []);

  return [message, setMessage];
};

export default useMessage;
