import { useState, useEffect } from 'react';
import { LoadingState } from '../../lib/interfaces/loading-state.interface';
import csvService from '../../services/csvService';

const usePatientCaseLoader = () => {
  const [state, setState] = useState<LoadingState|null>(null);

  useEffect(() => {
    const sub = csvService.patientCaseState.subscribe(setState);
    return () => sub.unsubscribe();
    /* eslint-disable */
  }, []);

  return state;
};

export default usePatientCaseLoader;
