import moment from "moment";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { colors } from "../../../theme/colors";
import DatePicker from "react-datepicker";
import { createStyles, makeStyles } from "@material-ui/core";
import OutsideClickHandler from "react-outside-click-handler";
import Button from "../../../pages/redesign/common/Button";
import HistoryChartDropdown from "../HistoryChartDropdown";
import {AggregationDropdown, MetricsDropdown} from "../../interfaces/metric-history.interface";
import {AppContext} from "../../../state/AppContext";
import HistoryChartAggregationDropdown from "../HistoryChartAggregationDropdown";

const DatePickerWrapperStylesLight = createGlobalStyle`
    .react-datepicker {
      background-color: #FFFFFF;
      position: relative;
      border: 1px solid #E4E4E4;
      border-radius: 7px;
      color: #404040;
      font-family: 'Raleway';
      padding: 10px 15px;
    }
    .date_picker {
      .react-datepicker__day {
        width: 24px;
        height: 24px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        border-radius: 50%;
      }
      .react-datepicker__day:hover {
        background-color: #00A1E680;
      }
      .react-datepicker__day:hover.react-datepicker__day--disabled {
        background-color: transparent;
        cursor: not-allowed;
      }
      .react-datepicker__day--outside-month, .react-datepicker__day--disabled {
        color: #9C9A9A;
      }
      .react-datepicker__day--today {
        border: 1px solid #59669B;
      }
      .react-datepicker__day--in-selecting-range {
        background-color: #00A1E680;
      }
      .react-datepicker__day--in-range {
        background-color: #59669B;
        color: #FBFCFD;
      }
      .react-datepicker__day--selected {
        background-color: #59669B;
      }
      .react-datepicker__month-header {
        display: flex;
        justify-content: space-between;
      }
      .react-datepicker__navigation {
        cursor: pointer;
        width: 24px;
        height: 24px;
        border: none;
      }
      .react-datepicker__navigation--next {
        background: url(/arrow-right.svg) no-repeat;
      }
      .react-datepicker__navigation--previous {
        background: url(/arrow-left.svg) no-repeat;
      }
      .react-datepicker__aria-live {
        display: none;
      }
      .react-datepicker__current-month {
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
      }
      .react-datepicker__day-names {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 500;
        color: #9C9A9A;
      }
      .react-datepicker__week {
        display: flex;
        justify-content: space-between;
      }
      .react-datepicker__month-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      .react-datepicker__header {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      .react-datepicker__month {
        padding-right: 7px;
        padding-left: 7px;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  `;
const DatePickerWrapperStylesDark = createGlobalStyle`
    .react-datepicker {
      background-color: #3E3E3E;
      position: relative;
      border: 1px solid #4D4D4D;
      border-radius: 7px;
      color: #FBFCFD;
      font-family: 'Raleway';
      padding: 10px 15px;
    }
    .date_picker {
      .react-datepicker__day {
        width: 24px;
        height: 24px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        border-radius: 50%;
      }
      .react-datepicker__day:hover {
        background-color: #00A1E680;
      }
      .react-datepicker__day:hover.react-datepicker__day--disabled {
        background-color: transparent;
        cursor: not-allowed;
      }
      .react-datepicker__day--outside-month, .react-datepicker__day--disabled {
        color: #9C9A9A;
      }
      .react-datepicker__day--today {
        border: 1px solid #00A1E6;
      }
      .react-datepicker__day--in-selecting-range {
        background-color: #00A1E680;
      }
      .react-datepicker__day--in-range {
        background-color: #00A1E6;
        color: #FBFCFD;
      }
      .react-datepicker__day--selected {
        background-color: #00A1E6;
      }
      .react-datepicker__month-header {
        display: flex;
        justify-content: space-between;
      }
      .react-datepicker__navigation {
        cursor: pointer;
        width: 24px;
        height: 24px;
        border: none;
      }
      .react-datepicker__navigation--next {
        background: url(/arrow-right.svg) no-repeat;
      }
      .react-datepicker__navigation--previous {
        background: url(/arrow-left.svg) no-repeat;
      }
      .react-datepicker__aria-live {
        display: none;
      }
      .react-datepicker__current-month {
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
      }
      .react-datepicker__day-names {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 500;
        color: #9C9A9A;
      }
      .react-datepicker__week {
        display: flex;
        justify-content: space-between;
      }
      .react-datepicker__month-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      .react-datepicker__header {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      .react-datepicker__month {
        padding-right: 7px;
        padding-left: 7px;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  `;

interface Props {
  onApply: () => void;
  setStartDate: any;
  setEndDate: any;
  startDate: Date | null;
  endDate: Date | null;
  setMetrics: Dispatch<SetStateAction<MetricsDropdown>>;
  metrics: MetricsDropdown;
  selectedAggregation: AggregationDropdown;
  setSelectedAggregation: Dispatch<SetStateAction<AggregationDropdown>>;
  deviceType: string;
  isTableOpened: boolean;
}

const ChartFilters = ({
  onApply,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  setMetrics,
  metrics,
  setSelectedAggregation,
  selectedAggregation,
  deviceType,
  isTableOpened
}: Props) => {
  // State for calendar component
  const [calendarStartDate, setCalendarStartDate] = useState<Date | null>(
    startDate
  );
  const [calendarEndDate, setCalendarEndDate] = useState<Date | null>(
    endDate
  );

  const { theme } = useContext(AppContext);
  const styles = useStyles();
  // State for selected date range
  const currentDateCalendarSelector = `${moment(startDate).format(
    `MMM D`
  )}-${moment(endDate).format(`MMM D, YYYY`)}`;
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [filterIsChanged, setFilterIsChanged] = useState(false);

  const onCalendarRangeChange = (dates: [Date | null, Date | null]) => {
    setFilterIsChanged(true);
    // end date is undefined when user selects the start date only
    const [start, end] = dates;
    setCalendarStartDate(start);
    setCalendarEndDate(end);
    if (start) {
      setStartDate(start);
    }
    if (end) {
      setEndDate(end);
    } else if (start) {
      setEndDate(start);
    }
  };

  return (
    <div className={`${styles.chartFilter} ${styles[`chartFilter--${theme}`]}`}>
      <div className={styles.filterSelectContainer}>
        <HistoryChartDropdown
          metrics={metrics}
          setMetrics={setMetrics}
          setFilterIsChanged={setFilterIsChanged}
          deviceType={deviceType}
          isTableOpened={isTableOpened}
        />
        <div
          className={`${styles.filterSelectWrapper}`}
          onClick={() => setIsCalendarOpen(true)}
        >
          <div className={`${styles.period} ${styles.filterSelect} ${styles[`filterSelect--${theme}`]}`}>
            {/*<LeftArrow />*/}
            <div className={styles[`filterSelectData--${theme}`]}>{currentDateCalendarSelector}</div>
            {/*<RightArrow />*/}
          </div>
          {isCalendarOpen && (
            <div className={styles.calendarWrapper}>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setIsCalendarOpen(false);
                }}
              >
                <DatePicker
                  calendarClassName={"date_picker"}
                  selected={calendarStartDate}
                  onChange={onCalendarRangeChange}
                  startDate={calendarStartDate}
                  endDate={calendarEndDate}
                  selectsRange
                  inline
                  formatWeekDay={(name) => {
                    type WeekDayMap = {
                      [key: string]: string;
                    };
                    const map: WeekDayMap = {
                      Sunday: "Sun",
                      Monday: "Mon",
                      Tuesday: "Tue",
                      Wednesday: "Wed",
                      Thursday: "Thu",
                      Friday: "Fri",
                      Saturday: "Sat",
                    };
                    return map[name];
                  }}
                  calendarStartDay={1}
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => {
                    type MonthMap = {
                      [key: string]: string;
                    };
                    const monthMap: MonthMap = {
                      0: "January",
                      1: "February",
                      2: "March",
                      3: "April",
                      4: "May",
                      5: "June",
                      6: "July",
                      7: "August",
                      8: "September",
                      9: "October",
                      10: "November",
                      11: "December",
                    };
                    return (
                      <div className={"react-datepicker__month-header"}>
                        <button
                          className={
                            "react-datepicker__navigation react-datepicker__navigation--previous"
                          }
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        ></button>
                        <div className={"react-datepicker__current-month"}>
                          {monthMap[date.getMonth()]}
                        </div>
                        <button
                          className={
                            "react-datepicker__navigation react-datepicker__navigation--next"
                          }
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        ></button>
                      </div>
                    );
                  }}
                  maxDate={new Date()}
                  minDate={
                    new Date(new Date().setMonth(new Date().getMonth() - 3))
                  }
                />
                {theme === 'dark' ? <DatePickerWrapperStylesDark/> : <DatePickerWrapperStylesLight />}
              </OutsideClickHandler>
            </div>
          )}
        </div>
        <HistoryChartAggregationDropdown selectedAggregation={selectedAggregation} setSelectedAggregation={setSelectedAggregation} setFilterIsChanged={setFilterIsChanged} />
      </div>
      <div className={styles.endSection}>
        <Button
          title={"Apply"}
          disabled={!filterIsChanged}
          width={103}
          onClick={() => {
            setFilterIsChanged(false);
            onApply();
          }}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    chartFilter: {
      padding: 15,
      display: "flex",
      justifyContent: "space-between",
      gap: 15,
      borderRadius: 10,
    },
    "chartFilter--light": {
      backgroundColor: colors.lightTheme.menuBackground,
    },
    "chartFilter--dark": {
      backgroundColor: colors.blackTheme.chartFilterSelectBackground,
    },
    filterSelectContainer: {
      display: "flex",
      gap: 18,
    },
    filterSelectWrapper: {
      position: "relative",
      cursor: "pointer",
    },
    calendarWrapper: {
      position: "absolute",
      top: "100%", // positions the top edge of the DayPicker just below the daySelector div
      marginTop: 5,
      left: "50%", // aligns the left edge of the DayPicker with the left edge of the daySelector div
      transform: "translateX(-50%)", // centers the DayPicker below the daySelector div
      width: 274,
      zIndex: 9999,
    },
    filterSelect: {
      height: 40,
      borderRadius: 7,
      fontSize: 16,
      fontWeight: 600,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "filterSelect--light": {
      border: `1px solid ${colors.lightTheme.chartFilterSelectBorder}`,
      backgroundColor: colors.lightTheme.chartFilterSelectBackground,
    },
    "filterSelect--dark": {
      border: `1px solid ${colors.blackTheme.chartFilterSelectBorder}`,
      backgroundColor: colors.blackTheme.chartFilterSelectBackground,
    },
    "filterSelectData--light": {
      color: colors.lightTheme.toggleText,
    },
    "filterSelectData--dark": {
      color: colors.blackTheme.toggleText,
    },
    metric: {
      width: 234,
      paddingLeft: 12,
      paddingRight: 8,
    },
    period: {
      width: 274,
      justifyContent: "center",
    },
    aggregation: {
      paddingLeft: 12,
      paddingRight: 5,
      width: 181,
    },
    endSection: {
      display: "flex",
    },
  })
);

export default ChartFilters;
