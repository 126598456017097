import React, { FunctionComponent } from 'react';
import {makeStyles, createStyles} from '@material-ui/core';
import {colors} from "../../../theme/colors";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
    },
    input: {
      width: '100%',
      height: 40,
      paddingLeft: 10,
      paddingRight: 10,
      fontFamily: 'Raleway, sans-serif',
      fontSize: 14,
      fontWeight: 600,
      borderRadius: 5,
      border: `1px solid ${colors.blackTheme.formInputBorder}`,
      backgroundColor: colors.blackTheme.formInputBackground,
      color: colors.blackTheme.formInputText,
      outline: 'none',
    },
    label: {
      fontSize: 11,
      fontWeight: 600,
      color: colors.blackTheme.formLabelText,
      fontFamily: 'Raleway, sans-serif',
      marginBottom: 5,
    },
    errorMessage: {
      marginTop: 2,
      fontSize: 11,
      fontWeight: 600,
      color: colors.formInvalid,
      fontFamily: 'Raleway, sans-serif',
    }
  })
);

interface Props {
  label: string;
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  isValid?: boolean;
  errorMessage?: string;
}

const FormInput: FunctionComponent<Props> = ({ label, value, onChange, disabled = false, isValid = true, errorMessage }) => {
  const styles = useStyles();
  const labelColor = disabled ? colors.blackTheme.popupTextInactiveColor : isValid ? colors.blackTheme.formLabelText : colors.formInvalid;
  const textColor = disabled ? colors.blackTheme.popupTextInactiveColor : isValid ? colors.blackTheme.formInputText : colors.formInvalid;
  const borderColor = isValid ? colors.blackTheme.formInputBorder : colors.formInvalid;

  return (
    <div className={styles.container}>
      <div className={styles.label} style={{color: labelColor}}>{label}</div>
      <input className={styles.input} disabled={disabled} style={{color: textColor, borderColor}} value={value} onChange={onChange ? (event) => onChange(event.target.value) : undefined}/>
      <div className={styles.errorMessage}>{errorMessage ? errorMessage : '\u00A0'}</div>
    </div>
  );
};

export default FormInput;
