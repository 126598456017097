import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles, CircularProgress, PropTypes } from '@material-ui/core';
import MUIButton from '@material-ui/core/Button'
import { colors } from '../../../theme/colors';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: 32,
      maxHeight: 32,
      textTransform: 'capitalize',
      width: 280
    },
    contained: {
      padding: '10px 30px',
    },
    text: {
      color: '#5A69AE',
      padding: '10px 30px'
    },
    buttonProgress: {
      color: colors.green,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

interface Props {
  disabled?: boolean; 
  loading?: boolean;
  rootClass?: any;
  variant?: 'text'|'outlined'|'contained';
  color?: PropTypes.Color;
  size?: 'small'|'medium'|'large';
  onClick?: () => void;
}

const Button: FunctionComponent<Props> = memo(({ 
  children, disabled, variant, size, onClick, rootClass, loading, color
}) => {
  const styles = useStyles();

  return (
    <MUIButton
      onClick={onClick}
      color={color || 'primary'}
      classes={{
        root: `${styles.root} ${rootClass}`,
        contained: styles.contained,
        text: styles.text
      }}
      variant={variant || 'contained'}
      size={size || 'small'}
      disabled={disabled || loading}
    >
      {children}
      {loading && <CircularProgress size={24} className={styles.buttonProgress} />}
    </MUIButton>
  );
});

export default Button;
