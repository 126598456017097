import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { PrivateRoute } from '../lib/components';
import { NavRoute } from '../lib/constants/nav-route.constant';
import { 
  ForgotPassword, Main, ResetPassword,
  SignIn, SignInSubmit, NotFound, CreatePatientCase
} from '../pages';
import ManageDevices from "../pages/ManageDevices";

// Contains logic for routing in the application
//todo: add documentation for each route
export default function MainNavigator() {
  return (
    <Router>
      <Switch>
        {/* / -> /main */}
        <Route exact path="/">
          <Redirect to={NavRoute.MAIN.INIT} />
        </Route>
        {/* /main - Main page */}
        <PrivateRoute path={NavRoute.MAIN.INIT}>
          <Main />
        </PrivateRoute>
        {/*todo: add documentation*/}
        <PrivateRoute path={NavRoute.MAIN.MANAGE_DEVICES}>
          <ManageDevices />
        </PrivateRoute>
        {/*todo: add documentation*/}
        {/*<PrivateRoute path={NavRoute.MAIN.PATIENT}>*/}
        {/*  <PatientMetrics />*/}
        {/*</PrivateRoute>*/}
        {/**/}
        <PrivateRoute path={NavRoute.MAIN.CREATE}>
          <CreatePatientCase />
        </PrivateRoute>

        <Route path={NavRoute.AUTH.SIGN_IN}>
          <SignIn />
        </Route>
        <Route path={NavRoute.AUTH.FORGOT_PASSWORD}>
          <ForgotPassword />
        </Route>
        <Route path={NavRoute.AUTH.RESET_PASSWORD}>
          <ResetPassword />
        </Route>
        <Route path={NavRoute.AUTH.CONFIRM_PASSWORD}>
          <SignInSubmit />
        </Route>



        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  )
};
