import React, { useContext, useState } from "react";
import BottomArrow from "../../icons/BottomArrow";
import { colors } from "../../../theme/colors";
import { createStyles, makeStyles } from "@material-ui/core";
import DropdownItem from "./DropdownItem";
import OutsideClickHandler from "react-outside-click-handler";
import { MetricsDropdown } from "../../interfaces/metric-history.interface";
import { themeCss } from "../../utils/theme";
import { AppContext } from "../../../state/AppContext";

interface Props {
  setMetrics: (value: any) => void;
  metrics: MetricsDropdown;
  setFilterIsChanged: (value: boolean) => void;
  deviceType:string;
  isTableOpened: boolean;
}

const HistoryChartDropdown = ({
  metrics,
  setMetrics,
  setFilterIsChanged,
  deviceType,
  isTableOpened
}: Props) => {
  const { theme } = useContext(AppContext);

  const useStyles = makeStyles(() =>
    createStyles({
      filterSelect: {
        height: 40,
        borderRadius: 7,
        border: `1px solid ${colors[themeCss(theme)].chartFilterSelectBorder}`,
        fontSize: 16,
        fontWeight: 600,
        color: colors[themeCss(theme)].textColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: colors[themeCss(theme)].chartFilterSelectBackground,
      },
      metric: {
        width: 234,
        paddingLeft: 12,
        paddingRight: 8,
      },
      textContainer: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "90%",
      },
      filterSelectWrapper: {
        position: "relative",
        cursor: "pointer",
      },
      dropdownMenu: {
        position: "absolute",
        top: "100%",
        marginTop: 5,
        left: "50%",
        transform: "translateX(-50%)",
        width: 234,
        zIndex: 9999,
        backgroundColor: colors[themeCss(theme)].chartFilterSelectBackground,
        borderRadius: 10,
        border: `1px solid ${colors[themeCss(theme)].chartFilterSelectBorder}`,
        paddingTop: 10,
        paddingRight: 10,
        paddingLeft: 10,
      },
    })
  );

  const [isActive, setIsActive] = useState(false);
  const styles = useStyles();

  const onItemClick = (key: string, label: string) => {
    if (Object.keys(metrics).includes(key) && Object.keys(metrics).length > 1) {
      const { [key]: removedKey, ...updatedMetrics } = metrics;
      setMetrics(updatedMetrics);
    } else {
      setMetrics((prev: MetricsDropdown) => {
        const updatedMetrics: MetricsDropdown = { ...prev, [key]: label };
        return updatedMetrics;
      });
    }
    setFilterIsChanged(true);
  };

  return (
    <div className={`${styles.filterSelectWrapper}`}>
      <div
        className={`${styles.filterSelect} ${styles.metric}`}
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <div className={styles.textContainer}>
          {Object.values(metrics).join(", ")}
        </div>
        <BottomArrow />
      </div>
      {isActive && (
        <div className={styles.dropdownMenu}>
          <OutsideClickHandler
            onOutsideClick={() => {
              setIsActive(false);
            }}
          >
            {deviceType === "eDoctor" ? (
              <>
                <DropdownItem
                  onClick={() => onItemClick("heart-rate", "Pulse Rate")}
                  isChecked={Object.keys(metrics).includes("heart-rate")}
                  label={"Pulse Rate"}
                  isDisabled={
                    Object.values(metrics).length === 2 &&
                    !Object.values(metrics).includes("Pulse Rate")
                  }
                />
                <DropdownItem
                  onClick={() =>
                    onItemClick("respiration-rate", "Respiratory Rate")
                  }
                  isChecked={Object.keys(metrics).includes("respiration-rate")}
                  label={"Respiratory Rate"}
                  isDisabled={
                    Object.values(metrics).length === 2 &&
                    !Object.values(metrics).includes("Respiratory Rate")
                  }
                />
                <DropdownItem
                  onClick={() =>
                    onItemClick("chest-expansion", "Chest Expansion")
                  }
                  isChecked={Object.keys(metrics).includes("chest-expansion")}
                  label={"Chest Expansion"}
                  isDisabled={
                    Object.values(metrics).length === 2 &&
                    !Object.values(metrics).includes("Chest Expansion")
                  }
                />
                <DropdownItem
                  onClick={() =>
                    onItemClick("body-temperature", "Skin Temperature")
                  }
                  isChecked={Object.keys(metrics).includes("body-temperature")}
                  label={"Skin Temperature"}
                  isDisabled={
                    Object.values(metrics).length === 2 &&
                    !Object.values(metrics).includes("Skin Temperature")
                  }
                />
                <DropdownItem
                  onClick={() =>
                    onItemClick("position", "Position")
                  }
                  isChecked={Object.values(metrics).includes("Position")}
                  label={"Position"}
                  sublabel={isTableOpened ? undefined : "(not available for chart)"}
                  isDisabled={
                    !isTableOpened || (
                      Object.values(metrics).length === 2 &&
                      !Object.values(metrics).includes("Position")
                    )
                  }
                />
                <DropdownItem
                  onClick={() =>
                    onItemClick("activity-intensity", "Activity Level")
                  }
                  isChecked={Object.values(metrics).includes("Activity Level")}
                  label={"Activity Level"}
                  sublabel={isTableOpened ? undefined : "(not available for chart)"}
                  isDisabled={
                    !isTableOpened || (
                      Object.values(metrics).length === 2 &&
                      !Object.values(metrics).includes("Activity Level")
                    )
                  }
                />
              </>
            ) : (
              <>
                <DropdownItem
                  onClick={() => onItemClick("heart-rate", "Pulse Rate")}
                  isChecked={Object.keys(metrics).includes("heart-rate")}
                  label={"Pulse Rate"}
                  isDisabled={
                    Object.values(metrics).length === 2 &&
                    !Object.values(metrics).includes("Pulse Rate")
                  }
                />
                <DropdownItem
                  onClick={() =>
                    onItemClick("respiration-rate", "Respiratory Rate")
                  }
                  isChecked={Object.keys(metrics).includes("respiration-rate")}
                  label={"Respiratory Rate"}
                  isDisabled={
                    Object.values(metrics).length === 2 &&
                    !Object.values(metrics).includes("Respiratory Rate")
                  }
                />
                <DropdownItem
                  onClick={() =>
                    onItemClick(
                      "oxygen-saturation-level",
                      "Oxygen Saturation Level"
                    )
                  }
                  isChecked={Object.keys(metrics).includes(
                    "oxygen-saturation-level"
                  )}
                  label={"Oxygen Saturation Level"}
                  isDisabled={
                    Object.values(metrics).length === 2 &&
                    !Object.values(metrics).includes("Oxygen Saturation Level")
                  }
                />
                <DropdownItem
                  onClick={() =>
                    onItemClick("body-temperature", "Skin Temperature")
                  }
                  isChecked={Object.keys(metrics).includes("body-temperature")}
                  label={"Skin Temperature"}
                  isDisabled={
                    Object.values(metrics).length === 2 &&
                    !Object.values(metrics).includes("Skin Temperature")
                  }
                />
              </>
            )}
          </OutsideClickHandler>
        </div>
      )}
    </div>
  );
};

export default HistoryChartDropdown;
