import React, { FunctionComponent } from 'react';

interface Props {
  width: number;
  height: number;
}

export const DarkThemeIcon: FunctionComponent<Props> = ({width, height}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_5182_24163)">
        <rect y="3.05176e-05" width="32" height="32" rx="3" fill="#59669B"/>
        <path
          d="M15.9998 21.3334C18.9453 21.3334 21.3331 18.9456 21.3331 16.0001C21.3331 13.0545 18.9453 10.6667 15.9998 10.6667C13.0543 10.6667 10.6664 13.0545 10.6664 16.0001C10.6664 18.9456 13.0543 21.3334 15.9998 21.3334Z"
          stroke="white" strokeWidth="2" strokeLinejoin="round"/>
        <path
          d="M26.6667 16H28M4 16H5.33333M16 26.6667V28M16 4.00003V5.33336M23.5427 23.5427L24.4853 24.4854M7.51467 7.5147L8.45733 8.45736M8.45733 23.5427L7.51467 24.4854M24.4853 7.5147L23.5427 8.45736"
          stroke="white" strokeWidth="2" strokeLinecap="round"/>
      </g>
      <defs>
        <clipPath id="clip0_5182_24163">
          <rect y="3.05176e-05" width="32" height="32" rx="3" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};


