import React from "react";
import { useTranslation } from "react-i18next";
import { Formik, FormikProps } from "formik";
import { Button, TextField } from "../../../../../lib/components";
import { formSubmission } from "../../../../../lib/utils/form-submission";
import { createStyles, makeStyles } from "@material-ui/core";
import { TFunction } from "i18next";
import { object as yupObject, number } from "yup";
import { FrequencyReportInterface } from "../../../../../lib/interfaces/frequency-report.interface";

const useStyles = makeStyles(() =>
  createStyles({
    item: {
      marginBottom: 24,
      marginTop: -24,
    },
    btnBlock: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    textField: {
      fontSize: 11,
      fontWeight: 600,
      color: '#A0A0A0',
      lineHeight: "22px",
    },
    inputField: {
      width: '100%'
    },
    btnCancel: {
      width: 160,
      background: 'transparent',
      border: '1px solid #5C6AA9',
      color: '#5C6AA9',
      '&:hover': {
        background: 'transparent',
        color: '#5C6AA9',
      }
    },

    btnSave: {
      width: 160
    }
  })
);

interface Props {
  submitForm: (values: FrequencyReportInterface, onComplete: () => void) => void;
  closeForm: () => void;
}

const validationSchema = (t: TFunction) => yupObject().shape({
  minutes: number()
    .required(t('validation.defaultRequired'))
    .integer(t('validation.numericRequired'))
    .positive(t('validation.maxNormalLength'))
    .min(2, t('validation.minNormalLength'))
    .max(60, t('validation.maxNormalLength'))
    .typeError(t('validation.numericRequired'))
});

const UrgentModeForm = ({submitForm, closeForm}: Props) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const renderForm = ({
                        values,
                        setFieldValue,
                        touched,
                        errors,
                        setFieldTouched,
                        isSubmitting,
                        isValid,
                        setSubmitting
                      }: FormikProps<FrequencyReportInterface>) => {
    const onBlur = (name: string) => () => setFieldTouched(name);
    const onChangeText = (name: string) => (value: string) => setFieldValue(name, value);
    const keyboardSubmit = () => {
      if (isValid && values.minutes.length > 0) {
        setSubmitting(true);
        submitForm(values, () => setSubmitting(false));
      }
    };

    return (
      <>
        <div className={styles.item}>
          <p className={styles.textField}>Normal Reporting mode interval in minutes</p>
          <TextField
            label={t('placeholder.minutes')}
            onChange={onChangeText('minutes')}
            onBlur={onBlur('minutes')}
            value={values.minutes}
            type="string"
            error={Boolean(touched.minutes && errors.minutes)}
            disabled={isSubmitting}
            required={true}
            helperText={(touched.minutes && errors.minutes) ? errors.minutes : undefined}
            className={styles.inputField}
          />
        </div>

        <div className={styles.btnBlock}>
          <Button
            loading={isSubmitting}
            disabled={isSubmitting || !isValid || values.minutes.length < 1}
            onClick={keyboardSubmit}
            rootClass={styles.btnSave}
          >
            { t('button.save') }
          </Button>
          <Button
            disabled={isSubmitting}
            onClick={closeForm}
            rootClass={styles.btnCancel}
          >
            { t('button.cancel') }
          </Button>
        </div>
      </>
    )};

  return (
    <Formik
      initialValues={{ minutes: '' }}
      onSubmit={formSubmission<FrequencyReportInterface>(submitForm)}
      validationSchema={validationSchema(t)}
    >
      {renderForm}
    </Formik>
  );
}

export default UrgentModeForm;