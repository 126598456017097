import React, { memo, FunctionComponent, useState, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core";
// import { useTranslation } from 'react-i18next';
import { Case } from "../../../../lib/interfaces/case.interface";
import { patientMeasurement } from "../../../../lib/utils/patient-measurement";
import { colors } from "../../../../theme/colors";
import UrgentModeComponent from "../UrgentModeComponent";
import moment from "moment";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { formatDateTimeFullForm } from "../../../../lib/utils/format-date";
import ReactTooltip from "react-tooltip";
import BatteryLevel from "./BatteryLevel";
import { UserSettings } from "../../../../lib/interfaces/user-settings.interface";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: 64,
      paddingTop: 13,
      paddingBottom: 15,
      display: "flex",
      backgroundColor: colors.white,
      width: "100%",
      justifyContent: "space-between",
    },
    item: {
      display: "flex",
      // width: '50%',
      flexDirection: "row",
    },
    itemContent: {
      paddingLeft: 3,
    },
    batteryItem: {
      display: "flex",
      alignItems: "center",
      paddingLeft: 15,
      paddingRight: 10,
      borderRight: "1px solid #E0E0E0",
      marginRight: 10,
      // width: 100,
    },
    iconContainer: {
      marginTop: 5,
      cursor: "pointer",
    },
    tooltipText: {
      fontWeight: 400,
      fontSize: 12,
      margin: 0,
      paddingBottom: 1,
    },
    tooltip: {
      width: 280,
    },
  })
);

//todo: fix duplication with DetailsData
function isOldMeasurement(lastMeasurementDate: string | null) {
  if (lastMeasurementDate === null) {
    return true;
  } else {
    return moment().diff(moment(lastMeasurementDate), "minutes") > 60;
  }
}

interface Props {
  patientCase: Case;
  setFilter: (value: string) => void;
  setChosenElement: (value: number) => void;
  setGraphClick: (value: boolean) => void;
  setButtonDisable: (value: boolean) => void;
  filter: any;
  graphClick: boolean;
  buttonDisable: boolean;
  userSettings: UserSettings | null;
}

const PatientMetricsFooter: FunctionComponent<Props> = memo(
  ({ patientCase, userSettings }) => {
    const styles = useStyles();

    const [measurementFormat, setMeasurementFormat] = useState(
      userSettings?.measurementFormat
    );
    useEffect(() => {
      setMeasurementFormat(userSettings?.measurementFormat);
      // eslint-disable-next-line
    }, [userSettings?.measurementFormat]);

    const batteryStatus =
      measurementFormat &&
      patientMeasurement(
        "bs",
        patientCase.lastMeasurement?.params.bs ?? null,
        measurementFormat
      );
    const lastMeasurementDate = patientCase.lastMeasurementDate;
    const dataIsAbsent =
      (batteryStatus && batteryStatus.value === null) ||
      (batteryStatus && batteryStatus.value === undefined) ||
      isOldMeasurement(lastMeasurementDate);
    return (
      <div className={styles.container}>
        <div className={styles.item}>
          <div className={styles.batteryItem}>
            {dataIsAbsent ? null : patientCase?.lastMeasurement?.params?.bs
                ?.message &&
              patientCase?.lastMeasurement?.params?.bs?.message?.length > 0 ? (
              <>
                <div
                  className={styles.iconContainer}
                  data-tip
                  data-for={"footerId"}
                >
                  <InfoOutlinedIcon />
                </div>
                <ReactTooltip
                  id={"footerId"}
                  place="top"
                  backgroundColor="#FBFCFD"
                  effect="solid"
                  type="light"
                  className={styles.tooltip}
                >
                  <p className={styles.tooltipText}>{`${
                    patientCase?.lastMeasurement?.params?.bs?.message?.[0] ?? ""
                  }${
                    patientCase?.lastMeasurement?.params?.bs?.previousDataDate
                      ? ` ${formatDateTimeFullForm(
                          patientCase?.lastMeasurement?.params?.bs
                            ?.previousDataDate
                        )}`
                      : ""
                  }${
                    patientCase?.lastMeasurement?.params?.bs?.message?.[1] ?? ""
                  }`}</p>
                </ReactTooltip>
              </>
            ) : null}
            <div className={styles.itemContent}>
              <BatteryLevel
                value={patientCase?.lastMeasurement?.params?.bs.value}
              />
            </div>
          </div>

          <UrgentModeComponent
            caseId={patientCase.caseId}
            deviceId={patientCase.deviceId}
            urgentAttentionMode={patientCase.urgentAttentionMode}
            normalModeMinutes={patientCase.normalModeMinutes}
            pendingStatus={patientCase.reportingModeStatus}
            errorField={patientCase.errorMessage}
          />
        </div>
      </div>
    );
  }
);

export default PatientMetricsFooter;
