import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles, TableRow, TableCell, TableSortLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MUITableHead from '@material-ui/core/TableHead';
import { HeadCell } from '../../interfaces/head-cell.interface';
import { colors } from '../../../theme/colors';


const useStyles = makeStyles(() =>
  createStyles({
    tableCellRoot: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingRight: 5,
      paddingLeft: 5,
      '&:first-child': {
        paddingLeft: 18
      }
    },
    tableRowRoot: {
      backgroundColor: colors.backgroundGray
    }
  })
);

interface Props {
  order: 'asc'|'desc';
  orderBy: string;
  onSort: (event: React.MouseEvent<unknown>, property: string) => void;
  headCells: HeadCell[];
  translationKey?: string;
  omitSortKeys?: string[];
}

const TableHead: FunctionComponent<Props> = memo(({ 
  order, orderBy, onSort, headCells, translationKey, omitSortKeys
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const shouldOmit = (id: string) => omitSortKeys && omitSortKeys.indexOf(id) !== -1;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (!shouldOmit(property)) {
      onSort(event, property);
    }
  };
  const translateCollection = translationKey || 'headCell';


  return (
    <MUITableHead>
      <TableRow
        classes={{
          root: styles.tableRowRoot
        }}
      >
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            classes={{
              root: styles.tableCellRoot
            }}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              hideSortIcon={shouldOmit(headCell.id)}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label && t(`${translateCollection}.${headCell.label}`)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </MUITableHead>
  );
});

export default TableHead;
