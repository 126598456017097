import { saveAs } from 'file-saver';

function convertArrayOfObjectsToCSV(
  data: any[], columnDelimiter = ',', lineDelimiter = '\n',
): string|null {  
  if (data === null || !data.length) {
    return null;
  }
  const keys = Object.keys(data[0]);
  let result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;
  
  data.forEach(function(item) {
    let ctr = 0;
    keys.forEach(function(key) {
      if (ctr > 0) {
        result += columnDelimiter;
      }
      result += item[key];
      ctr++;
    });
    result += lineDelimiter;
  });
  
  return result;
}

export function downloadCSV(fileName: string, data: any[], head?: string) {
  let csv = convertArrayOfObjectsToCSV(data);
  if (csv === null) {
    return;
  }
  
  // if (!csv.match(/^data:text\/csv/i)) {
  //   csv = 'data:text/csv;charset=utf-8,' + (head ? (head + '\n\n') : '') + csv;
  // }

  downloadWithBlob((head ? (head + '\n\n') : '') + csv, `${fileName}.csv`);

  // const dataResult = encodeURI(csv);
  // const link = document.createElement('a');
  // link.setAttribute('href', dataResult);
  // link.setAttribute('download', `${fileName}.csv`);
  // link.click();
}

function downloadWithBlob(data: string, fileName: string) {
  var blob = new Blob([data], {
   type: 'text/csv;charset=utf-16'
  });
  
  saveAs(blob, fileName);
}
