import React, {memo, FunctionComponent} from 'react';
import useLoader from '../../../hooks/useLoader';
import { LoaderName } from '../../constants/loader-name.constant';
import LinearProgress from '../LinearProgress';

const LoaderComponent: FunctionComponent = memo(() => {
  const loader = useLoader();

  return loader && loader.id === LoaderName.GLOBAL ? <LinearProgress /> : null
});

export default LoaderComponent;
