import React, {ReactNode} from 'react';
import {makeStyles, createStyles} from '@material-ui/core';
import { colors } from '../../../theme/colors';
import { ReactComponent as TEKLogo } from '../common/tektelic-logo.svg';
import { ReactComponent as ProfileIcon } from '../common/profile-icon.svg';
import { ReactComponent as ContextMenuIcon } from '../common/context-menu-icon.svg';
import PatientsIcon from "../common/sidebar-icons/PatientsIcon";
import MenuItem from "../common/MenuItem";
import DevicesIcon from "../common/sidebar-icons/DevicesIcon";
import GatewaysIcon from "../common/sidebar-icons/GatewaysIcon";
import DocumentationIcon from "../common/sidebar-icons/DocumantatoinIcon";
import SupportIcon from "../common/sidebar-icons/SupportIcon";
import {NavRoute} from "../../../lib/constants/nav-route.constant";
import {useHistory, useLocation} from "react-router-dom";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100vh',
      minHeight: 500,
      minWidth: 800,
      backgroundColor: colors.blackTheme.background,
      color: colors.blackTheme.menuTextColor,
      fontSize: 20,
      fontFamily: 'Raleway, sans-serif',
      display: 'flex',
    },
    sidebar: {
      minWidth: 300,
      backgroundColor: colors.blackTheme.sidebarBackground,
      paddingTop: 24,
      paddingBottom: 24,
      paddingLeft: 12,
      paddingRight: 12,
      display: 'flex',
      flexDirection: 'column',
    },
    logo: {
      height: 66,
    },
    topMenu: {
      marginTop: 28,
      display: 'flex',
      gap: 18,
      flexDirection: 'column',
    },
    bottomMenu: {
      marginTop: 'auto',
      display: 'flex',
      gap: 10,
      flexDirection: 'column',
    },
    version: {
      paddingLeft: 12,
    },
    content: {
      flexGrow: 2,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
    },
    header: {
      position: 'sticky',
      width: '100%',
      minWidth: '100%',
      top: 0,
      backgroundColor: colors.blackTheme.headerBackground,
      minHeight: 94,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 40,
      paddingRight: 40,
      fontWeight: 'bold',
      fontSize: 24,
      boxShadow: '0px 3px 6px #00000029',
    },
    headerMenu: {
      fontWeight: 600,
      fontSize: 19,
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center',
      gap: 12,
    },
    userName: {
      width: 140,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);

interface Props {
  children: ReactNode;
  title: ReactNode;
}

const Layout = ({children, title} : Props) => {
  const history = useHistory();
  const location = useLocation();
  const isDevicesPage = location.pathname === NavRoute.MAIN.MANAGE_DEVICES;
  const isPatientPage = location.pathname === NavRoute.MAIN.PATIENT;

  const goToMain = () => history.replace(NavRoute.MAIN.INIT);
  const styles = useStyles();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.sidebar}>
          <div className={styles.logo}>
            <TEKLogo/>
          </div>
          <div className={styles.topMenu}>
            <MenuItem onClick={() => goToMain()} active={isPatientPage} IconComponent={PatientsIcon}>
              Patients
            </MenuItem>
            <MenuItem onClick={() => history.replace(NavRoute.MAIN.MANAGE_DEVICES)} active={isDevicesPage} IconComponent={DevicesIcon}>
              Devices
            </MenuItem>
            <MenuItem IconComponent={GatewaysIcon}>
              Gateways
            </MenuItem>
          </div>
          <div className={styles.bottomMenu}>
            <MenuItem IconComponent={DocumentationIcon}>
              Documentation
            </MenuItem>
            <MenuItem IconComponent={SupportIcon}>
              Support
            </MenuItem>
            <div className={styles.version}>
              Version {process.env.REACT_APP_VERSION}
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.header}>
            {title}
            <div className={styles.headerMenu}>
              <ProfileIcon/>
              <div className={styles.userName}>
                John Doe
              </div>
              <ContextMenuIcon/>
            </div>
          </div>
        {children}
        </div>
      </div>
    </>
  );
};

export default Layout;
