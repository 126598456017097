import React, {memo, FunctionComponent} from 'react';
import MUILinearProgress from '@material-ui/core/LinearProgress'

interface Props {}

const LinearProgress: FunctionComponent<Props> = memo(() => {
  return (
    <MUILinearProgress />
  );
});

export default LinearProgress;
