import React, {memo, FunctionComponent} from 'react';
import Snackbar from '../Snackbar';
import { MessageVariant } from '../../constants/message-variant.enum';

interface Props {
  message: any;
  setMessage: (value: any) => void;
}

const MessageDisplay: FunctionComponent<Props> = memo(({ message, setMessage }) => {
  const closeMessage = () => setMessage(null);

  return (
    <Snackbar
      open={Boolean(message)}
      type={message && message.type ? message.type : MessageVariant.MESSAGE}
      message={message ? message.message : ''}
      onClose={ closeMessage }
    />
  );
});

export default MessageDisplay;
