export function getActivityFactor(value: number): string {
  // * This is done in order to meet requirements of activity factor MD-293
  const modifiedValue = value / 1000;

  if (modifiedValue < 0.1) {
    return 'activityFactor.low';
  } else if (modifiedValue > 0.3) {
    return 'activityFactor.high';
  } else {
    return 'activityFactor.med';
  }
}
