import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { SignInFormValues } from '../../interfaces/form-values.interface';
import { object as yupObject, string as yupString } from 'yup';
import { formSubmission } from '../../../../../lib/utils/form-submission';
import { Button, TextField } from '../../../../../lib/components';
import { TFunction } from 'i18next';
import { NavRoute } from '../../../../../lib/constants/nav-route.constant';

const useStyles = makeStyles(() =>
  createStyles({
    item: {
      marginBottom: 24 
    }
  })
);

interface Props {
  history: any;
  submitForm: (values: SignInFormValues, event: () => void) => void;
}

const validationSchema = (t: TFunction) => yupObject().shape({
  email: yupString()
    .email(t('validation.invalidEmailFormat'))
    .required(t('validation.emailRequired')),
  pass: yupString()
    .min(8, t('validation.minLength', { num: 8 }))
    .required(t('validation.passwordRequired'))
});

const SignInForm: FunctionComponent<Props> = memo(({ history, submitForm }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const navigateToForgot = (email: string) => () => history.push({
    pathname: NavRoute.AUTH.FORGOT_PASSWORD,
    state: { email }
  });

  const renderForm = ({
    values,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
    isSubmitting,
    isValid
  }: FormikProps<SignInFormValues>) => {
    const onBlur = (name: string) => () => setFieldTouched(name);
    const onChangeText = (name: string) => (value: string) => setFieldValue(name, value);

    return (
      <>
        <div className={styles.item}>
          <TextField 
            label={t('placeholder.emailAddress')}
            onChange={onChangeText('email')}
            onBlur={onBlur('email')}
            value={values.email}
            type="email-address"
            error={Boolean(touched.email && errors.email)}
            disabled={isSubmitting}
            required={true}
            helperText={(touched.email && errors.email) ? errors.email : undefined}
          />
        </div>

        <div className={styles.item}>
          <TextField
            label={t('placeholder.password')}
            onChange={onChangeText('pass')}
            onBlur={onBlur('pass')}
            value={values.pass}
            type="password"
            error={Boolean(touched.pass && errors.pass)}
            disabled={isSubmitting}
            required={true}
            helperText={(touched.pass && errors.pass) ? errors.pass : undefined}
          />
        </div>
        
        <div className={styles.item}>
          <Button
            variant="text"
            onClick={navigateToForgot(values.email)}
          >
            { t('button.forgotPasswordLink') }
          </Button>
        </div>

        <div className={styles.item}>
          <Button
            loading={isSubmitting}
            disabled={isSubmitting || !isValid}
            onClick={handleSubmit}
          >
            { t('button.signInButton') }
          </Button>
        </div>
      </>
  )};

  return (
    <Formik
      initialValues={{ email: '', pass: '' }}
      onSubmit={formSubmission<SignInFormValues>(submitForm)}
      validationSchema={validationSchema(t)}
    >
      {renderForm}
    </Formik>
  );
});

export default SignInForm;
