import React, {memo, FunctionComponent, useState} from 'react';
import { createStyles, FormControl, MenuItem, Theme, withStyles, InputBase, makeStyles, FormHelperText } from '@material-ui/core';
import MUISelect from '@material-ui/core/Select'
import { SelectInterface } from '../../interfaces/select-elements.interface';
import {colors} from "../../../theme/colors";
import { ReactComponent as SelectArrowIcon } from '../../../theme/icons/select-arrow.svg';


const useStyles = makeStyles(() => 
  createStyles({
    root: {
      width: '100%'
    }
  })
) 

interface Props {
  elements: SelectInterface[];
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  error?: boolean;
  required?: boolean;
  helperText?: string;
}

const Select: FunctionComponent<Props> = memo(({ 
  elements, value, onChange, placeholder, disabled, error, required, helperText
}) => {
  const styles = useStyles();
  const [isPlaceholderSelected, setIsPlaceholderSelected] = useState(true);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as string);
    setIsPlaceholderSelected(event.target.value === '');
  };
  const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
      root: {
        'label + &': {
          marginTop: theme.spacing(2),
        }
      },
      input: {
        color: isPlaceholderSelected ? colors.placeholderText : 'inherit',
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${error ? 'rgba(255, 0, 0, 1)' : 'rgba(0, 0, 0, 0.23)'}`,
        fontSize: 16,
        padding: '18px 26px 17px 12px',
        transition: theme.transitions.create(['box-shadow']),
        '&:hover': {
          borderColor: 'black',
        }
      },
    }),
  )(InputBase);

  return (
    <FormControl className={styles.root} variant="filled" error={error}>
      <MUISelect
        value={value}
        onChange={handleChange}
        input={<BootstrapInput />}
        displayEmpty
        required={required}
        disabled={disabled}
        inputProps={{name: 'select', id: 'mui-select'}}
        IconComponent={SelectArrowIcon}
      >
        {placeholder ? <MenuItem disabled value="">{placeholder}</MenuItem> : null}

        {
          elements.map(item => <MenuItem key={item.key || item.value} value={item.value}>{item.text}</MenuItem>)
        }
      </MUISelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
});

export default Select;
