import {LastMeasurements, MetricItem} from "../../../../../../lib/interfaces/metric-history.interface";
import {convertMetricValueToChartCoordinate} from "./convertMetricValueToChartCoordinate";
import moment from "moment";
import {DATE_TIME_FULL_FORMAT} from "../../../../../../lib/constants/date-format.constant";
import {ChartItemWithTimestamps} from "./chart-types";
import {ChartMetricName} from "../utils";
import {convertToMillis} from "../../history-chart-utils";

export function convertResponseDataToChartItems(chartData: MetricItem[], chartMetrics: string[], lastMeasurements: LastMeasurements) :ChartItemWithTimestamps[] {
  const NUMBER_OF_CHARTS = 4;
  const CHART_PADDING = 2;
  const formattedData: ChartItemWithTimestamps[] = [];
  chartData.filter(item => {
    return chartMetrics.includes(item.metric);
  }).forEach((item) => {
    const metric = item.metric;
    if (item.units.length > 0) {
      item.units.forEach((unit) => {
        const existingItem = formattedData.find(
          (resultItem) => resultItem.timeFromResponse === unit.x
        );
        if (existingItem) {
          existingItem.items[metric as ChartMetricName] = {
            value: unit.y,
            coordinate: convertMetricValueToChartCoordinate(
              unit.y,
              lastMeasurements[metric].min,
              lastMeasurements[metric].max,
              NUMBER_OF_CHARTS,
              chartMetrics.indexOf(metric),
              CHART_PADDING
            )
          };
        } else {
          const timestamp = convertToMillis(unit.x);
          const newItem: ChartItemWithTimestamps = {
            timeFromResponse: unit.x,
            timestamp: timestamp,
            tooltipTime: moment(timestamp).format(DATE_TIME_FULL_FORMAT),
            items: {
              HeartRate: {value: null, coordinate: null},
              RespirationRate: {value: null, coordinate: null},
              ChestExpansion: {value: null, coordinate: null},
              OxygenSaturationLevel: {value: null, coordinate: null},
              BodyTemperature: {value: null, coordinate: null},
            }
          };

          newItem.items[metric as ChartMetricName] = {
            value: unit.y,
            coordinate: convertMetricValueToChartCoordinate(
              unit.y,
              lastMeasurements[metric].min,
              lastMeasurements[metric].max,
              NUMBER_OF_CHARTS,
              chartMetrics.indexOf(metric),
              CHART_PADDING
            )
          };
          formattedData.push(newItem);
        }
      });
    }
  });

  return formattedData;
}
