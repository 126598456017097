import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { object as yupObject, string as yupString, ref } from 'yup';
import { Formik, FormikProps } from 'formik';
import { SignInConfirmFormValues } from '../../interfaces/form-values';
import { formSubmission } from '../../../../../lib/utils/form-submission';
import { TFunction } from 'i18next';
import { Button, PasswordField } from '../../../../../lib/components';

const useStyles = makeStyles(() =>
  createStyles({
    item: {
      marginBottom: 24 
    }
  })
);


interface Props {
  submitForm: (values: SignInConfirmFormValues, event: () => void) => void
}

const validationSchema = (t: TFunction) => yupObject().shape({
  pass: yupString()
    .min(8, t('validation.passwordMinLength'))
    .required(t('validation.passwordRequired')),
  confirmPass: yupString().when('pass', {
      is: val => val && val.length > 0,
      then: yupString()
        .oneOf([ref('pass')], t('validation.passwordMatch'))
        .required(t('validation.defaultRequired'))
    }),
});

const SignInSubmitForm: FunctionComponent<Props> = memo(({ submitForm }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const renderForm = ({
    values,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
    isSubmitting,
    isValid,
  }: FormikProps<SignInConfirmFormValues>) => {
    const onChangeText = (keyName: string) => (value: string) => setFieldValue(keyName, value);
    const onBlur = (keyName: string) => () => setFieldTouched(keyName);

    return (
      <>
        <div className={styles.item}>
          <PasswordField
            label={t('placeholder.newPassword')}
            onChange={onChangeText('pass')}
            onBlur={onBlur('pass')}
            value={values.pass}
            error={Boolean(touched.pass && errors.pass)}
            disabled={isSubmitting}
            required
            showHelp
            helperText={(touched.pass && errors.pass) ? errors.pass : undefined}
          />
        </div>

        <div className={styles.item}>
          <PasswordField
            label={t('placeholder.confirmNewPassword')}
            onChange={onChangeText('confirmPass')}
            onBlur={onBlur('confirmPass')}
            value={values.confirmPass}
            error={Boolean(touched.confirmPass && errors.confirmPass)}
            disabled={isSubmitting}
            required
            showHelp
            helperText={(touched.confirmPass && errors.confirmPass) ? errors.confirmPass : undefined}
          />
        </div>

        <div className={styles.item}>
          <Button
            loading={isSubmitting}
            disabled={isSubmitting || !isValid}
            onClick={handleSubmit}
          >
            { t('button.submitPassword') }
          </Button>
        </div>
      </>
    )
  };

  return (
    <Formik
      initialValues={{ pass: '', confirmPass: '' }}
      onSubmit={formSubmission<SignInConfirmFormValues>(submitForm)}
      validationSchema={validationSchema(t)}
    >
      {renderForm}
    </Formik>
  );
});

export default SignInSubmitForm;
