import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import { colors } from "../../../../../theme/colors";
import MetricsTile from "./MetricTile";
import {
  EBEAT_METRICS,
  EDOCTOR_METRICS,
  LastHourMetrics,
  MetricLabels, MetricName,
} from "../../../../../lib/interfaces/metric-history.interface";
import AdditionalMetricTile from "./AdditionalMetricTile";
import Chart from "./Chart";
import { Case } from "../../../../../lib/interfaces/case.interface";
import {
  ImperialExtension,
  MetricExtension,
} from "../../../../../lib/constants/value-extension.constant";
import { useTranslation } from "react-i18next";
import {
  authService,
  metricService,
  caseService,
} from "../../../../../services";
import { finalize, map, switchMap } from "rxjs/operators";
import LoadingDataSpinner from "../../../../redesign/common/LoadingDataSpinner";
import { AppContext } from "../../../../../state/AppContext";
import { sortByDate } from "../../../../../lib/utils/stable-sort";
import { Subscription } from "rxjs";
import { themeCss } from "../../../../../lib/utils/theme";
import NoDataAvailable from "../NoDataAvailable";
import { UserSettings } from "../../../../../lib/interfaces/user-settings.interface";
import {getMetricValue} from "../../../../../lib/utils/get-metric-value";
import {MetricNameToAbbreviation} from "../../../../../lib/constants/metrics-name.enum";
import NoChartDataIcon from "../../../../../theme/icons/NoChartDataIcon";
import {DeviceType} from "../../../../../lib/interfaces/device-type";
//todo: extract to separate component
type LastMeasurementsWithChartProps = {
  children: React.ReactNode,
  lastHourMetrics: LastHourMetrics,
  additionalMetrics?: React.ReactNode,
}

type Props = {
  patientCase: Case;
  userSettings: UserSettings | null;
  //todo: delete from properties, because Context is used
  isFullScreen: boolean;
};

const LastHourChart: FunctionComponent<Props> = ({
  patientCase,
  userSettings,
}: Props) => {
  const { isFullScreen, setCases, theme } = useContext(AppContext);
  const useStyles = makeStyles(() =>
    createStyles({
      chartContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 31,
        position: "relative",
        flex: 1,
      },
      lastMeasurements: {
        display: "flex",
        flexDirection: "column",
        width: (isFullScreen ? 442 : 331) - 30,
        marginBottom: 'calc(34px)',
        marginLeft: -30,
        marginTop: 4,
      },
      additionalTiles: {
        fontSize: 14,
        fontWeight: 600,
        display: "flex",
        flexDirection: "column",
        gap: 12,
      },
      chart: {
        flex: 1,
        position: "relative",
      },
      noLastHourDataContainer: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "normal",
        color: colors.lightTheme.noLastHourDataText,
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        gap: 13,
      },
      noLastHourDataTitle: {
        fontSize: 18,
        fontWeight: 700,
      },
      lastMeasurementsWithChart: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
      },
      additionalMetrics: {
        marginBottom: 30,
        height: 42,
        display: "flex",
        gap: 30,
        justifyContent: "flex-end",
      },
      mainMetrics: {
        display: "flex",
      }
    })
  );
  const styles = useStyles();
  const [lastHourMetrics, setLastHourMetrics] =
    useState<LastHourMetrics | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const caseId = patientCase?.caseId;

  useEffect(() => {
    if (caseId && userSettings?.measurementFormat) {
      setIsLoading(true);
      setLastHourMetrics(null);
      const hoursOffset = -new Date().getTimezoneOffset();
      authService
        .getUserSettings()
        .pipe(
          switchMap(() =>
            metricService.getLastHourMetrics(caseId, hoursOffset)
          ),
          finalize(() => setIsLoading(false))
        )
        .subscribe((lastHourMetrics) => {
          setLastHourMetrics(lastHourMetrics);
        });
    }
  }, [caseId, userSettings?.measurementFormat]);

  useEffect(() => {
    const hoursOffset = -new Date().getTimezoneOffset();
    let sub: Subscription;
    const interval = setInterval(() => {
      sub = caseService
        .getCasesList()
        .pipe(
          map(sortByDate("initiationDate")),
          switchMap((cases) => {
            return authService.getUserSettings().pipe(
              map((settings) => {
                return { cases, settings };
              })
            );
          }),
          switchMap(({ cases, settings }) => {
            return metricService.getLastHourMetrics(caseId, hoursOffset).pipe(
              map((lastHourMetrics) => {
                return { cases, settings, lastHourMetrics };
              })
            );
          })
        )
        .subscribe(({ cases, lastHourMetrics }) => {
          setCases(cases);
          setLastHourMetrics(lastHourMetrics);
        });
    }, 60000);
    return () => {
      clearInterval(interval);
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [setCases, caseId, userSettings?.measurementFormat]);

  function isDataAbsent(metrics: MetricName[]) {
    if (lastHourMetrics === null) {
      return true;
    }
    const selectedMetricItems = lastHourMetrics.chartData.filter((metricItem) => {
      return metrics.includes(metricItem.metric);
    });
    return selectedMetricItems.every((metricItem) => metricItem.units.length === 0);
  }

  function isValidMetrics(metrics: string[]) {
    if (!lastHourMetrics) {
      return false;
    }
    return Object.keys(lastHourMetrics.lastMeasurements).every((metricName) => {
      return metrics.includes(metricName);
    });
  }

  const { t } = useTranslation();

  let extension = MetricExtension;
  if (userSettings && userSettings.measurementFormat === "metric") {
    extension = MetricExtension;
  } else if (userSettings && userSettings.measurementFormat === "imperial") {
    extension = ImperialExtension;
  }

  const noDataAvailableMessage = (
    <NoDataAvailable
      icon={<NoChartDataIcon/>}
      firstLineText={"Please ensure the device is on the"}
      secondLineText={"patient and check their condition"}
    />
  );

  let content = <div></div>;

  function formatValue(value: number | null, metricName: MetricName) {
    if (value === null) {
      return "-";
    }
    return getMetricValue(metricName, value, t);
  }

  function createMetricsTile(lastHourMetrics: LastHourMetrics, metricName: MetricName, color: string, showThresholds=true) {
    const unitKey = MetricNameToAbbreviation[metricName];
    return (
      <MetricsTile
        isFullScreen={isFullScreen}
        outOfRange={lastHourMetrics.lastMeasurements[metricName].outOfRange}
        color={color}
        label={MetricLabels[metricName]}
        value={formatValue(lastHourMetrics.lastMeasurements[metricName].value, metricName)}
        units={extension[unitKey]}
        min={formatValue(lastHourMetrics.lastMeasurements[metricName].min, metricName)}
        max={formatValue(lastHourMetrics.lastMeasurements[metricName].max, metricName)}
        message={lastHourMetrics.lastMeasurements[metricName].message}
        thresholdMin={formatValue(patientCase.thresholds.find(threshold => threshold.metric === metricName)?.lowRange || null, metricName)}
        thresholdMax={formatValue(patientCase.thresholds.find(threshold => threshold.metric === metricName)?.highRange || null, metricName)}
        showThresholds={showThresholds}
      />
    );
  }

  //todo: move to separate component
  const LastMeasurementsWithChart = ({children, lastHourMetrics, additionalMetrics}: LastMeasurementsWithChartProps) => {
    return (
      <div className={styles.lastMeasurementsWithChart}>
        <div className={styles.additionalMetrics}>{additionalMetrics}</div>
        <div className={styles.mainMetrics}>
          <div className={styles.chart}>
            <Chart
              lastHourMetrics={lastHourMetrics}
              deviceType={patientCase.deviceType}
              extension={extension}
            />
          </div>
          <div className={styles.lastMeasurements}>
            {children}
          </div>
        </div>
      </div>
    );
  }

  function getAdditionalMetricValue(deviceType: DeviceType, lastHourMetrics: LastHourMetrics, additionalMetricName: MetricName) {
    let result = "-";
    if (deviceType === "eDoctor" && lastHourMetrics) {
      let value = lastHourMetrics.lastMeasurements[additionalMetricName].value;
      if (value !== null) {
        result = getMetricValue(additionalMetricName, value, t);
      }
    }
    return result;
  }

  if (isLoading) {
    content = (
      <LoadingDataSpinner
        text={"Loading ..."}
        textColor={colors.lightTheme.spinnerText}
      />
    );
  } else if (lastHourMetrics === null) {
    content = noDataAvailableMessage;
  } else if (patientCase.deviceType === "eDoctor") {
    if (!isValidMetrics(EDOCTOR_METRICS)) {
      // true, when the lastHourMetrics is not fetched from the server yet when switching from eBeat
      content = <div></div>;
    } else if (isDataAbsent(EDOCTOR_METRICS)) {
      content = noDataAvailableMessage;
    } else {
      content = (
        <LastMeasurementsWithChart lastHourMetrics={lastHourMetrics} additionalMetrics={
          <>
            <AdditionalMetricTile
              titleValue={"Position"}
              color={colors[themeCss(theme)].position}
              value={getAdditionalMetricValue(patientCase.deviceType, lastHourMetrics, "Position")}
              message={lastHourMetrics.lastMeasurements.Position.message}
              size={isFullScreen ? "large" : "small"}
            />
            <AdditionalMetricTile
              titleValue={"Activity Level"}
              color={colors[themeCss(theme)].activityLevel}
              value={getAdditionalMetricValue(patientCase.deviceType, lastHourMetrics, "ActivityIntensity")}
              message={
                lastHourMetrics.lastMeasurements.ActivityIntensity.message
              }
              size={isFullScreen ? "large" : "small"}
            />
          </>
        }>
          {createMetricsTile(lastHourMetrics, 'HeartRate', colors[themeCss(theme)].heartRate)}
          {createMetricsTile(lastHourMetrics, 'RespirationRate', colors[themeCss(theme)].respirationRate)}
          {createMetricsTile(lastHourMetrics, 'BodyTemperature', colors[themeCss(theme)].bodyTemperature)}
          {createMetricsTile(lastHourMetrics, 'ChestExpansion', colors[themeCss(theme)].chestExpansion, false)}
        </LastMeasurementsWithChart>
      );
    }
  } else if (patientCase.deviceType === "eBeat") {
    if (!isValidMetrics(EBEAT_METRICS)) {
      // true, when the lastHourMetrics is not fetched from the server yet
      content = <div></div>;
    } else if (isDataAbsent(EBEAT_METRICS)) {
      content = noDataAvailableMessage;
    } else {
      content = (
        <LastMeasurementsWithChart lastHourMetrics={lastHourMetrics}>
          {createMetricsTile(lastHourMetrics, 'HeartRate', colors[themeCss(theme)].heartRate)}
          {createMetricsTile(lastHourMetrics, 'RespirationRate', colors[themeCss(theme)].respirationRate)}
          {createMetricsTile(lastHourMetrics, 'OxygenSaturationLevel', colors[themeCss(theme)].oxygenSaturationLevel)}
          {createMetricsTile(lastHourMetrics, 'BodyTemperature', colors[themeCss(theme)].bodyTemperature)}
        </LastMeasurementsWithChart>
      );
    }
  } else {
    content = noDataAvailableMessage;
  }
  return <div className={styles.chartContainer}>{content}</div>;
};

export default LastHourChart;
