/**
 * Converts a hexadecimal color value to an RGBA color value.
 *
 * @param {string} hex - The hexadecimal color value. This can be in the format '#RGB' or '#RRGGBB'.
 * @param {number} alpha - The opacity of the color. This should be a number between 0 (completely transparent) and 1 (completely opaque).
 * @returns {string} The RGBA color value in the format 'rgba(r, g, b, a)'.
 *
 * @example
 * // returns 'rgba(255,0,0,0.5)'
 * hexToRgbA('#FF0000', 0.5);
 */
export function hexToRgbA(hex: string, alpha: number) {
  let r: any, g: any, b: any;
  // If the hex color is in the format '#RGB'
  if (hex.length === 4) {
    r = "0x" + hex[1] + hex[1];
    g = "0x" + hex[2] + hex[2];
    b = "0x" + hex[3] + hex[3];
  }
  // If the hex color is in the format '#RRGGBB'
  else if (hex.length === 7) {
    r = "0x" + hex[1] + hex[2];
    g = "0x" + hex[3] + hex[4];
    b = "0x" + hex[5] + hex[6];
  }
  // Return the RGBA color value
  return "rgba(" + +r + "," + +g + "," + +b + "," + alpha + ")";
}
