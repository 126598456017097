import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import MUIFab from '@material-ui/core/Fab'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#5A69AE',
      color: '#FFFFFF'
    }
  })
);

interface Props {
  onClick: () => void;
  size?: 'large'|'medium'|'small';
  disabled?: boolean;
  children: NonNullable<React.ReactNode>;
}

const Fab: FunctionComponent<Props> = memo(({ 
  onClick, children, size, disabled
}) => {
  const styles = useStyles();

  return (
    <MUIFab
      classes={{
        root: styles.root
      }}
      onClick={onClick}
      size={size || 'large'}
      disabled={disabled}
    >
      { children }
    </MUIFab>
  );
});

export default Fab;
