import React, {memo, FunctionComponent} from 'react';
import MUITypography from '@material-ui/core/Typography';

const Typography: FunctionComponent<any> = memo((props) => {
  return (
    <MUITypography {...props}>
      { props.children }
    </MUITypography>
  );
});

export default Typography;
