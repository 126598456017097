import React, {FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import MUIDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles(() =>
  createStyles({
    actionsRoot: {
      justifyContent: 'center'
    },
    dialogPaper: {
      boxShadow: 'none',
      borderRadius: 10,
      width: 375,
      paddingTop: 40,
      paddingLeft: 24,
      paddingRight: 24,
      paddingBottom: 40,
      backgroundColor: '#FBFCFD',
    },
    customDialogTitle: {
      padding: 0,
    },
    titleText: {
      fontSize: 16,
      fontWeight: 600,
      textAlign: 'center',
    },
    customDialogContent: {
      padding: 0,
      paddingTop: 15,
    },
  })
);

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  title?: string;
}

const EditPatientDialog: FunctionComponent<Props> = (
  {
    children, open, setOpen, title
  }) => {
  const styles = useStyles();
  const onClose = () => setOpen(false);

  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      classes={{
        paper: styles.dialogPaper
      }}
    >
      {
        title && <DialogTitle className={styles.customDialogTitle}>
          <div className={styles.titleText}>{title}</div>
        </DialogTitle>
      }
      <DialogContent className={styles.customDialogContent}>
        {children}
      </DialogContent>
    </MUIDialog>
  );
};

export default EditPatientDialog;
