import React, {memo, FunctionComponent} from 'react';
import { IconButton as MUIIconButton, makeStyles, createStyles } from '@material-ui/core';
import Icon from '../Icon';

const useStyles = makeStyles(() =>
createStyles({
  icon: {
    padding: '0!important',
    margin: '0!important',
  }
})
);

interface Props {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const IconButton: FunctionComponent<Props> = memo(({ children, onClick, onMouseDown }) => {
  const styles = useStyles();
  return (
    <MUIIconButton onClick={onClick} onMouseDown={onMouseDown}>
      <Icon className={styles.icon}>
        {children}
      </Icon>
    </MUIIconButton>
  );
});

export default IconButton;
