import React, {FunctionComponent, useContext} from "react";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis} from "recharts";
import {LastHourMetrics, MetricLabels} from "../../../../../../lib/interfaces/metric-history.interface";
import moment from "moment/moment";
import {colors} from "../../../../../../theme/colors";
import {createStyles, makeStyles} from "@material-ui/core";
import {
  UnitKey,
  UnitsExtension
} from "../../../../../../lib/constants/value-extension.constant";
import {isNullable} from "../../../../../../lib/utils/checkValue";
import {AppContext} from "../../../../../../state/AppContext";
import {themeCss} from "../../../../../../lib/utils/theme";
import { getMetricColor } from "../utils";
import {DeviceType} from "../../../../../../lib/interfaces/device-type";
import {getNumericMetricValue} from "../../../../../../lib/utils/get-metric-value";
import {TIME_FORMAT} from "../../../../../../lib/constants/date-format.constant";
import {convertResponseDataToChartItems} from "./convertResponseDataToChartItems";
import {ChartItemWithTimestamps} from "./chart-types";
import {getMillisecondsForXAxisTicks} from "./getMillisecondsForXAxisTicks";
import {createTicksForYAxis} from "./createTicksForYAxis";

const useStyles = makeStyles(() =>
  createStyles({
    chartPopup: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 3,
      fontSize: 10,
      fontWeight: 400,
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 9,
      paddingBottom: 9,
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    },
    'chartPopup--light': {
      borderColor: colors.lightTheme.chartPopupBorder,
      backgroundColor: colors.lightTheme.chartPopupBackground,
      color: colors.lightTheme.chartPopupFontColor,
    },
    'chartPopup--dark': {
      borderColor: colors.blackTheme.chartPopupBorder,
      backgroundColor: colors.blackTheme.chartPopupBackground,
      color: colors.blackTheme.chartPopupFontColor,
    },
    chartPopupDate: {
      fontWeight: 600,
    },
    chartPopupValue: {
      display: 'flex',
      gap: 6,
      alignItems: 'center',
    },
    chartPopupDot: {
      width: 7,
      height: 7,
      borderRadius: '50%',
      display: 'inline-block',
    },
    chartPopupValueNumber: {
      fontWeight: 600,
    },
  })
);

type Props = {
  lastHourMetrics: LastHourMetrics;
  deviceType: DeviceType;
  extension: UnitsExtension;
}

function convertToMillis(timeInLocalTimezone: string) {
  return moment(timeInLocalTimezone).local().valueOf();
}

const Chart: FunctionComponent<Props> = ({lastHourMetrics, deviceType, extension}: Props) => {
  const { isFullScreen, theme} = useContext(AppContext);
  const styles  = useStyles();

  const from = convertToMillis(lastHourMetrics.from);
  const to = convertToMillis(lastHourMetrics.to);

  type EdoctorChartMetricName = 'HeartRate' | 'RespirationRate' | 'ChestExpansion' | 'BodyTemperature';
  type EbeatChartMetricName = 'HeartRate' | 'RespirationRate' | 'OxygenSaturationLevel' | 'BodyTemperature';

  type ChartMetricName = EdoctorChartMetricName | EbeatChartMetricName;

  let chartMetrics : ChartMetricName[] = [];
  if (deviceType === 'eDoctor') {
    chartMetrics = ['HeartRate', 'RespirationRate', 'BodyTemperature', 'ChestExpansion'];
  }
  if (deviceType === 'eBeat') {
    chartMetrics = ['HeartRate', 'RespirationRate', 'OxygenSaturationLevel', 'BodyTemperature'];
  }

  function showPopupValue(data: ChartItemWithTimestamps, metric: ChartMetricName, extensionCode: UnitKey) {
    const value = data.items[metric].value;
    return value !== null && (
      <div className={styles.chartPopupValue}>
        <div className={styles.chartPopupDot} style={{backgroundColor: getMetricColor(metric, theme)}}></div>
        <div>{`${MetricLabels[metric]}:`}</div>
        <div className={styles.chartPopupValueNumber}>{getNumericMetricValue(metric, value)} {extension[extensionCode]}</div>
      </div>
    )
  }

  const CustomTooltip = ({active, payload}: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      const data: ChartItemWithTimestamps = payload[0].payload;
      return (
        <div className={`${styles.chartPopup} ${styles[`chartPopup--${theme}`]}`}>
          <div
            className={styles.chartPopupDate}>{data.tooltipTime}
          </div>
          {!isNullable(data.items.HeartRate.value) && showPopupValue(data, 'HeartRate', 'hr')}
          {!isNullable(data.items.RespirationRate.value) && showPopupValue(data, 'RespirationRate', 'rr')}
          {deviceType === 'eDoctor' && <>
            {!isNullable(data.items.BodyTemperature.value) && showPopupValue(data, 'BodyTemperature', 'bt')}
            {!isNullable(data.items.ChestExpansion.value) && showPopupValue(data, 'ChestExpansion', 'ce')}
          </>}
          {deviceType === 'eBeat' && <>
            {!isNullable(data.items.OxygenSaturationLevel.value) && showPopupValue(data, 'OxygenSaturationLevel', 'spo2')}
            {!isNullable(data.items.BodyTemperature.value) && showPopupValue(data, 'BodyTemperature', 'bt')}
          </>}
        </div>
      );
    }
    // without this div the first tooltip render will be in top left corner
    return <div>Empty</div>
  };

  const data = convertResponseDataToChartItems(lastHourMetrics.chartData, chartMetrics, lastHourMetrics.lastMeasurements);
  const xAxisTicks = getMillisecondsForXAxisTicks(from, to);
  const ticksForYAxis = createTicksForYAxis(4);
  const lines = chartMetrics.map(item => {
    return {dataKey: `items.${item}.coordinate`, color: getMetricColor(item, theme)}
  });
  return <ResponsiveContainer width="100%" height="100%" >
    <LineChart
      data={data}
      margin={{
        top: 5,
        right: 30,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="6 6" stroke={colors[themeCss(theme)].chartGridColor}/>
      <XAxis
        dataKey="timestamp"
        domain={[from, to]}
        type={'number'}
        ticks={xAxisTicks}
        stroke={colors[themeCss(theme)].chartGridColor}
        tick={{fontSize: isFullScreen ? 22 : 12, dy: 15 - 5}}
        tickLine={false}
        tickFormatter={(value) => {
          return moment(value).format(TIME_FORMAT);
        }}
      />
      <YAxis domain={[0, 100]} stroke={'none'} width={30} ticks={ticksForYAxis}/>
      <Tooltip filterNull={true} content={<CustomTooltip />} isAnimationActive={false}/>
      {lines.map((line) => {
        return <Line
          type="monotone"
          dataKey={line.dataKey}
          stroke={line.color}
          key={line.dataKey}
          strokeWidth={isFullScreen ? 3 : 2}
          activeDot={{ r: isFullScreen ? 4.5 : 3.5, fill: line.color, stroke: 'none' }}
          dot={{ r: isFullScreen ? 3 : 2, fill: line.color, stroke: 'none'}}
          isAnimationActive={false}
        />
      })}
    </LineChart>
  </ResponsiveContainer>;
}

export default Chart;
