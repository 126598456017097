import { MessageVariant } from './../../lib/constants/message-variant.enum';
import { Subject } from 'rxjs';

const MessageService = () => {
  const state$ = new Subject();

  const addMessage = (message: string, type?: MessageVariant): void => {
    state$.next({message, type});
  }

  return {
    state: state$.asObservable(),
    addMessage
  }
};

const singleton = MessageService();
export default Object.freeze(singleton);



