import React, {memo, FunctionComponent} from 'react';
import { CompanyLogo, FormContainer } from '../../../lib/components';
import SignInConfirmForm from './components/SignInSubmitForm';
import { SignInConfirmFormValues } from './interfaces/form-values';
import { useHistory } from 'react-router-dom';
import { authService } from '../../../services';
import { take } from 'rxjs/operators';
import { NavRoute } from '../../../lib/constants/nav-route.constant';

const SignInSubmit: FunctionComponent = memo(() => {
  const history = useHistory();

  const onSubmit = (values: SignInConfirmFormValues, onComplete: () => void) => {
    const password = values.pass;
    authService.signInConfirm(password, {}).pipe(
      take(1)
    ).subscribe({
      next: () => {
        history.push(NavRoute.MAIN.INIT);
        onComplete();
      },
      error: (e) => {
        console.error(e)
        onComplete()
      }
    });
  }

  return (
    <FormContainer>
      <CompanyLogo />
      <SignInConfirmForm submitForm={onSubmit} />
    </FormContainer>
  );
});

export default SignInSubmit;
