import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { ButtonBase, Typography } from '../../../../lib/components';
import { useHistory } from 'react-router-dom';
import { NavRoute } from '../../../../lib/constants/nav-route.constant';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center'
    },
    backButton: {
      borderRadius: 16
    },
    icon: {
      width: 52,
      fontSize: 24
    },
    title: {
      fontSize: 24,
      fontWeight: 600
    }
  })
);

const CreatePatientHeader: FunctionComponent = memo(() => {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const goBack = history.length > 2 ? history.goBack : () => history.replace(NavRoute.MAIN.INIT); 

  return (
    <div className={styles.container}>
      <ButtonBase className={styles.backButton} onClick={goBack}>
        <KeyboardBackspaceIcon className={styles.icon} />
      </ButtonBase>
      <Typography className={styles.title}>{t('header.addNewPatient')}</Typography>
    </div>
  );
});

export default CreatePatientHeader;
