import moment from 'moment';

export function stableSort(array: any[], comparator: (a: any, b: any) => number) {
  if (!Array.isArray(array)) {
    console.error('Invalid input to stableSort function. Expected an array, got:', array);
    return [];
  }
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

export function sortByDate(fieldName?: any, asc?: boolean, dateFormat?: string) {
  return function(array: any[]) {
    return (array && array.length) ? 
      array.sort((a: any, b: any) => {
        const aDate = moment(fieldName ? a[fieldName] : a, dateFormat);
        const bDate = moment(fieldName ? b[fieldName] : b, dateFormat);
        return asc ? aDate.diff(bDate) : bDate.diff(aDate);
      }) 
    :
      [];
  }
}
