import { TableData } from './../interfaces/table-data.interface';

export function descendingComparator<T extends TableData>(a: T, b: T) {
  const aVal = Number(a.value) || a.value;
  const bVal = Number(b.value) || b.value;
  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }
  return 0;
}

export function normalDescendingComparator<T>(a: T, b: T) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

export const descendingValueComparator = <T>(key: keyof T) => (a: T, b: T) => {
  return Number(a[key]) - Number(b[key])
}
