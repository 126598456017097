import React from "react";

function NoTableDataIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="101" viewBox="0 0 100 101" fill="none">
      <path
        d="M85.6397 71.4619C85.6397 80.12 78.6209 87.1387 69.9629 87.1387C61.3049 87.1387 54.2861 80.12 54.2861 71.4619C54.2861 62.8039 61.3049 55.7852 69.9629 55.7852C78.6209 55.7852 85.6397 62.8039 85.6397 71.4619Z"
        stroke="#9C9A9A" strokeWidth="4"/>
      <path d="M81.0093 83.6035L94.7104 97.3046" stroke="#9C9A9A" strokeWidth="4" strokeLinecap="round"/>
      <path
        d="M10 71.2861V19.8472C10 18.0587 10.6432 16.528 11.9297 15.2551C13.2134 13.9796 14.7572 13.3418 16.5609 13.3418H68.4391C70.2428 13.3418 71.7866 13.9796 73.0703 15.2551C74.3568 16.528 75 18.0587 75 19.8472V57.4123L54.6956 77.7915H16.5609C14.7572 77.7915 13.2134 77.1537 11.9297 75.8781C10.6432 74.6052 10 73.0745 10 71.2861ZM13.762 34.1026H70.9375V19.414C70.9375 18.6916 70.7032 18.0982 70.2347 17.6336C69.7661 17.169 69.1676 16.9367 68.4391 16.9367H16.2605C15.5319 16.9367 14.9334 17.169 14.4648 17.6336C13.9963 18.0982 13.762 18.6916 13.762 19.414V34.1026ZM33.9121 53.933H50.9878V37.3865H33.9121V53.933ZM33.9121 74.1963H50.9878V57.4123H33.9121V74.1963ZM13.762 53.933H30.3044V37.3865H13.762V53.933ZM54.6956 53.933H70.9375V37.3865H54.6956V53.933ZM16.2605 74.1963H30.3044V57.4123H13.762V71.719C13.762 72.4413 13.9963 73.0348 14.4648 73.4994C14.9334 73.964 15.5319 74.1963 16.2605 74.1963ZM54.6956 73.7633V77.7915L75 57.4123H54.6956V73.7633Z"
        fill="#9C9A9A"/>
      <path
        d="M74.8244 76.0084L70.6173 71.9398M66.4102 67.8711L70.6173 71.9398M66.4102 76.0084L70.6173 71.9398M74.8244 67.8711L70.6173 71.9398"
        stroke="#9C9A9A" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default NoTableDataIcon;
