export enum AggregateBy {
  DAYS = 'DAYS',
  HOURS = 'HOURS',
  MINUTES = 'MINUTES' 
}

export enum AggregateOptions {
  none = 'By minutes',
  hour = 'Hourly',
  day = 'Daily'
}
