import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Case } from '../../interfaces/case.interface';
import {object as yupObject, ref, string as yupString} from 'yup';
import { TFunction } from 'i18next';
import { Formik, FormikProps } from 'formik';
import { formSubmission } from '../../utils/form-submission';
import { EditPatientFormValues } from '../../interfaces/patient-form.interface';
import FormInput from "../../../pages/PatientMetrics/components/EditPatientButton/EditPatientDialog/FormInput";
import ThresholdTextField from "../../../pages/CreatePatientCase/components/CreatePatientForm/ThresholdTextField";
import {MeasurementFormat} from "../../interfaces/user-settings.interface";
import {ImperialExtension, MetricExtension, UnitsExtension} from "../../constants/value-extension.constant";
import useRequestUserSettings from "../../../hooks/useRequestUserSettings";
import useUserSettings from "../../../hooks/useUserSettings";
import Button from "../../../pages/redesign/common/Button";

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'stretch'
    },
    withGap: {
      display: 'flex',
      flexFlow: 'column',
      gap: 5,
    },
    fillInPatientAlertThresholds: {
      fontSize: 13,
    },
    thresholds: {
      fontSize: 14,
    },
    bold: {
      fontWeight: 600
    },
    fixedWidth: {
      width: 50
    },
    center: {
      textAlign: 'center'
    },
    skipThresholds: {
      fontSize: 13,
      fontWeight: 600,
      marginTop: 18
    },
    buttonsWrapper: {
      display: 'flex',
      marginTop: 20,
      gap: 11
    },
  })
);

interface Props {
  patientCase: Case;
  submitForm: (values: EditPatientFormValues, event: () => void) => void;
  onCancel: () => void;
}

function createMinimumField(regexp: RegExp) {
  return yupString()
    .matches(regexp, "Wrong format")
    .when('max', {
      is: max => max !== undefined && max !== '' && max !== null,
      then: yupString().required().test('is-less', 'Must be less than max', function (value) {
        return Number(value) < Number(this.resolve(ref('max')));
      }),
      otherwise: yupString()
    })
    .min(0)
}

function createMaximumField(regexp: RegExp) {
  return yupString()
    .matches(regexp, "Wrong format")
    .when('min', {
      is: min => min !== undefined && min !== '' && min !== null,
      then: yupString().required().test('is-greater', 'Must be greater than min', function (value) {
        return Number(value) > Number(this.resolve(ref('min')));
      }),
      otherwise: yupString()
    })
    .min(0)
}

const validationSchema = (t: TFunction) => yupObject().shape({
  firstName: yupString()
    .min(2, t('validation.minLength', {num: 2}))
    .required(t('validation.firstNameRequired')),
  lastName: yupString()
    .min(2, t('validation.minLength', {num: 2}))
    .required(t('validation.lastNameRequired')),
  pulseRate: yupObject().shape({
    min: createMinimumField(/^\d+$/),
    max: createMaximumField(/^\d+$/)
  }, [['min', 'max']]),
  bodyTemperature: yupObject().shape({
    min: createMinimumField(/^\d+(\.\d{1,2})?$/),
    max: createMaximumField(/^\d+(\.\d{1,2})?$/)
  }, [['min', 'max']]),
  respiratoryRate: yupObject().shape({
    min: createMinimumField(/^\d+$/),
    max: createMaximumField(/^\d+$/)
  }, [['min', 'max']]),
  oxygenSaturationLevel: yupObject().shape({
    min: createMinimumField(/^\d+$/),
    max: createMaximumField(/^\d+$/)
  }, [['min', 'max']]),
});

const EditPatientForm: FunctionComponent<Props> = memo(({ 
  patientCase, submitForm, onCancel
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  useRequestUserSettings();
  const userSettings = useUserSettings();
  const { patientFirstName, patientLastName, thresholds } = patientCase;
  const extensions: Record<MeasurementFormat, UnitsExtension> = {
    metric: MetricExtension,
    imperial: ImperialExtension
  }

  let extension = userSettings ? extensions[userSettings.measurementFormat] : MetricExtension;

  const renderForm = ({
    values,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
    isSubmitting,
    isValid,
    setSubmitting
  }: FormikProps<EditPatientFormValues>) => {
    const onBlur = (name: string) => () => setFieldTouched(name);
    const onChangeText = (name: string) => (value: string) => setFieldValue(name, value);
    const keyboardSubmit = () => {
      if (isValid) {
        setSubmitting(true);
        submitForm(values, () => setSubmitting(false));
      }
    };
    const isSubmittingDisabled = isSubmitting || !isValid;
    return (
      <>
        <form className={styles.form} onSubmit={keyboardSubmit}>
          <div className={styles.withGap}>
            <FormInput
              labelColor={'#9C9A9A'}
              label={t('placeholder.firstName')}
              borderColor={'#E4E4E4'}
              backgroundColor={'transparent'}
              textColor={'#404040'}
              onChange={value => onChangeText('firstName')(value).then(() => onBlur('firstName')())}
              value={values.firstName}
              isValid={!Boolean(errors.firstName)}
              invalidColor={'#EC523C'}
              disabled={isSubmitting}
              errorMessage={(touched.firstName && errors.firstName) ? errors.firstName : undefined}
            />
            <FormInput
              labelColor={'#9C9A9A'}
              label={t('placeholder.lastName')}
              borderColor={'#E4E4E4'}
              backgroundColor={'transparent'}
              textColor={'#404040'}
              onChange={value => onChangeText('lastName')(value).then(() => onBlur('lastName')())}
              value={values.lastName}
              isValid={!Boolean(errors.lastName)}
              invalidColor={'#EC523C'}
              disabled={isSubmitting}
              errorMessage={(touched.lastName && errors.lastName) ? errors.lastName : undefined}
            />
            <div className={styles.fillInPatientAlertThresholds}>
              {t('text.fillInPatientAlertThresholds')}
            </div>
            <table className={styles.thresholds}>
              <tbody>
              <tr>
                <td></td>
                <td className={`${styles.bold} ${styles.fixedWidth}`}>Min</td>
                <td></td>
                <td className={`${styles.bold} ${styles.fixedWidth}`}>Max</td>
                <td></td>
              </tr>
              {/*Pulse Rate*/}
              <tr>
                <td className={styles.bold}>Pulse Rate:</td>
                <td>
                  <ThresholdTextField
                    onChange={(value) => {
                      return onChangeText('pulseRate.min')(value).then(() => onBlur('pulseRate.min')());
                    }}
                    value={values.pulseRate.min}
                    error={Boolean((touched.pulseRate?.min || touched.pulseRate?.max) && errors.pulseRate?.min)}
                    disabled={isSubmitting}
                  />
                </td>
                <td className={styles.center}>-</td>
                <td>
                  <ThresholdTextField
                    onChange={(value) => {
                      return onChangeText('pulseRate.max')(value).then(() => onBlur('pulseRate.max')());
                    }}
                    onBlur={onBlur('pulseRate.max')}
                    value={values.pulseRate.max}
                    error={Boolean((touched.pulseRate?.max || touched.pulseRate?.min) && errors.pulseRate?.max)}
                    disabled={isSubmitting}
                  />
                </td>
                <td>{extension.hr}</td>
              </tr>
              {/*Body Temperature*/}
              <tr>
                <td className={styles.bold}>Skin Temperature:</td>
                <td>
                  <ThresholdTextField
                    onChange={(value) => {
                      return onChangeText('bodyTemperature.min')(value).then(() => onBlur('bodyTemperature.min')());
                    }}
                    onBlur={onBlur('bodyTemperature.min')}
                    value={values.bodyTemperature.min}
                    error={Boolean((touched.bodyTemperature?.min || touched.bodyTemperature?.max) && errors.bodyTemperature?.min)}
                    disabled={isSubmitting}
                  />
                </td>
                <td className={styles.center}>-</td>
                <td>
                  <ThresholdTextField
                    onChange={(value) => {
                      return onChangeText('bodyTemperature.max')(value).then(() => onBlur('bodyTemperature.max')());
                    }}
                    onBlur={onBlur('bodyTemperature.max')}
                    value={values.bodyTemperature.max}
                    error={Boolean((touched.bodyTemperature?.max || touched.bodyTemperature?.min) && errors.bodyTemperature?.max)}
                    disabled={isSubmitting}
                  />
                </td>
                <td>{extension.bt}</td>
              </tr>
              {/*Respiration Rate*/}
              <tr>
                <td className={styles.bold}>Respiration Rate:</td>
                <td>
                  <ThresholdTextField
                    onChange={(value) => {
                      return onChangeText('respiratoryRate.min')(value).then(() => onBlur('respiratoryRate.min')());
                    }}
                    onBlur={onBlur('respiratoryRate.min')}
                    value={values.respiratoryRate.min}
                    error={Boolean((touched.respiratoryRate?.min || touched.respiratoryRate?.max) && errors.respiratoryRate?.min)}
                    disabled={isSubmitting}
                  />
                </td>
                <td className={styles.center}>-</td>
                <td>
                  <ThresholdTextField
                    onChange={(value) => {
                      return onChangeText('respiratoryRate.max')(value).then(() => onBlur('respiratoryRate.max')());
                    }}
                    onBlur={onBlur('respiratoryRate.max')}
                    value={values.respiratoryRate.max}
                    error={Boolean((touched.respiratoryRate?.max || touched.respiratoryRate?.min) && errors.respiratoryRate?.max)}
                    disabled={isSubmitting}
                  />
                </td>
                <td>{extension.rr}</td>
              </tr>
              {/*Oxygen Saturation Level*/}
              {patientCase.deviceType === 'eBeat' && <tr>
                <td className={styles.bold}>Oxygen Saturation Level:</td>
                <td>
                  <ThresholdTextField
                    onChange={(value) => {
                      return onChangeText('oxygenSaturationLevel.min')(value).then(() => onBlur('oxygenSaturationLevel.min')());
                    }}
                    onBlur={onBlur('oxygenSaturationLevel.min')}
                    value={values.oxygenSaturationLevel.min}
                    error={Boolean((touched.oxygenSaturationLevel?.min || touched.oxygenSaturationLevel?.max) && errors.oxygenSaturationLevel?.min)}
                    disabled={isSubmitting}
                  />
                </td>
                <td className={styles.center}>-</td>
                <td>
                  <ThresholdTextField
                    onChange={(value) => {
                      return onChangeText('oxygenSaturationLevel.max')(value).then(() => onBlur('oxygenSaturationLevel.max')());
                    }}
                    onBlur={onBlur('oxygenSaturationLevel.max')}
                    value={values.oxygenSaturationLevel.max}
                    error={Boolean((touched.oxygenSaturationLevel?.max || touched.oxygenSaturationLevel?.min) && errors.oxygenSaturationLevel?.max)}
                    disabled={isSubmitting}
                  />
                </td>
                <td>{extension.spo2}</td>
              </tr>}
              </tbody>
            </table>
          </div>
          <div className={styles.skipThresholds}>{t('text.skipThresholds')}</div>
        </form>
        <div className={styles.buttonsWrapper}>
          <Button
            backgroundColor={'transparent'}
            borderColor={'#A0A0A0'}
            fontColor={'#A0A0A0'}
            title={t('button.cancel')}
            grow={true}
            onClick={onCancel}
          />
          {isSubmittingDisabled ? <Button
            backgroundColor={'#A0A0A0'}
            borderColor={'#A0A0A0'}
            fontColor={'#FFF'}
            title={t('button.submit')}
            grow={true}
            onClick={handleSubmit}
            disabled={isSubmittingDisabled}
          /> : <Button
            backgroundColor={'#00A1E6'}
            borderColor={'#00A1E6'}
            fontColor={'#FFF'}
            title={t('button.submit')}
            grow={true}
            onClick={handleSubmit}
            disabled={isSubmittingDisabled}
          />}
        </div>
      </>
    )
  };

  return (
    <Formik
      initialValues={{
        firstName: patientFirstName,
        lastName: patientLastName,
        pulseRate: {
          min: thresholds.find(threshold => threshold.metric === 'HeartRate')?.lowRange.toString() || '',
          max: thresholds.find(threshold => threshold.metric === 'HeartRate')?.highRange.toString() || '',
        },
        bodyTemperature: {
          min: thresholds.find(threshold => threshold.metric === 'BodyTemperature')?.lowRange.toFixed(2).toString() || '',
          max: thresholds.find(threshold => threshold.metric === 'BodyTemperature')?.highRange.toFixed(2).toString() || '',
        },
        respiratoryRate: {
          min: thresholds.find(threshold => threshold.metric === 'RespirationRate')?.lowRange.toString() || '',
          max: thresholds.find(threshold => threshold.metric === 'RespirationRate')?.highRange.toString() || '',
        },
        oxygenSaturationLevel: {
          min: thresholds.find(threshold => threshold.metric === 'OxygenSaturationLevel')?.lowRange.toString() || '',
          max: thresholds.find(threshold => threshold.metric === 'OxygenSaturationLevel')?.highRange.toString() || '',
        }
    }}
      onSubmit={formSubmission<EditPatientFormValues>(submitForm)}
      validationSchema={validationSchema(t)}
    >
      {renderForm}
    </Formik>
  );
});

export default EditPatientForm;
