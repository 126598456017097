/**
 * Returns an array of timestamps for the x-axis ticks
 * @param from milliseconds of the start of the hour
 * @param to milliseconds of the end of the hour
 */
export function getMillisecondsForXAxisTicks(from: number, to: number) {
  const timestamps = [];
  let current = Math.ceil(from / 300000) * 300000; // Round up to the nearest (after current timestamp) 5 minutes
  while (current <= to) {
    timestamps.push(current);
    current += 300000; // Add 5 minutes
  }
  return timestamps;
}
