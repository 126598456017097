import React from "react";

function NoChartDataIcon() {
  return (
    <svg
      width="101"
      height="100"
      viewBox="0 0 101 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon_devices">
        <path
          id="Ellipse 46"
          d="M86.1395 71.2122C86.1395 79.8702 79.1208 86.8889 70.4627 86.8889C61.8047 86.8889 54.786 79.8702 54.786 71.2122C54.786 62.5541 61.8047 55.5354 70.4627 55.5354C79.1208 55.5354 86.1395 62.5541 86.1395 71.2122Z"
          stroke="#9C9A9A"
          strokeWidth="4"
        />
        <path
          id="Line 80"
          d="M81.5095 83.353L95.2105 97.0541"
          stroke="#9C9A9A"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          id="Vector"
          d="M10.5 71.0356V19.5967C10.5 17.8082 11.1432 16.2775 12.4297 15.0047C13.7134 13.7291 15.2572 13.0913 17.0609 13.0913H68.9391C70.7428 13.0913 72.2866 13.7291 73.5703 15.0047C74.8568 16.2775 75.5 17.8082 75.5 19.5967V57.1618L55.1956 77.541H17.0609C15.2572 77.541 13.7134 76.9032 12.4297 75.6276C11.1432 74.3547 10.5 72.8241 10.5 71.0356ZM14.262 33.8522L42.8498 26.5078L71.4375 19.1635C71.4375 18.4411 71.2032 17.8477 70.7347 17.3831C70.2661 16.9185 69.6676 16.6862 68.9391 16.6862H16.7605C16.0319 16.6862 15.4334 16.9185 14.9648 17.3831C14.4963 17.8477 14.262 18.4411 14.262 19.1635V33.8522ZM14.262 53.6825L60 58L71.4375 53.6825V19.1635L42.8498 26.5078L14.262 33.8522V53.6825ZM34.4121 73.9458H51.4878H55.1956L60 58L14.262 53.6825L14.9648 73.2489L34.4121 73.9458ZM16.7605 73.9458H34.4121L14.9648 73.2489L14.262 53.6825V71.4685C14.262 72.1909 14.4963 72.7843 14.9648 73.2489C15.4334 73.7135 16.0319 73.9458 16.7605 73.9458ZM55.1956 73.5129V77.541L75.5 57.1618L60 58L55.1956 73.5129Z"
          fill="#9C9A9A"
        />
        <path
          id="Vector_2"
          d="M62.0625 32C60.1636 32 58.625 33.6171 58.625 35.6111C58.625 36.0206 58.7041 36.407 58.823 36.7739L50.4437 44.3536C49.8559 43.8618 49.1224 43.5556 48.3125 43.5556C47.3782 43.5556 46.5333 43.9499 45.9145 44.5854L39.3509 41.1332C39.2231 39.2605 37.7525 37.7778 35.9375 37.7778C34.0394 37.7778 32.5 39.3948 32.5 41.3889C32.5 41.9349 32.6237 42.4469 32.83 42.9106L26.1379 51.0168C25.7625 50.8695 25.3617 50.7778 24.9375 50.7778C23.0393 50.7778 21.5 52.3948 21.5 54.3889C21.5 56.3829 23.0393 58 24.9375 58C26.8357 58 28.375 56.3829 28.375 54.3889C28.375 53.9086 28.2815 53.4515 28.1193 53.0326L34.92 44.8209C35.2438 44.9263 35.5807 45 35.9375 45C36.9378 45 37.8301 44.5436 38.4585 43.8264L44.8784 47.2078C44.9011 49.1824 46.4273 50.7778 48.3125 50.7778C50.2114 50.7778 51.75 49.1607 51.75 47.1667C51.75 47.1024 51.7356 47.0424 51.7321 46.9789L60.6737 38.9095C61.0993 39.1074 61.5668 39.2222 62.0625 39.2222C63.9614 39.2222 65.5 37.6059 65.5 35.6111C65.5 33.6171 63.9614 32 62.0625 32Z"
          fill="#9C9A9A"
        />
        <path
          id="Vector_3"
          d="M75.3246 75.7577L71.1175 71.689M66.9104 67.6204L71.1175 71.689M66.9104 75.7577L71.1175 71.689M75.3246 67.6204L71.1175 71.689"
          stroke="#9C9A9A"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default NoChartDataIcon;
