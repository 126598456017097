import React, {memo, FunctionComponent, useState, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase, CircularProgress, Dialog } from '../../../../lib/components';
import { caseService } from '../../../../services';
import {switchMap} from 'rxjs/operators';
import { ReactComponent as DeleteIcon } from '../../../../theme/icons/delete.svg';
import {AppContext} from "../../../../state/AppContext";

interface Props {
  patientId: string;
}

const DeletePatientButton: FunctionComponent<Props> = memo(({ patientId }) => {
  const { setCases } = useContext(AppContext);
  const { t } = useTranslation();
  const toggleDialog = () => setOpen(!open);
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const submit = () => {
    toggleDialog();
    setLoading(true);
    caseService.resolveCase(patientId).pipe(
      switchMap(
        () => caseService.getCasesList())
    ).subscribe(
      (cases) => {
        setCases(cases)
        setLoading(false)
      },
      () => setLoading(false),
    )
  }

  return (
    <>
      {
        isLoading ? 
          <CircularProgress size={16} />
        :
          <ButtonBase onClick={toggleDialog}>
            <DeleteIcon />
          </ButtonBase>
      }
      
      <Dialog
        open={open}
        setOpen={setOpen}
        title={t('header.resolveCase')}
        contentText={t('text.resolveCase')}
        submit={submit}
        onCancel={toggleDialog}
      />
    </>
  );
});

export default DeletePatientButton;
