import React, { FunctionComponent } from 'react';

interface Props {
  width: number;
  height: number;
  color: string;
}

export const ActivityLowIcon: FunctionComponent<Props> = ({width, height, color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 30" fill="none">
      <path
        d="M18.7136 19.3392L19.9529 22.0042C19.9529 22.0042 25.5241 23.1872 25.5241 26.0009C25.5241 28 23.0454 28 23.0454 28H16.4443L13.1943 26.1945"
        stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M11.2864 19.3392L10.0485 22.0042C10.0485 22.0042 4.47583 23.1872 4.47583 26.0009C4.47583 28 6.9545 28 6.9545 28H9.9445L13.1945 26.1945L17.1667 23.6667"
        stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M2 20.671C2 20.671 5.09544 20.0051 6.953 19.3392C8.80911 10.011 14.3789 10.6768 15 10.6768C15.6197 10.6768 21.1909 10.011 23.047 19.3392C24.9046 20.0036 28 20.671 28 20.671M15 7.77784C15.7662 7.77784 16.501 7.47348 17.0428 6.9317C17.5845 6.38993 17.8889 5.65513 17.8889 4.88895C17.8889 4.12277 17.5845 3.38797 17.0428 2.8462C16.501 2.30443 15.7662 2.00006 15 2.00006C14.2338 2.00006 13.499 2.30443 12.9572 2.8462C12.4155 3.38797 12.1111 4.12277 12.1111 4.88895C12.1111 5.65513 12.4155 6.38993 12.9572 6.9317C13.499 7.47348 14.2338 7.77784 15 7.77784Z"
        stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};


