import React, {memo, FunctionComponent, useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles, createStyles} from '@material-ui/core';
import { colors } from '../../../theme/colors';
import { ReactComponent as EditIcon } from '../common/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../common/delete-icon.svg';
import { ReactComponent as NoDevicesIcon } from './no-devices-icon.svg';
import { ReactComponent as AlertIcon } from '../common/alert-icon.svg';
import { ReactComponent as Spinner } from '../common/spinner.svg';
import TableCellWithTooltip from "../common/TableCellWithTooltip";
import Button from "../common/Button";
import FormInput from "../common/FormInput";
import {DeviceDto, DeviceV2} from "../../../lib/interfaces/device.interface";
import {deviceService} from "../../../services";
import {catchError, switchMap, tap} from "rxjs/operators";
import CenteredSnackbar from "../common/CenteredStackbar";
import {of, throwError} from "rxjs";
import Layout from "../common/Layout";

const useStyles = makeStyles(() =>
  createStyles({
    contentWrapper: {
      backgroundColor: colors.blackTheme.contentWrapperBackground,
      marginLeft: 40,
      marginTop: 35,
      marginRight: 48,
      marginBottom: 40,
      paddingTop: 24,
      paddingBottom: 24,
      paddingLeft: 50,
      paddingRight: 50,
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
    },
    actionsList: {
      display: 'flex',
      marginBottom: 40,
      justifyContent: 'flex-end',
    },
    table: {
      fontSize: 16,
      textAlign: 'left',
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
      borderRadius: 5,
      border: `1px solid ${colors.blackTheme.tableBorder}`,
      '& th': {
        fontWeight: 'bold',
        height: 63,
        backgroundColor: colors.blackTheme.tableHeaderBackground,
        '&:first-child': {
          paddingLeft: 36,
        }
      },
      '& td': {
        fontWeight: 600,
        borderTop: `1px solid ${colors.blackTheme.tableBorder}`,
        height: 63,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 0,
        paddingRight: 36,
        '&:first-child': {
          paddingLeft: 36,
        },
        '&:last-child': {
          whiteSpace: 'normal',
          overflow: 'visible',
          textOverflow: 'clip',
          maxWidth: 'none',
          paddingRight: 23,
        }
      },
    },
    rowActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 15,
      '& svg': {
        cursor: 'pointer',
      }
    },
    noDevicesContainer: {
      fontSize: 16,
      textAlign: 'center',
      display: 'flex',
      width: 240,
      flexDirection: 'column',
      flexGrow: 2,
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    noDevicesIcon: {
      marginBottom: 13,
      width: 100,
      height: 100,
    },
    noDevicesTitle: {
      fontWeight: 'bold',
      fontSize: 18,
      marginBottom: 20,
    },
    editPopup: {
      backgroundColor: colors.blackTheme.popupBackground,
      borderRadius: 10,
      width: 380,
      padding: 24,
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      justifyContent: 'center',
    },
    editPopupWrapper: {
      position: 'relative',
    },
    popupTitle: {
      color: colors.blackTheme.popupTextActiveColor,
      fontWeight: 600,
      fontFamily: 'Raleway, sans-serif',
      fontSize: 24,
      textAlign: 'center',
    },
    popupActions: {
      display: 'flex',
      gap: 12,
    },
    inputs: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    snackbar: {
      '& .MuiAlert-root': {
        fontFamily: 'Raleway, sans-serif',
        borderRadius: 8
      },
    },
    errorPopup: {
      backgroundColor: colors.blackTheme.popupBackground,
      borderRadius: 10,
      width: 375,
      padding: 24,
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      justifyContent: 'center',
      alignItems: 'center',
    },
    errorPopupText: {
      fontFamily: 'Raleway, sans-serif',
      color: colors.blackTheme.popupTextActiveColor,
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 500,
    },
    link: {
      fontWeight: 'bold',
      color: colors.blackTheme.linkColor,
      textDecoration: 'none',
    },
    spinner: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 20,
    },
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    },
    spinnerIcon: {
      width: 45,
      height: 45,
      animation: '$spin 2s linear infinite',
    },
    spinnerText: {
      fontFamily: 'Raleway, sans-serif',
      fontSize: 18,
      fontWeight: 500,
      color: colors.blackTheme.popupTextActiveColor,
    },
    spinnerForeground: {
      top: 0,
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: colors.blackTheme.popupBackground,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  })
);

export type ValidationRule = {
  isValid: (value: string) => boolean;
  errorMessage: string;
}

type FormFieldState = {
  id: string,
  label: string,
  isValid?: boolean,
  value: string,
  validationRules?: ValidationRule[],
  disabled: boolean,
  customErrorMessage?: string,
}

type FormState = {
  isValid?: boolean;
  fields : FormFieldState[];
}

const ManageDevices: FunctionComponent = memo(() => {
  const styles = useStyles();
  const [devices, setDevices] = useState<DeviceV2[] | null>(null);
  const [formState, setFormState] = useState<FormState|null>(null);
  const [updatingDevice, setUpdatingDevice] = useState<DeviceDto|null>(null);
  const [isSnackbarOpened, setIsSnackbarOpened] = React.useState(false);
  const [updatedDeviceName, setUpdatedDeviceName] = React.useState('');
  const [isErrorPopupOpened, setIsErrorPopupOpened] = React.useState(false);
  const [isDeviceSaving, setIsDeviceSaving] = React.useState(false);
  const [isDevicesFetching, setIsDevicesFetching] = React.useState(false);

  const isEmptyDeviceListFetched = devices != null && devices.length === 0; // empty device list was fetched
  const isGlobalSpinnerShown = isDevicesFetching;

  //todo: MD-649 use separate component
  const LoadingDataSpinner: FunctionComponent = () => {
    return <div className={styles.spinner}>
      <div className={styles.spinnerIcon}>
        <Spinner/>
      </div>
      <div className={styles.spinnerText}>
        Loading data...
      </div>
    </div>
  }

  useEffect(() => {
    setIsDevicesFetching(true);
    const sub = deviceService.getAllDevicesV2()
      .subscribe({
        next: (devices) => {
          setDevices(devices);
          setIsDevicesFetching(false);
        }, error: () => {
          setIsErrorPopupOpened(true);
        }
      })
    return () => sub.unsubscribe();
  }, []);

  // click on edit icon in table row
  const handleEditClick = (deviceEui: string) => {
    deviceService.getDeviceById(deviceEui).subscribe((device) => {
      setUpdatingDevice(device);
      let initialFormState: FormState = {
        fields: [
          {
            id: 'name',
            label: 'Device Name',
            value: device.name,
            validationRules: [
              {
                isValid: (value: string) => value.trim().length !== 0,
                errorMessage: 'Device Name is required',
              },
              {
                isValid: (value: string) => value.trim().length >= 3,
                errorMessage: 'Device Name must contain 3 characters minimum',
              },
              {
                isValid: (value: string) => value.trim().length <= 255,
                errorMessage: 'Device Name must contain 255 characters maximum',
              },
              {
                // eslint-disable-next-line no-useless-escape
                isValid: (value: string) => /^[a-zA-Z0-9!#$%&'()*+,\-./:;<=>?@\[\]^_`{|} ~]+$/.test(value.trim()),
                errorMessage: 'Device Name can only contain Latin letters, digits, and special characters like !@#?]',
              }
            ],
            disabled: false,
          },
          {
            id: 'id',
            label: 'Device EUI',
            value: device.id,
            disabled: true,
          },
          {
            id: 'deviceType',
            label: 'Device Type',
            value: device.deviceModel.name,
            disabled: true,
          },
        ].map((field: FormFieldState) => {
          return {
            ...field,
            isValid: field.validationRules ? field.validationRules.every(rule => rule.isValid(field.value)) : true,
          }
        })
      };
      initialFormState.isValid = initialFormState.fields.every(f => f.isValid);
      setFormState(initialFormState);
    }, () => {
      setIsErrorPopupOpened(true);
    });
  };
  const handlePopupClose = () => {
    setFormState(null);
  };
  // click on save button in edit popup
  const handleSave = () => {
    setIsDeviceSaving(true);
    if (formState && updatingDevice) {
      const newName = formState.fields.find(f => f.id === 'name')?.value.trim() || '';
      deviceService.updateDevice(
        {
          ...updatingDevice,
          name: newName
        }
      ).pipe(
        catchError(error => {
          if (error.status === 400) {
            setIsDeviceSaving(false);
            const newFields:FormFieldState[] = formState.fields.map(f => {
              if (f.id === 'name') {
                return {
                  ...f,
                  isValid: false,
                  customErrorMessage: error.message,
                };
              }
              return f;
            });
            setFormState({
              ...formState,
              fields: newFields,
              isValid: newFields.every(f => {
                return f.isValid;
              })
            });

            return deviceService.getAllDevicesV2().pipe(
              tap((devices) => {
                setDevices(devices);
              }),
              switchMap(() => of())
            )
          } else {
            return throwError(error)
          }
        }),
        switchMap(() => deviceService.getAllDevicesV2()),
      ).subscribe((deices) => {
        setDevices(deices);
        setUpdatedDeviceName(newName);
        setIsSnackbarOpened(true);
        setIsDeviceSaving(false);
        handlePopupClose();
      }, () => {
        setIsErrorPopupOpened(true);
        setIsDeviceSaving(false);
        handlePopupClose();
      })
    }
  };

  return (
    <Layout title={'Devices'}>
      <>
        <div className={styles.contentWrapper} style={{
          height: isEmptyDeviceListFetched || isGlobalSpinnerShown ? '100%' : 'auto',
          marginBottom: isEmptyDeviceListFetched || isGlobalSpinnerShown ? 162 : 40,
        }}>
          {
            isGlobalSpinnerShown ? (
              // spinner
              <div className={styles.noDevicesContainer}>
                <LoadingDataSpinner/>
              </div>
            ) : (
              devices ?
                <>
                  <div className={styles.actionsList}>
                    <Button title={'Add Device'} height={45} bold/>
                  </div>
                  {!isEmptyDeviceListFetched ?
                    // table with devices
                    <table className={styles.table}>
                      <thead>
                      <tr>
                        <th style={{width: '33%'}}>Device Name</th>
                        <th style={{width: '33%'}}>Device EUI</th>
                        <th style={{width: '26%'}}>Device Type</th>
                        <th style={{width: '8%'}}></th>
                      </tr>
                      </thead>
                      <tbody>
                      {devices.map((device) => (
                        <tr key={device.id}>
                          <TableCellWithTooltip content={device.name}/>
                          <TableCellWithTooltip content={device.id}/>
                          <TableCellWithTooltip content={device.type}/>
                          <td>
                            <div className={styles.rowActions}>
                              <EditIcon onClick={() => handleEditClick(device.id)}/>
                              <DeleteIcon onClick={() => {
                              }}/>
                            </div>
                          </td>
                        </tr>
                      ))}
                      </tbody>
                    </table> :
                    <div className={styles.noDevicesContainer}>
                      <div className={styles.noDevicesIcon}><NoDevicesIcon/></div>
                      <div className={styles.noDevicesTitle}>No devices</div>
                      <div>Add your first device, and it will show up here</div>
                    </div>}
                </>
                : null
            )
          }
          {formState != null && <Dialog
            disableBackdropClick
            open={true}
            BackdropProps={{style: {backgroundColor: 'rgba(0, 0, 0, 0.5)'}}}
            classes={{paper: styles.editPopup}}
          >
            <div className={styles.editPopupWrapper}>
              <div className={styles.popupTitle}>Edit Device</div>
              <div className={styles.inputs}>
                {formState.fields.map((field) => (
                  <FormInput
                    key={field.id}
                    label={field.label}
                    value={field.value}
                    onChange={(newValue) => {
                      const newFields = formState.fields.map(f => {
                        return f.id === field.id ? {
                          ...f,
                          customErrorMessage: undefined,
                          isValid: f.validationRules ? f.validationRules.every(rule => rule.isValid(newValue)) : true,
                          value: newValue
                        } : f;
                      });
                      setFormState({
                        ...formState,
                        fields: newFields,
                        isValid: newFields.every(f => {
                          return f.isValid;
                        })
                      });
                    }}
                    disabled={field.disabled}
                    isValid={field.isValid}
                    errorMessage={field.customErrorMessage || (field.validationRules ? field.validationRules.find(rule => !rule.isValid(field.value))?.errorMessage : undefined)}
                  />
                ))}
              </div>
              <div className={styles.popupActions}>
                <Button onClick={() => handleSave()} title={'Save'} grow disabled={!formState.isValid}></Button>
                <Button onClick={handlePopupClose} title={'Cancel'} grow type={'secondary'}></Button>
              </div>
              {isDeviceSaving && <div className={styles.spinnerForeground}>
                <LoadingDataSpinner/>
              </div>}
            </div>
          </Dialog>
          }
        </div>
        <CenteredSnackbar updatedDeviceName={updatedDeviceName} open={isSnackbarOpened}
                          setOpen={setIsSnackbarOpened}/>
        {isErrorPopupOpened && <Dialog
          disableBackdropClick
          open={true}
          BackdropProps={{style: {backgroundColor: 'rgba(0, 0, 0, 0.5)'}}}
          classes={{paper: styles.errorPopup}}
        >
          <AlertIcon/>
          <div className={styles.errorPopupText}>Something went wrong. Please try again or contact <a
            className={styles.link} target='_blank' rel='noopener noreferrer'
            href='https://support.tektelic.com/portal/en/signin'>TEKTELIC Support</a></div>
          <Button onClick={() => setIsErrorPopupOpened(false)} title={'Close'}/>
        </Dialog>
        }
      </>
    </Layout>
  );
});

export default ManageDevices;
