import moment from "moment";

export function generateTimestampsForHours(startPeriod: string, endPeriod: string) {
    const start = moment(startPeriod).local();
    const end = moment(endPeriod).local();
    const timestamps = [];
    for (let i = start; i.isSameOrBefore(end); i.add(1, 'hour')) {
      timestamps.push(i.valueOf());
    }
    return timestamps;
}

export function generateTimestampsForDays(startPeriod: string, endPeriod: string) {
  const start = moment(startPeriod).local();
  const end = moment(endPeriod).local();
  const timestamps = [];
  for (let i = start; i.isSameOrBefore(end); i.add(1, 'day')) {
    timestamps.push(i.valueOf());
  }
  return timestamps;
}
  
 export function convertToMillis(timeString: string) {
    return moment(timeString).local().valueOf();
  }