import React, {memo, FunctionComponent} from 'react';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { colors } from '../../../theme/colors';

interface Props {
  value: boolean;
}

const PasswordValidationIcon: FunctionComponent<Props> = memo(({ value }) => {
  return value ?
    <CheckCircleIcon style={{
      fontSize: 12,
      paddingRight: 3,
      color: colors.green,
      opacity: 0.9
    }} />
      :
    <RadioButtonUncheckedIcon style={{
      fontSize: 12,
      paddingRight: 3,
      color: colors.lightGray,
      opacity: 0.5
    }} />
});

export default PasswordValidationIcon;
