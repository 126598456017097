import { BehaviorSubject } from 'rxjs';

const loaderService = () => {
  const state$ = new BehaviorSubject<{id: string|null}|null>(null);

  const enable = (id?: string) => {
    state$.next({id: id || null});
  }

  const disable = () => {
    state$.next(null);
  }

  return {
    state: state$.asObservable(),
    enable,
    disable
  }
};

const singleton = loaderService();
export default Object.freeze(singleton);
