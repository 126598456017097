import React, {memo, FunctionComponent} from 'react';
import { Icon as MUIIcon, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: 5
    },
  })
);

interface Props {
  color?: 'inherit'|'default'|'disabled'|'primary'|'secondary'|'action'|'error'|undefined; 
  fontSize?: 'inherit'|'default'|'large'|'small'|undefined;
  className?: any;
  classes?: any;
}

const Icon: FunctionComponent<Props> = memo(({ children, color, fontSize, className, classes }) => {
  const styles = useStyles();

  return (
    <MUIIcon
      color={color}
      fontSize={fontSize}
      className={styles.root}
    >
      {children}
    </MUIIcon>
  );
});

export default Icon;
