import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import { colors } from "../../../../theme/colors";
import { ReactComponent as FullScreenIcon } from "../../../../theme/icons/full-screen-icon.svg";
import { ReactComponent as FullScreenIconBig } from "../../../../theme/icons/full-screen-icon-big.svg";
import { ReactComponent as FullScreenIconDark } from "../../../../theme/icons/full-screen-icon-dark.svg";
import { ReactComponent as FullScreenIconBigDark } from "../../../../theme/icons/full-screen-icon-big-dark.svg";
import { ReactComponent as TableIcon } from "../../../../theme/icons/table-icon.svg";
import { ReactComponent as TableIconFocused } from "../../../../theme/icons/table-icon-focused.svg";
import { ReactComponent as TableIconDark } from "../../../../theme/icons/table-icon-dark.svg";
import { ReactComponent as TableIconDarkFocused } from "../../../../theme/icons/table-icon-dark-focused.svg";
import { Case } from "../../../../lib/interfaces/case.interface";
import LastHourChart from "./LastHourChart";
import { AppContext } from "../../../../state/AppContext";
import { DarkThemeIcon } from "../../../../theme/icons/DarkThemeIcon";
import { themeCss } from "../../../../lib/utils/theme";
import { LightThemeIcon } from "../../../../theme/icons/LightThemeIcon";
import { UserSettings } from "../../../../lib/interfaces/user-settings.interface";
import HistoryChart from "./HistoryChart";
import {authService, errorService} from "../../../../services";
import {Theme} from "../../../../lib/interfaces/theme.type";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      marginTop: 28,
      marginRight: 25,
      marginBottom: 29,
      marginLeft: 27,
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 40,
      paddingRight: 40,
      flexGrow: 2,
      borderRadius: 8,
      boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.08)",
      gap: 31,
    },
    fullScreenContainer: {
      display: "flex",
      flexDirection: "column",
      paddingTop: 10,
      paddingLeft: 45,
      paddingRight: 45,
      flexGrow: 2,
      gap: 31,
    },
    chartMenu: {
      display: "flex",
    },
    startSection: {
      display: "flex",
    },
    endSection: {
      display: "flex",
      marginLeft: "auto",
    },
    toggle: {
      height: 40,
      display: "flex",
      fontSize: 16,
      fontWeight: 600,
      "& > :first-child": {
        borderRadius: "4px 0px 0px 4px",
      },
      "& > :last-child": {
        borderLeft: "none",
        borderRadius: "0px 4px 4px 0px",
      },
    },
    toggleButton: {
      minWidth: 92,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "toggleButton--light": {
      border: `1px solid ${colors.lightTheme.toggleBorder}`,
      color: colors.lightTheme.toggleText,
    },
    "toggleButton--dark": {
      border: `1px solid ${colors.blackTheme.toggleBorder}`,
      color: colors.blackTheme.toggleText,
    },
    toggleButtonActive: {
      color: colors.lightTheme.toggleTextActive,
      backgroundColor: colors.lightTheme.toggleBackgroundActive,
    },
    clickable: {
      cursor: "pointer",
    },
  })
);

interface Props {
  patientCase: Case;
  userSettings: UserSettings | null;
}

const NormalModeContainer: FunctionComponent = ({ children }) => {
  const { theme } = useContext(AppContext);
  const styles = useStyles();
  return (
    <div
      className={styles.container}
      style={{ backgroundColor: colors[themeCss(theme)].chartBackground }}
    >
      {children}
    </div>
  );
};

const FullScreenModeContainer: FunctionComponent = ({ children }) => {
  const { theme } = useContext(AppContext);
  const styles = useStyles();
  return (
    <div
      className={styles.fullScreenContainer}
      style={{ backgroundColor: colors[themeCss(theme)].chartBackground }}
    >
      {children}
    </div>
  );
};

const MetricsWrapper: FunctionComponent<Props> = ({
  patientCase,
  userSettings,
}) => {
  const { isFullScreen, setIsFullScreen, theme, setTheme } =
    useContext(AppContext);
  const [activeButton, setActiveButton] = useState<"LastHour"|"History">("LastHour");
  const [isTableOpened, setIsTableOpened] = useState<boolean>(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setIsFullScreen(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [setIsFullScreen]);

  const styles = useStyles();
  const Container = isFullScreen
    ? FullScreenModeContainer
    : NormalModeContainer;
  const iconSize = isFullScreen ? 54 : 32;
  const fullScreenIcons = {
    fullScreen: {
      light: <FullScreenIconBig />,
      dark: <FullScreenIconBigDark />,
    },
    normalScreen: {
      light: <FullScreenIcon />,
      dark: <FullScreenIconDark />,
    },
  };

  function updateTheme(theme: Theme) {
    userSettings && authService
      .updateUserSettings({
        ...userSettings,
        theme: theme,
      })
      .subscribe({
        next: () => {
          setTheme(theme);
        },
        error: (error) => {
          errorService.addError(error);
        },
      });
  }

  return (
    <Container>
      {/*todo: extract to MetricsWrapperMenu*/}
      <div className={styles.chartMenu}>
        <div className={styles.startSection}>
          {isFullScreen || (
            <div className={`${styles.toggle} ${styles.clickable}`}>
              <div
                className={`${styles.toggleButton} ${
                  styles[`toggleButton--${theme}`]
                } ${
                  activeButton === "LastHour" ? styles.toggleButtonActive : ""
                }`}
                onClick={() => setActiveButton("LastHour")}
              >
                Last hour
              </div>
              <div
                className={`${styles.toggleButton} ${
                  styles[`toggleButton--${theme}`]
                } ${
                  activeButton === "History" ? styles.toggleButtonActive : ""
                }`}
                onClick={() => setActiveButton("History")}
              >
                History
              </div>
            </div>
          )}
        </div>
        <div
          className={`${styles.endSection}`}
          style={{ gap: isFullScreen ? 20 : 10 }}
        >
          {theme === "light" ? (
            <span
              className={styles.clickable}
              onClick={() => updateTheme("dark")}
            >
              <DarkThemeIcon width={iconSize} height={iconSize} />
            </span>
          ) : (
            <span
              className={styles.clickable}
              onClick={() => updateTheme("light")}
            >
              <LightThemeIcon width={iconSize} height={iconSize} />
            </span>
          )}
          {
            activeButton === "History" && !isFullScreen &&
            <span className={styles.clickable} onClick={() => setIsTableOpened((prev) => !prev)}>
              {isTableOpened ?
                theme === 'light' ? <TableIconFocused/> : <TableIconDarkFocused/> :
                theme === 'light' ? <TableIcon/> : <TableIconDark/>
              }
            </span>
          }
          <div
            className={styles.clickable}
            onClick={() => setIsFullScreen((prev) => !prev)}
          >
            {isFullScreen
              ? fullScreenIcons.fullScreen[theme]
              : fullScreenIcons.normalScreen[theme]}
          </div>
        </div>
      </div>
      {activeButton === "LastHour" ? (
        <LastHourChart patientCase={patientCase} userSettings={userSettings} isFullScreen={isFullScreen}/>
      ) : (
        <HistoryChart patientCase={patientCase} userSettings={userSettings} isTableOpened={isTableOpened} />
      )}
    </Container>
  );
};

export default MetricsWrapper;
