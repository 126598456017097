import React, {memo, FunctionComponent, useState, useEffect} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Header, ButtonBase } from '../../../../lib/components';
import { useHistory } from 'react-router-dom';
import { NavRoute } from '../../../../lib/constants/nav-route.constant';
import csvService from '../../../../services/csvService';
import { downloadCSV } from '../../../../lib/utils/download-csv';
import CircularProgressWithLabel from '../../../../lib/components/CircularProgressWithLabel';
import { LOADERT_CSV_MAIN } from '../../../../lib/constants/loader-name.constant';
import usePatientCaseLoader from '../../../../hooks/usePatientCaseLoader';
import useRequestUserSettings from '../../../../hooks/useRequestUserSettings';
import useUserSettings from '../../../../hooks/useUserSettings';
import { ReactComponent as DevicesIcon } from '../../../../theme/icons/devices.svg';
import { ReactComponent as DownloadCsv } from '../../../../theme/icons/download-csv.svg';

const useStyles = makeStyles(() =>
  createStyles({
    utils: {
      display: 'flex',
      alignItems: 'center'
    },
    utilityButtons: {
      borderRadius: '50%',
      marginLeft: 24,
    },
    progress: {
      padding: 5,
      marginLeft: 10,
    },
  })
);

const navigateToManageDevices = (history: any) => () => {
  history.push(NavRoute.MAIN.MANAGE_DEVICES);
}

const EmployeeHeader: FunctionComponent = memo(() => {
  const styles = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const csvLoader = usePatientCaseLoader();

   useRequestUserSettings();
   const userSettings = useUserSettings();

   const [measurementFormat, setMeasurementFormat] = useState(
     userSettings?.measurementFormat
   );

   useEffect(() => {
     setMeasurementFormat(userSettings?.measurementFormat);
     // eslint-disable-next-line
   }, [userSettings?.measurementFormat]);

  const downloadCSVForExport = () =>
    measurementFormat && csvService.getAlCasesData(t, measurementFormat).subscribe({
      next: (data) => {
        downloadCSV(`Export of all cases`, data);
      },
      complete: csvService.resetLoader,
    });

  return (
    <Header 
      title={t('header.patientListPage')}
      utility={
        <div className={styles.utils}>
          <ButtonBase className={styles.utilityButtons} onClick={navigateToManageDevices(history)}>
            <DevicesIcon />
          </ButtonBase>

          {/* <ButtonBase className={styles.utilityButtons} onClick={() => {}}>
            <FilterListIcon />
          </ButtonBase> */}

        {
          csvLoader && csvLoader.name === LOADERT_CSV_MAIN ?
            <div className={styles.progress}>
              <CircularProgressWithLabel size={40} value={Number(csvLoader.value)} />
            </div>
          :
            <ButtonBase className={styles.utilityButtons}
              onClick={downloadCSVForExport}
            >
              <DownloadCsv />
            </ButtonBase>
        }
        </div>
      }
    />
  );
});

export default EmployeeHeader;
