import React, {useContext} from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { colors } from "../../../../../theme/colors";
import {AppContext} from "../../../../../state/AppContext";
import {themeCss} from "../../../../../lib/utils/theme";

interface Props {
  firstLineText: string;
  secondLineText?: string;
  transparent?: boolean;
  icon: React.ReactNode;
}

function NoDataAvailable({ firstLineText, secondLineText, icon, transparent = true }: Props) {
  const { theme} = useContext(AppContext);
  const useStyles = makeStyles(() =>
    createStyles({
      noLastHourDataContainer: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "normal",
        color: colors.lightTheme.noLastHourDataText,
        backgroundColor: transparent ? "transparent" : colors[themeCss(theme)].noLastHourDataBackground,
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        gap: 13,
        borderRadius: 10,
      },
      noLastHourDataTitle: {
        fontSize: 18,
        fontWeight: 700,
      },
    })
  );

  const styles = useStyles();


  return (
    <div className={styles.noLastHourDataContainer}>
      {icon}
      <div>
        <span className={styles.noLastHourDataTitle}>No data available</span>
        <br />
        <br />
        {firstLineText}
        <br />
        {secondLineText}
      </div>
    </div>
  );
}

export default NoDataAvailable;
