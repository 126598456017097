import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../lib/components';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingLeft: 22,
      paddingTop: 50
    },
    title: {
      width: 300,
      whiteSpace: 'normal',
      fontSize: 18,
      lineHeight: '149.3%'
    }
  })
);


interface Props {}

const NoDevicesComponent: FunctionComponent<Props> = memo(() => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Typography className={styles.title}>{t('text.noDevicesTitle')}</Typography>
    </div>
  );
});

export default NoDevicesComponent;
