import React from 'react'

function CheckedIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="white" />
      <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#9C9A9A" />
      <path
        d="M11.4629 4.27702L6.6805 9.5621L4.55721 7.21762C4.1946 6.81724 3.63054 6.81724 3.27196 7.21762C2.90935 7.61801 2.90935 8.24083 3.27196 8.63677L6.04795 11.7019C6.22925 11.9021 6.45488 12 6.6805 12C6.90612 12 7.13175 11.9021 7.31305 11.7019L12.728 5.72285C13.0907 5.32247 13.0907 4.69965 12.728 4.30371C12.3896 3.90333 11.8255 3.90333 11.4629 4.27702Z"
        fill="#59669B"
      />
    </svg>
  );
}

export default CheckedIcon