import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AggregateBy } from '../../constants/aggregation.enum';
import {messageForGraphTooltip} from "../../utils/processing-message-for-graph-tooltip";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: 15,
      borderRadius: 8,
      backgroundColor: '#ffffff',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    date: {
      fontWeight: 600
    },
    max: {
      color: '#F17176'
    },
    avg: {
      color: '#9EA2A5'
    },
    min: {
      color: '#1FA1E9'
    }
  })
);

interface Props {
  min: number;
  avg: number;
  max: number;
  date: string;
  current: AggregateBy|string;
  onlyDate: boolean;
  keyName: string;
  message: string[] | null;
}

const ChartTooltip: FunctionComponent<Props> = memo(({ min, max, avg, date, current, onlyDate, keyName, message }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const colors = {
    min: (keyName === 'hr' || keyName === 'spo2') ? '#9BF39D' : '#1FA1E9',
    avg: keyName === 'hr' ? "#F17176" : keyName === 'spo2' ? "#1FA1E9" : "#9EA2A5",
    max: (keyName === 'hr' || keyName === 'spo2') ? '#000000' : '#F17176',
  }

  return (
    <div className={styles.container}>
      <p className={styles.date}>{ date }</p>
      {onlyDate ? null :
        current !== AggregateBy.MINUTES ? 
          <>
            <p style={{color: colors.max}}>{t('label.max')}: { max }</p>
            {avg && avg > 0 ? <p style={{color: colors.avg}}>{t('label.avg')}: { avg }</p> : null}
            <p style={{color: colors.min}}>{t('label.min')}: { min }</p>
          </>
        : message && message.length > 0 ?
          <p style={{color: colors.avg}}>{t('label.avg')}: { messageForGraphTooltip(message[0]) }</p>
          : <p style={{color: colors.avg}}>{t('label.avg')}: { avg }</p>
      }
      
    </div>
  );
});

export default ChartTooltip;
