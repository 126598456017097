import React, {memo, FunctionComponent} from 'react';

interface Props {
  
}

const NotFound: FunctionComponent<Props> = memo(() => {

  return (
    <div></div>
  );
});

export default NotFound;
