import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// ThemeProvider makes the Material UI theme available down the React component tree
import { ThemeProvider } from '@material-ui/core/styles';
// theme is a custom theme for Material UI
import { theme } from './theme';

// todo: do we need this import?
import './i18n';

// Entry point of the application

// renders the App component into the DOM element
//todo: change to createRoot https://react.dev/reference/react-dom/client/createRoot#createroot
ReactDOM.render(
  // StrictMode lets you find common bugs in your components early during development. https://react.dev/reference/react/StrictMode
  <React.StrictMode>
    {/* makes the Material UI theme available down the React component tree */}
    {/*todo: move this logic to App component*/}
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
