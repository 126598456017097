import React, {memo, FunctionComponent} from 'react';
import MUIButtonBase from '@material-ui/core/ButtonBase';

interface Props {
  className?: any;
  disableRipple?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ButtonBase: FunctionComponent<Props> = memo(({ className, children, onClick, disableRipple, disabled }) => {
  return (
    <MUIButtonBase 
      disableRipple={disableRipple}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      { children }
    </MUIButtonBase>
  );
});

export default ButtonBase;
