import React, { FunctionComponent } from 'react';

interface Props {
  width: number;
  height: number;
  color: string;
}

export const QuestionIcon: FunctionComponent<Props> = ({color, width, height}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 34 34" fill="none">
      <circle cx="17" cy="17" r="11.0769" stroke={color} strokeWidth="1.84615"/>
      <path
        d="M16.2896 20.0802C16.2844 19.9167 16.2818 19.7941 16.2818 19.7124C16.2818 19.2312 16.3598 18.8158 16.5159 18.4662C16.6304 18.2029 16.8152 17.9373 17.0702 17.6694C17.2575 17.4742 17.5932 17.1904 18.0772 16.8181C18.5664 16.4413 18.8838 16.1417 19.0295 15.9192C19.1752 15.6967 19.2481 15.4538 19.2481 15.1905C19.2481 14.7138 19.0347 14.2961 18.608 13.9375C18.1813 13.5743 17.6582 13.3927 17.039 13.3927C16.4405 13.3927 15.9409 13.5561 15.5402 13.883C15.1395 14.2099 14.8767 14.7206 14.7518 15.4153L13.3076 15.2654C13.4377 14.3347 13.8228 13.6219 14.4629 13.1271C15.1082 12.6322 15.9591 12.3848 17.0155 12.3848C18.1344 12.3848 19.0269 12.6504 19.693 13.1815C20.3592 13.7127 20.6922 14.3552 20.6922 15.1088C20.6922 15.5446 20.5751 15.9464 20.341 16.3142C20.1068 16.6819 19.6488 17.1291 18.9671 17.6558C18.5091 18.0099 18.2099 18.271 18.0694 18.4389C17.9289 18.6069 17.8248 18.7999 17.7571 19.0178C17.6895 19.2357 17.6504 19.5898 17.64 20.0802H16.2896ZM16.9017 22.5386C16.5162 22.5386 16.2037 22.2261 16.2037 21.8406C16.2037 21.4551 16.5162 21.1425 16.9017 21.1425H17.1059C17.4914 21.1425 17.804 21.4551 17.804 21.8406C17.804 22.2261 17.4914 22.5386 17.1059 22.5386H16.9017Z"
        fill={color}/>
    </svg>
  );
};
