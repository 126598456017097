import React, {useContext} from "react";
import {AppContext} from "../../../../../../../state/AppContext";
import {createStyles, makeStyles} from "@material-ui/core";
import {colors} from "../../../../../../../theme/colors";
import {themeCss} from "../../../../../../../lib/utils/theme";
import ReactTooltip from "react-tooltip";

type Props = {
  id: string;
}

const TableTooltip = ({ id } : Props) => {
  const { theme} = useContext(AppContext);
  const useStyles = makeStyles(() =>
    createStyles({
      tooltip: {
        width: 172,
        fontSize: 12,
        fontWeight: 500,
        paddingRight: '10px !important',
        paddingLeft: '10px !important',
        border: `1px solid ${colors[themeCss(theme)].metricTable.popup.border} !important`,
        backgroundColor: `${colors[themeCss(theme)].metricTable.popup.background} !important`,
        borderRadius: '3px !important',
        color: `${colors[themeCss(theme)].metricTable.popup.font} !important`,
        opacity: `1 !important`,
      }
    })
  );
  const styles = useStyles();

  return (
    <ReactTooltip
      id={id}
      place="right"
      effect="solid"
      className={styles.tooltip}
      arrowColor={'transparent'}
    />
  );
};

export default TableTooltip;
