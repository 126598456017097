
import { ImperialExtensionHistory, MetricExtensionHistory } from "../../../../../lib/constants/value-extension.constant";
import { Theme } from "../../../../../lib/interfaces/theme.type";
import { UserSettings } from "../../../../../lib/interfaces/user-settings.interface";
import { themeCss } from "../../../../../lib/utils/theme";
import { colors } from "../../../../../theme/colors";


export type EdoctorChartMetricName = 'HeartRate' | 'RespirationRate' | 'ChestExpansion' | 'BodyTemperature';
export type EbeatChartMetricName = 'HeartRate' | 'RespirationRate' | 'OxygenSaturationLevel' | 'BodyTemperature';

export type ChartMetricName = EdoctorChartMetricName | EbeatChartMetricName;


  export function getMetricColor(metric: ChartMetricName, theme: Theme) {
    const metricColors: Record<ChartMetricName, string> = {
      HeartRate: colors[themeCss(theme)].heartRate,
      RespirationRate: colors[themeCss(theme)].respirationRate,
      ChestExpansion: colors[themeCss(theme)].chestExpansion,
      OxygenSaturationLevel: colors[themeCss(theme)].oxygenSaturationLevel,
      BodyTemperature: colors[themeCss(theme)].bodyTemperature
    };
    return metricColors[metric];
  }

  export function getMetricColorHistory(metric: string, theme: Theme) {
    const metricColors: Record<string, string> = {
      "heart-rate": colors[themeCss(theme)].heartRate,
      "respiration-rate": colors[themeCss(theme)].respirationRate,
      "chest-expansion": colors[themeCss(theme)].chestExpansion,
      "oxygen-saturation-level": colors[themeCss(theme)].oxygenSaturationLevel,
      "body-temperature": colors[themeCss(theme)].bodyTemperature
    };
    return metricColors[metric];
    }
  
  export function getUnitForHistoryChart(metric: string,  userSettings: UserSettings | null) {
    if (!userSettings) {
      return "";
    }
  
    return userSettings.measurementFormat === "imperial" ? ImperialExtensionHistory[metric] : MetricExtensionHistory[metric];
  }
