import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Formik, FormikProps } from 'formik';
import { object as yupObject, string as yupString } from 'yup';
import { useTranslation } from 'react-i18next';
import { ForgotPasswordFormValues } from '../../interfaces/forgot-password-form-values.interface';
import { TFunction } from 'i18next';
import { formSubmission } from '../../../../../lib/utils/form-submission';
import { Button, TextField } from '../../../../../lib/components';

const useStyles = makeStyles(() =>
  createStyles({
    item: {
      marginBottom: 24 
    }
  })
);

interface Props {
  email: string;
  submitForm: (values: ForgotPasswordFormValues, event: () => void) => void;
}

const validationSchema = (t: TFunction) => yupObject().shape({
  email: yupString()
    .email(t('validation.invalidEmailFormat'))
    .required(t('validation.emailRequired'))
});

const ForgotPasswordForm: FunctionComponent<Props> = memo(({ email, submitForm }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const renderForm = ({
    values,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
    isSubmitting,
    isValid
  }: FormikProps<ForgotPasswordFormValues>) => {
    const onBlur = (name: string) => () => setFieldTouched(name);
    const onChangeText = (name: string) => (value: string) => setFieldValue(name, value);

    return (
      <>
        <div className={styles.item}>
          <TextField
            label={t('placeholder.emailAddress')}
            onChange={onChangeText('email')}
            onBlur={onBlur('email')}
            value={values.email}
            type="email-address"
            error={Boolean(touched.email && errors.email)}
            disabled={isSubmitting}
            required={true}
            helperText={(touched.email && errors.email) ? errors.email : undefined}
          />
        </div>
        
        <div className={styles.item}>
          <Button
            loading={isSubmitting}
            disabled={isSubmitting || !isValid}
            onClick={handleSubmit}
          >
            { t('button.forgotPassword') }
          </Button>
        </div>
      </>
  )};

  return (
    <Formik
      initialValues={{ email }}
      onSubmit={formSubmission<ForgotPasswordFormValues>(submitForm)}
      validationSchema={validationSchema(t)}
    >
      {renderForm}
    </Formik>
  );
});

export default ForgotPasswordForm;
