import React, { memo, FunctionComponent, useState } from "react";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ButtonBase from "../ButtonBase";
import Menu from "../Menu";
import { authService } from "../../../services";
import { makeStyles, createStyles } from "@material-ui/core";
import Dialog from "../Dialog";
import AppSettingsForm from "../../../pages/PatientMetrics/components/AppSettingsForm";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      margin: "-18px 15px 0 15px",
    },
  })
);

interface Props {}

const UserMenu: FunctionComponent<Props> = memo(() => {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openAppSettingsDialog, setOpenAppSettingsDialog] = useState(false);

  const elements = [
    {
      titleKey: "contactUs",
      handler: () => {
        window.open("https://support.tektelic.com/portal/en/signin", "_blank");
      },
    },
    {
      titleKey: "appSettings",
      handler: () => {
        setOpenAppSettingsDialog(true);
      },
    },
    {
      titleKey: "logOut",
      handler: () => {
        authService.signOutProcess().subscribe();
      },
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonBase className={styles.container} onClick={handleClick}>
        <AccountCircleIcon />
      </ButtonBase>
      <Menu elements={elements} handleClose={handleClose} anchorEl={anchorEl} />
      <Dialog
        open={openAppSettingsDialog}
        setOpen={setOpenAppSettingsDialog}
        onCancel={() => {
          setOpenAppSettingsDialog(false);
        }}
        title={"Application settings"}
        noButtons
        smallStyle={true}
      >
        <AppSettingsForm closeForm={() => setOpenAppSettingsDialog(false)} />
      </Dialog>
    </>
  );
});

export default UserMenu;
