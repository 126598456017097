import React, {useContext} from "react";
import {createStyles, makeStyles} from "@material-ui/core";
import {MetricLabels, MetricName, MetricResponse} from "../../../../../../lib/interfaces/metric-history.interface";
import moment from "moment";
import {MetricNameToAbbreviation} from "../../../../../../lib/constants/metrics-name.enum";
import {ImperialExtension, MetricExtension, UnitKey} from "../../../../../../lib/constants/value-extension.constant";
import {UserSettings} from "../../../../../../lib/interfaces/user-settings.interface";
import {useTranslation} from "react-i18next";
import NoDataAvailable from "../../NoDataAvailable";
import {AppContext} from "../../../../../../state/AppContext";
import {colors} from "../../../../../../theme/colors";
import {themeCss} from "../../../../../../lib/utils/theme";
import {getMetricValue} from "../../../../../../lib/utils/get-metric-value";
import TableTooltip from "./TableTooltip";
import {DAY_FORMAT, TIME_FORMAT} from "../../../../../../lib/constants/date-format.constant";
import NoTableDataIcon from "../../../../../../theme/icons/NoTableDataIcon";

type MergedMetricItems = {
  firstMetricName: MetricName;
  secondMetricName: MetricName | null;
  items: MergedMetricItem[];
}

type MergedMetricItem = {
  primaryTimeElement: string;
  secondaryTimeElement: string | null;
  firstMetric: MetricItemValue;
  secondMetric: MetricItemValue | null;
};

type MetricItemValue = {
  value: number | null;
  message: string | null;
  outOfRange: boolean | null
}

type FormattedTime = {
  primaryTimeElement: string;
  secondaryTimeElement: string | null;
}

type Props = {
  response: MetricResponse | null;
  userSettings: UserSettings | null;
  savedSelectedAggregationKey: string;
}

// '2024-04-09T13:00:00' - for aggregated
// '2024-04-09T12:58:06.271192' - for non-aggregated
export function formatTime(timestamp: number, selectedAggregation: string): FormattedTime {
  const formats: Record<string, (timestamp: number) => FormattedTime> = {
    'none': timestampValue => {
      return {
        primaryTimeElement: moment(timestampValue).format(TIME_FORMAT),
        secondaryTimeElement: moment(timestampValue).format(DAY_FORMAT),
      }
    },
    'hour': timestampValue => {
      const fromTime = moment(timestampValue).subtract(1, "hour").format(TIME_FORMAT);
      const toTime = moment(timestampValue).subtract(1, "minute").format(TIME_FORMAT);
      const day = moment(timestampValue).subtract(1, "hour").format(DAY_FORMAT);
      return {
        primaryTimeElement: `${fromTime} - ${toTime}`,
        secondaryTimeElement: day,
      }
    },
    'day': timestampValue => {
      return {
        primaryTimeElement: moment(timestampValue).format(DAY_FORMAT),
        secondaryTimeElement: null,
      }
    }
  }
  return formats[selectedAggregation](timestamp) || formats['none'](timestamp);
}

function mergeMetrics(response: MetricResponse | null, aggregationKey: string): MergedMetricItems | null {
  if (response === null || response.metrics.length === 0) {
    return null;
  }
  let mergedMetricItems: MergedMetricItem[] = [];
  const firstMetric = response.metrics[0];

  // loop through the first metric data
  for (let i = 0; i < firstMetric.data.length; i++) {
    const dateTime = firstMetric.data[i].datetime;
    const formattedTime = formatTime(Date.parse(dateTime), aggregationKey);
    mergedMetricItems.push({
      primaryTimeElement: formattedTime.primaryTimeElement,
      secondaryTimeElement: formattedTime.secondaryTimeElement,
      firstMetric: {
        value: firstMetric.data[i].value,
        message: firstMetric.data[i].tableTooltipMessage,
        outOfRange: firstMetric.data[i].outOfRange
      },
      secondMetric: null,
    });
  }

  // if there is a second metric, loop through it
  if (response.metrics.length > 1) {
    const secondMetric = response.metrics[1];
    for (let i = 0; i < secondMetric.data.length; i++) {
      mergedMetricItems[i].secondMetric = {
        value: secondMetric.data[i].value,
        message: secondMetric.data[i].tableTooltipMessage,
        outOfRange: secondMetric.data[i].outOfRange
      };
    }
  }
  mergedMetricItems.reverse();

  return {
    firstMetricName: response.metrics[0].name,
    secondMetricName: response.metrics.length > 1 ? response.metrics[1].name : null,
    items: mergedMetricItems
  };
}

const MetricTable = ({response, userSettings, savedSelectedAggregationKey}: Props) => {
  const {theme} = useContext(AppContext);
  const useStyles = makeStyles(() =>
    createStyles({
      metricTable: {
        width: '100%',
        position: 'absolute',
        display: 'block',
        borderCollapse: 'collapse',
        color: colors[themeCss(theme)].metricTable.text,
        '& td': {
          paddingTop: 18,
        },
        '& th': {
          textAlign: 'left',
        },
        '& th, & td': {
          paddingRight: 10,
        },
        '& thead': {
          position: 'sticky',
          top: 0,
          backgroundColor: colors[themeCss(theme)].metricTable.headerBackground,
          fontSize: 13,
          fontWeight: 700,
        },
        '& tbody': {
          fontSize: 15,
        },
        '& thead, & tbody tr': {
          display: 'table',
          width: '100%',
          tableLayout: 'fixed',
        }
      },
      secondaryTimeElement: {
        color: colors[themeCss(theme)].metricTable.date,
        fontSize: 13,
      },
      primaryTimeElement: {
        fontWeight: 700,
      },
      valueCell: {
        fontWeight: 600,
      },
      value: {
        display: 'inline-block',
        minWidth: 55,
        textAlign: 'center',
        padding: `9px 12px`
      },
      outOfRange: {
        backgroundColor: colors.formInvalid,
        color: colors.blackTheme.metricTable.text,
      }
    })
  );
  const styles = useStyles();
  const {t} = useTranslation();

  const emptyIcon = <NoDataAvailable icon={<NoTableDataIcon/>} firstLineText={"Please adjust your selection"}
                                     transparent={false}/>;

  if (response === null || response.metrics[0].data.length === 0) {
    return emptyIcon;
  }

  const responseWithoutGaps = {
    ...response,
    metrics: response.metrics.map(metric => ({
      ...metric,
      data: metric.data.filter(dataItem => dataItem.showInTable)
    }))
  };

  if (responseWithoutGaps.metrics[0].data.length === 0) {
    return emptyIcon;
  }

  const tableItems: MergedMetricItems | null = mergeMetrics(responseWithoutGaps, savedSelectedAggregationKey);
  const singleMetric = responseWithoutGaps.metrics.length === 1;
  if (tableItems === null) {
    return emptyIcon;
  }

  function getMetricUnits(metricName: MetricName): string {
    let extension = MetricExtension;
    if (userSettings && userSettings.measurementFormat === "metric") {
      extension = MetricExtension;
    } else if (userSettings && userSettings.measurementFormat === "imperial") {
      extension = ImperialExtension;
    }
    const metricCode: UnitKey = MetricNameToAbbreviation[metricName];
    return extension[metricCode];
  }

  function getHeaderText(metricName: MetricName): string {
    const metricTitle = MetricLabels[metricName];
    const metricUnits = getMetricUnits(metricName);
    return metricTitle + (metricUnits ? ', ' + metricUnits : '');
  }

  function createCell(tooltipId: string, metricItemValue: MetricItemValue, metricName: MetricName) {
    return (
      <td className={styles.valueCell}>
        <span
          className={`${styles.value} ${metricItemValue.outOfRange ? styles.outOfRange : ''}`}
          data-tip={metricItemValue.message}
          data-for={tooltipId}
        >
          {metricItemValue.value === null ? '-' : getMetricValue(metricName, metricItemValue.value, t)}
        </span>
        {metricItemValue.message && <TableTooltip id={tooltipId}/>}
      </td>
    );
  }

  return (
    <table className={styles.metricTable}>
      <thead>
      <tr>
        <th>Date & Time</th>
        <th>{getHeaderText(tableItems.firstMetricName)}</th>
        {(!singleMetric && tableItems.secondMetricName) && <th>{getHeaderText(tableItems.secondMetricName)}</th>}
      </tr>
      </thead>
      <tbody>
      {tableItems.items.map((item, index) => (
        <tr key={index}>
          <td>
            <div className={styles.primaryTimeElement}>{item.primaryTimeElement}</div>
            {item.secondaryTimeElement &&
              <div className={styles.secondaryTimeElement}>{item.secondaryTimeElement}</div>}
          </td>
          {createCell(`tooltip-firstMetric-${index}`, item.firstMetric, tableItems.firstMetricName)}
          {!singleMetric && item.secondMetric && tableItems.secondMetricName &&
            createCell(`tooltip-secondMetric-${index}`, item.secondMetric, tableItems.secondMetricName)}
        </tr>
      ))}
      </tbody>
    </table>
  )
}

export default MetricTable;
