import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { generateHeadCells } from '../../../../lib/utils/generate-head-cells';
import { Table } from '../../../../lib/components';
import { modifyTableData } from '../../../../lib/utils/modify-table-data';
import { useTranslation } from 'react-i18next';
import { formatDateTimeFullForm } from '../../../../lib/utils/format-date';
import {Case} from "../../../../lib/interfaces/case.interface";
import {caseViewModel} from "../../../../lib/models/case-view.model";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      overflowY: 'auto'
    }
  })
);

interface Props {
  patients: Case[];
  setSelectedCaseId: (value: string) => void;
}

const PatientCaseBody: FunctionComponent<Props> = memo(({
  patients, setSelectedCaseId
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const headCells = generateHeadCells(caseViewModel(patients[0]), false, ['patientId', 'deviceId', 'patientFirstName', 'patientLastName']);

  function createFullName(caseView: Case) {
    return `${caseView.patientFirstName} ${caseView.patientLastName}`;
  }

  const bodyCells = patients.map(caseView => {
    const fullName = createFullName(caseView);
    return {
      id: caseView.caseId,
      caseId: caseView.caseId,
      patientNameValue: fullName,
      patientName: modifyTableData(fullName),
      initiationDate: modifyTableData(caseView.initiationDate, '', formatDateTimeFullForm(caseView.initiationDate)),
      sex: modifyTableData(caseView.sex ? t(`sex.${caseView.sex}`) : '-'),
      age: modifyTableData(caseView.age ? String(caseView.age) : '-'),
      deviceType: modifyTableData(caseView.deviceType ? t(`deviceType.${caseView.deviceType}`) : '-'),
    };
  });

  return (
    <div className={styles.container}>
      <Table
        orderByInit={'initiationDate'}
        data={bodyCells}
        headCells={headCells}
        omitSortKeys={[]}
        onSelect={setSelectedCaseId}
        disablePagination
      />
    </div>
  );
});

export default PatientCaseBody;
