import React, { useEffect, useState } from "react";
import RadioButton from "../../../../lib/components/RadioButton";
import { Button, Typography } from "../../../../lib/components";
import { createStyles, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  authService,
  errorService,
  messageService,
} from "../../../../services";
import useUserSettings from "../../../../hooks/useUserSettings";
import useRequestUserSettings from "../../../../hooks/useRequestUserSettings";

interface Props {
  closeForm: () => void;
  containerStyles?: React.CSSProperties;
}

const AppSettingsForm = ({ closeForm, containerStyles }: Props) => {
  useRequestUserSettings();
  const userSettings = useUserSettings();

  const [selectedValue, setSelectedValue] = useState(
    userSettings?.measurementFormat || null
  );

  useEffect(() => {
    setSelectedValue(userSettings?.measurementFormat || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettings?.measurementFormat]);

  const styles = useStyles();
  const { t } = useTranslation();

  const handleSave = () => {
    userSettings && selectedValue &&
      authService
        .updateUserSettings({
          ...userSettings,
          measurementFormat: selectedValue,
        })
        .subscribe({
          next: () => {
            authService.getUserSettings().subscribe();
            messageService.addMessage(t("text.settingsUpdated"));
            closeForm();
          },
          error: (error) => {
            errorService.addError(error);
          },
        });
  };

  return (
    <div style={containerStyles}>
      <div className={styles.row}>
        <Typography>{t("text.measurementUnits")}</Typography>
        <RadioButton
          isSelected={selectedValue === "metric"}
          label="Metric"
          onSelect={() => setSelectedValue("metric")}
        />
        <RadioButton
          isSelected={selectedValue === "imperial"}
          label="Imperial"
          onSelect={() => setSelectedValue("imperial")}
        />
      </div>

      <div className={styles.btnBlock}>
        <Button
          disabled={selectedValue === null}
          onClick={handleSave}
          rootClass={styles.btnSave}
        >
          {t("button.save")}
        </Button>
        <Button onClick={closeForm} rootClass={styles.btnCancel}>
          {t("button.cancel")}
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    btnBlock: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 20,
      width: "100%",
    },
    btnCancel: {
      width: 150,
      background: "transparent",
      border: "1px solid #5C6AA9",
      color: "#5C6AA9",
      "&:hover": {
        background: "transparent",
        color: "#5C6AA9",
      },
      marginLeft: 15,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    btnSave: {
      width: 150,
    },
  })
);

export default AppSettingsForm;
