import React, {memo, FunctionComponent, useState, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import { ButtonBase, CircularProgress } from '../../../../lib/components';
import { Case } from '../../../../lib/interfaces/case.interface';
import EditPatientForm from '../../../../lib/components/EditPatientForm';
import {EditPatientFormValues, EditPatientRequest} from '../../../../lib/interfaces/patient-form.interface';
import { caseService, messageService } from '../../../../services';
import EditPatientDialog from "./EditPatientDialog";
import {ThresholdMetric} from "../../../../lib/interfaces/new-patient-form.interface";
import {switchMap} from "rxjs/operators";
import {AppContext} from "../../../../state/AppContext";

interface Props {
  patientCase: Case;
}

function convertFormToRequest(deviceId: string, form: EditPatientFormValues): EditPatientRequest {
  let patient: EditPatientRequest = {
    firstName: form.firstName,
    lastName: form.lastName,
    deviceId: deviceId,
    thresholds: [],
  }
  if (form.pulseRate.min !== '' && form.pulseRate.max !== '') {
    patient.thresholds.push({
      metric: 'HeartRate' as ThresholdMetric,
      lowRange: Number(form.pulseRate.min),
      highRange: Number(form.pulseRate.max),
    });
  }
  if (form.bodyTemperature.min !== '' && form.bodyTemperature.max !== '') {
    patient.thresholds.push({
      metric: 'BodyTemperature' as ThresholdMetric,
      lowRange: Number(form.bodyTemperature.min),
      highRange: Number(form.bodyTemperature.max),
    });
  }
  if (form.respiratoryRate.min !== '' && form.respiratoryRate.max !== '') {
    patient.thresholds.push({
      metric: 'RespirationRate' as ThresholdMetric,
      lowRange: Number(form.respiratoryRate.min),
      highRange: Number(form.respiratoryRate.max),
    });
  }
  if (form.oxygenSaturationLevel.min !== '' && form.oxygenSaturationLevel.max !== '') {
    patient.thresholds.push({
      metric: 'OxygenSaturationLevel' as ThresholdMetric,
      lowRange: Number(form.oxygenSaturationLevel.min),
      highRange: Number(form.oxygenSaturationLevel.max),
    });
  }
  return patient;
}

const EditPatientButton: FunctionComponent<Props> = memo(({ patientCase }) => {
  const { setCases } = useContext(AppContext);
  const { t } = useTranslation();
  const toggleDialog = () => setOpen(!open);
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const submit = (formValues: EditPatientFormValues, event: () => void) => {
    toggleDialog();
    setLoading(true);
    caseService.updateCase(patientCase.caseId, convertFormToRequest(patientCase.deviceId, formValues)).pipe(
      switchMap(
        () => caseService.getCasesList()
      )).subscribe(
      (cases) => {
        setCases(cases);
        messageService.addMessage(t('text.patientUpdated', {id: patientCase.patientId}));
        setLoading(false);
      },
      () => setLoading(false)
    )
  }

  return (
    <>
      {
        isLoading ? 
          <CircularProgress size={16} />
        :
          <ButtonBase onClick={toggleDialog}>
            <EditIcon />
          </ButtonBase>
      }
      
      <EditPatientDialog
        open={open}
        setOpen={setOpen}
        title={t('header.edit', {name: `${patientCase.patientFirstName} ${patientCase.patientLastName}`})}
      >
        <EditPatientForm patientCase={patientCase} submitForm={submit} onCancel={toggleDialog} />
      </EditPatientDialog>
    </>
  );
});

export default EditPatientButton;
