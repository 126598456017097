import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React from 'react';
import {createStyles, makeStyles} from "@material-ui/core";
import { ReactComponent as SnackbarIcon } from './snackbar-icon.svg';

const useStyles = makeStyles(() =>
  createStyles({
    snackbar: {
      '& .MuiAlert-root': {
        fontFamily: 'Raleway, sans-serif',
        borderRadius: 8
      },
    },
  })
);

function Alert(props: AlertProps) {
  return <MuiAlert icon={<SnackbarIcon/>} elevation={6} variant="filled" {...props} />;
}

interface Props {
  updatedDeviceName: string;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

export default function CenteredSnackbar({ updatedDeviceName, open, setOpen }: Props) {
  const styles = useStyles();

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div style={{position: 'relative', height: '100vh'}}>
      <div className={styles.snackbar}>
        <Snackbar
          disableWindowBlurListener
          open={open}
          onClose={handleClose}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          style={{position: 'absolute', width: '80%'}}
        >
          <Alert onClose={handleClose} severity="success">
            <span>The device <b>{updatedDeviceName}</b> has been successfully updated</span>
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}
