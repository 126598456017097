import { useState, useEffect } from 'react';
import { UserSettings } from '../../lib/interfaces/user-settings.interface';
import { authService } from '../../services';

const useUserSettings = () => {
  const [settings, setUserSettings] = useState<UserSettings | null>(null);

  useEffect(() => {
    const sub = authService.userSettings.subscribe((userSettings) => {
      if (userSettings) {
        setUserSettings(userSettings)
      }
    });

    return () => sub.unsubscribe();
  }, []);

  return settings;
};

export default useUserSettings;