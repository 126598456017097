import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthState } from '../../../hooks';
import { NavRoute } from '../../constants/nav-route.constant';
import { VERIFICATION_STATE } from '../../constants/verification-state.enum';

function PrivateRoute({ children, ...rest }: any) {
  const authStatus = useAuthState();
  return authStatus ? (
    <Route {...rest}
      render={({ location }) =>
        authStatus === VERIFICATION_STATE.AUTHORIZED ? (children) : (
          <Redirect
            to={{
              pathname: NavRoute.AUTH.SIGN_IN,
              state: { from: location }
            }}
          />
        )
      }
    />
  ) : null;
}

export default PrivateRoute;
