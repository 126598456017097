import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import MUISnackbar from '@material-ui/core/Snackbar'
import { MessageVariant } from '../../constants/message-variant.enum';

const useStyles = makeStyles(() =>
  createStyles({
    message: {
      '& > div': {
        backgroundColor: '#4caf50'
      }
    },
    warning: {
      '& > div': {
        backgroundColor: '#ffa500'
      }
    },
    error: {
      '& > div': {
        backgroundColor: '#E26653'
      }
    }
  })
);

interface Props {
  open: boolean;
  message: string;
  type: MessageVariant;
  onClose?: () => void;
}

const Snackbar: FunctionComponent<Props> = memo(({ open, onClose, message, type }) => {
  const styles = useStyles();

  return (
    <MUISnackbar 
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      message={message}
      classes={{
        root: styles[type]
      }}
    />
  );
});

export default Snackbar;
