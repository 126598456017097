// @ts-nocheck
import React, {memo, useMemo, FunctionComponent, useState, MouseEvent, ChangeEvent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import MUITable from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '../TableHead';
import TableBody from '../TableBody';
import { Order } from '../../interfaces/order.type';
import { HeadCell } from '../../interfaces/head-cell.interface';
import { stableSort } from '../../utils/stable-sort';
import { getComparator } from '../../utils/get-comparator';

const useStyles = makeStyles(() =>
  createStyles({
    table: {

    }
  })
);

interface Props {
  data: any[];
  headCells: HeadCell[];
  orderByInit: string;
  chosen?: string;
  onSelect?: (caseId: string, name: string) => void;
  translationKey?: string;
  omitSortKeys?: string[];
  disablePagination?: boolean;
}

const Table: FunctionComponent<Props> = memo(({ 
  data, onSelect, headCells, orderByInit, chosen, translationKey, omitSortKeys, disablePagination
}) => {
  const styles = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<string>(orderByInit);
  
  const emptyRows = useMemo(
    () => rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage), 
    [rowsPerPage, page, data]
  );

  const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = disablePagination ? stableSort(data, getComparator(order, orderBy)) :
    stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  
  return (
    <div>
      <TableContainer>
        <MUITable
          className={styles.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >

          <TableHead
            order={order}
            orderBy={orderBy}
            onSort={handleRequestSort}
            headCells={headCells}
            translationKey={translationKey}
            omitSortKeys={omitSortKeys}
          />

          <TableBody 
            data={sortedData}
            headCells={headCells}
            emptyRows={emptyRows}
            onSelect={onSelect}
            chosen={chosen}
          />

        </MUITable>
      </TableContainer>

      {
      !disablePagination &&
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      }
    </div>
  );
});

export default Table;
