import React, { useState } from "react";
// Amplify - is used for Google Analytics
//todo: update version
import Amplify from "aws-amplify";
// AWSConfig - configuration for Amplify
import { AWSConfig } from "./lib/config/aws-exports";
// MuiPickersUtilsProvider - provides date utilities for Material-UI pickers
//todo: do we need this import?
//todo: delete
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// MomentUtils - date utilities for Material-UI pickers
import MomentUtils from "@date-io/moment";
// Resets CSS styles
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  // Error message snackbar
  ErrorDisplay,
  // Success message snackbar
  MessageDisplay
} from "./lib/components";
import {
  // Custom hook for error message state
  useError,
  // Custom hook for success message state
  useMessage
} from "./hooks";
// MainNavigator - main navigator of the application
import MainNavigator from "./navigator/MainNavigator";
import { AppContext } from "./state/AppContext";
import { SelectedPatient } from "./lib/interfaces/selected-patient.interface";
import { Case } from "./lib/interfaces/case.interface";
import { Theme } from "./lib/interfaces/theme.type";

// Apply Amplify configuration
Amplify.configure(AWSConfig);

// Main component for the application

function App() {
  // Custom hook for error message state
  const [error, setError] = useError();
  // Custom hook for success message state
  const [message, setMessage] = useMessage();
  //deprecated - was used for the unfinished dark design
  // is stored in the Context
  const [selectedPatient, setSelectedPatient] =
    useState<SelectedPatient | null>(null);
  // Full screen mode
  // true - full screen mode is enabled
  // false - full screen mode is disabled
  const [isFullScreen, setIsFullScreen] = useState(false);
  // Cases - list of cases
  // used for:
  // - displaying information about patients in the left sidebar
  // - displaying information about patient in the patient info section on the Details screen
  // - displaying last hour metrics in the Last hour screen
  const [cases, setCases] = useState<Case[] | null>(null);
  // Theme - current theme of the application
  // light - light theme
  // dark - dark theme
  const [theme, setTheme] = useState<Theme>('light');

  return (
    //todo: create separate component for AppContext.Provider and move all context state to it
    <AppContext.Provider
      value={{
        selectedPatient,
        setSelectedPatient,
        isFullScreen,
        setIsFullScreen,
        cases,
        setCases,
        theme,
        setTheme,
      }}
    >
      {/* provides date utilities for Material-UI pickers */}
      {/* todo: do we need this import? */}
      {/* todo: delete */}
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {/*Resets CSS styles*/}
        <CssBaseline />
        {/*Shows error message snackbar if error message is not empty*/}
        <ErrorDisplay error={error} setError={setError} />
        {/*Shows success message snackbar if success message is not empty*/}
        <MessageDisplay message={message} setMessage={setMessage} />
        {/*Main navigator of the application*/}
        <MainNavigator />
      </MuiPickersUtilsProvider>
    </AppContext.Provider>
  );
}

// todo: #MD-650 fix the error
// got error while updating react in development mode
// index.js:1 Refused to execute inline script because it violates
// the following Content Security Policy directive: "script-src 'self' 'sha256-eQEC0N6l4J8wki7xcDuel9PXKtP7aJlgG4K7iAxg5/s='
// https:". Either the 'unsafe-inline' keyword, a hash ('sha256-D6kpIX0A4TTsmvxfEpOpkS2C/u3VGN/Hbjf0UwftoVg='),
// or a nonce ('nonce-...') is required to enable inline execution.

export default App;
