import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';

export const AuthApi = {
  signIn: (email: string, password: string): Promise<CognitoUser | any> => 
    Auth.signIn(email, password),
  forgotPassword: (email: string): Promise<any> => Auth.forgotPassword(email),
  forgotPasswordSubmit: (username: string, code: string, password: string): Promise<void> => 
    Auth.forgotPasswordSubmit(username, code, password),
  completeNewPassword: (user: CognitoUser, password: string, requiredAttributes: any): Promise<CognitoUser | any> => 
    Auth.completeNewPassword(
      user,  // the Cognito User Object
      password, // the new password
      requiredAttributes // the required attributes
    ),
  currentSession: (): Promise<any> => Auth.currentSession(),
  currentUserInfo: (): Promise<any> => Auth.currentUserInfo(),
  signOut: (): Promise<any> => Auth.signOut()
};
