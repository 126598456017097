import Tooltip from '@material-ui/core/Tooltip';
import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {createStyles, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      backgroundColor: '#3E3E3E',
      borderColor: '#4D4D4D',
      border: '1px solid',
      fontSize: 12,
      fontWeight: 600,
      fontFamily: 'Raleway, sans-serif',
    },
    content: {
      '&::after': {
        content: "''",
        display: 'block',
      }
    },
  })
);

const TableCellWithTooltip: FunctionComponent<{ content: string }> = ({ content }) => {
  const cellRef = useRef<HTMLTableCellElement>(null);
  const [isCropped, setIsCropped] = useState(false);
  const styles = useStyles();

  const checkIfCropped = () => {
    if (cellRef.current && cellRef.current.scrollWidth > cellRef.current.clientWidth) {
      setIsCropped(true);
    } else {
      setIsCropped(false);
    }
  };

  useEffect(() => {
    checkIfCropped(); // Check initially
    window.addEventListener('resize', checkIfCropped); // Check on window resize

    return () => {
      window.removeEventListener('resize', checkIfCropped);
    };
  }, [content]);

  return (
    isCropped ?
      <td ref={cellRef}><Tooltip title={content} placement={'top-start'} interactive={true} classes={{tooltip: styles.tooltip}}>
        <span className={styles.content}>{content}</span>
      </Tooltip></td>
    :
      <td ref={cellRef}>{content}</td>
  );
};

export default TableCellWithTooltip;
