import React, {memo, FunctionComponent, useState, ChangeEvent} from 'react';
import { useTranslation } from 'react-i18next';
import { SearchField } from '../../../../lib/components';

interface Props {
  onSearch: (value: string) => void;
}

const PatientSearch: FunctionComponent<Props> = memo(({
  onSearch
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>('');

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchValue(value);
    onSearch(value);
  }

  return (
    <SearchField
      value={searchValue}
      onChange={onChange}
      placeholder={t('placeholder.search')}
    />
  );
});

export default PatientSearch;
