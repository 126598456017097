export function convertMetricValueToChartCoordinate(
  /**
   * Metric value
   */
  value: number | null,
  /**
   * Minimum value of the metric
   */
  minValue: number | null,
  /**
   * Maximum value of the metric
   */
  maxValue: number | null,
  /**
   * Number of metrics in the chart
   */
  numberOfMetrics: number,
  /**
   * Index of the metric in the chart
   * Example: 0 - top metric, 1 - second metric from the top, etc.
   */
  chartIndex: number,
  /**
   * Padding for metric chart in percents
   * Example: 5 - 5% padding from the top and bottom of the chart, 10% padding between neighboring metrics
   */
  padding: number
) {
  if (value === null || minValue === null || maxValue === null) {
    return null;
  }
  if (minValue === maxValue) {
    maxValue = value + 1;
    minValue = value - 1;
  }

  const coordinateInterval = 100 / numberOfMetrics;
  const coordinateIntervalWithoutPadding = coordinateInterval - padding * 2;
  const bottomChartCoordinate = 100 - (chartIndex + 1) * coordinateInterval + padding;

  const valueInterval = maxValue - minValue;
  const chartCoordinateToMetricRatio = coordinateIntervalWithoutPadding / valueInterval;
  return (value - minValue) * chartCoordinateToMetricRatio + bottomChartCoordinate;
}
