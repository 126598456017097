import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { MetricsView } from '../../../../lib/interfaces/metric-history.interface';
import { Typography } from '../../../../lib/components';
import {formatDateTimeFullForm, extractDate, extractTime} from '../../../../lib/utils/format-date';
import { colors } from '../../../../theme/colors';
import { ValueLimit } from '../../../../lib/constants/value-limit.constant';
import { checkLimitExtended } from '../../../../lib/utils/check-limit';
import { isNullable } from '../../../../lib/utils/checkValue';
import { AggregateBy } from '../../../../lib/constants/aggregation.enum';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReactTooltip from "react-tooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 15
    },
    leftItem: {
      width: '35%'
    },
    rightItem: {
      width: '65%'
    },
    data: {
      display: 'flex',
      justifyContent: 'flex-start'
    },
    singleData: {
      justifyContent: 'center'
    },
    exceeded: {
      color: theme.palette.error.main
    },
    text: {
      width: 50,
      fontWeight: 700,
      fontSize: 18,
    },
    date: {
      fontWeight: 400,
      fontSize: 14,
      color: colors.gray
    },
    dataValue: {
      marginRight: 18,
    },
    iconContainer: {
      marginLeft: -20,
      marginTop: 0,
      cursor: "pointer",
      opacity: .5
    },
    tooltipText: {
      fontWeight: 400,
      fontSize: 12,
      margin: 0,
      paddingBottom: 1,
      lineHeight: 1.5,
    },
    tooltip: {
      width: 350,
      borderRadius: 5,
    },
    opacityText: {
      opacity: .5
    }
  })
);


interface Props {
  historyItem: MetricsView;
  fieldName: string|null;
  aggregateBy: AggregateBy|string;
}

const HistoryTableItem: FunctionComponent<Props> = memo(({ historyItem, fieldName, aggregateBy }) => {
  const styles = useStyles();
  const itemExceeded = fieldName && checkLimitExtended(ValueLimit[fieldName], historyItem.min, historyItem.max);

  const minValue = isNullable(historyItem.min) ? '-' : historyItem.min;
  const avgValue = !historyItem.avg ? '-' : historyItem.avg; 
  const maxValue = isNullable(historyItem.max) ? '-' : historyItem.max;

  return (
    <div className={styles.container}>
      <div className={styles.leftItem}>
        {
          aggregateBy !== AggregateBy.DAYS &&
          <Typography className={`${styles.text} ${itemExceeded && styles.exceeded}`}>
            {extractTime(historyItem.aggregatedTime)}
          </Typography>
        } 

        <Typography className={styles.date}>{extractDate(historyItem.aggregatedTime)}</Typography>

      </div>
      <div className={`${styles.rightItem} ${styles.data} ${aggregateBy === AggregateBy.MINUTES && styles.singleData}`}>
        <Typography
          className={`${styles.text} ${itemExceeded && styles.exceeded} ${styles.dataValue} ${aggregateBy === AggregateBy.MINUTES && historyItem.message && historyItem.message.length > 0 ? styles.opacityText : ''} `}>{minValue}</Typography>
        {
          aggregateBy === AggregateBy.MINUTES && historyItem.message && historyItem.message.length > 0 ?
            <>
              <div
                className={styles.iconContainer}
                data-tip
                data-for={historyItem.aggregatedTime}
              >
                <InfoOutlinedIcon/>
              </div>
              <ReactTooltip id={historyItem.aggregatedTime} overridePosition={({left, top}) => {
                return {top: top - 60, left: left + 100}
              }} backgroundColor={"#FBFCFD"} type="light" borderColor='#404040' effect='solid' className={styles.tooltip}>
                <p className={styles.tooltipText}>{`${historyItem.message?.[0] ?? ''}${historyItem.previousDataDate ? ` ${formatDateTimeFullForm(historyItem.previousDataDate)}` : ''}${historyItem.message?.[1] ?? ''}`}</p>
              </ReactTooltip>
            </> : null
        }
        
        {
          aggregateBy !== AggregateBy.MINUTES &&
          <>
            <Typography className={`${styles.text} ${styles.dataValue}`}>{avgValue}</Typography>
            <Typography className={`${styles.text} ${itemExceeded && styles.exceeded}`}>{maxValue}</Typography>
          </>
        } 
      </div>
    </div>
  );
});

export default HistoryTableItem;
