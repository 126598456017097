import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import MUIBadge from '@material-ui/core/Badge'

const useStyles = makeStyles(() =>
  createStyles({
    badge: {
      right: -7,
      top: 2,
    },
  })
);

interface Props {
  badgeContent?: number;
}

const Badge: FunctionComponent<Props> = memo(({ children, badgeContent }) => {
  const styles = useStyles();

  return (
    <MUIBadge 
      badgeContent={badgeContent}
      color="primary"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      classes={{
        badge: styles.badge
      }}
    >
      {children}
    </MUIBadge>
  );
});

export default Badge;
