import { createStyles, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { colors } from "../../../theme/colors";
import CheckedIcon from "./CheckedIcon";
import UncheckedIcon from "./UncheckedIcon";
import { themeCss } from "../../utils/theme";
import { AppContext } from "../../../state/AppContext";
import CheckedIconDark from "./CheckedIconDark";
import UncheckedIconDark from "./UncheckedIconDark";

interface Props {
  isChecked: boolean;
  label: string;
  isDisabled: boolean;
  onClick?: (e: any) => void;
  sublabel?: string;
}

const DropdownItem = ({
  isChecked,
  label,
  isDisabled,
  onClick,
  sublabel,
}: Props) => {
  const { theme } = useContext(AppContext);

  const useStyles = makeStyles(() =>
    createStyles({
      container: {
        marginBottom: 13,
        cursor: "pointer",
      },
      label: {
        color: isDisabled
          ? colors.disabledGray
          : colors[themeCss(theme)].textColor,
        fontFamily: "Raleway",
        fontWeight: 600,
        fontSize: 16,
        paddingLeft: 10,
      },
      sublabel: {
        color: isDisabled
          ? colors.disabledGray
          : colors[themeCss(theme)].textColor,
        fontFamily: "Raleway",
        fontWeight: 500,
        fontSize: 11,
      },
      unclickable: {
        pointerEvents: "none",
      },
    })
  );

  const styles = useStyles();

  const getIcon = () => {
    if (theme === "light") {
      return isChecked ? <CheckedIcon /> : <UncheckedIcon />;
    } else {
      return isChecked ? (
        <CheckedIconDark />
      ) : (
        <UncheckedIconDark />
      );
    }
  };

  return (
    <div
      className={
        isDisabled
          ? `${styles.container} ${styles.unclickable}`
          : styles.container
      }
      onClick={onClick}
    >
      {getIcon()}
      <span className={styles.label}>{label}</span>{" "}
      <span className={styles.sublabel}>{sublabel && sublabel}</span>
    </div>
  );
};

export default DropdownItem;
