import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })
);


interface Props {}

const FormContainer: FunctionComponent<Props> = memo(({ children }) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      {children}
    </div>
  );
});

export default FormContainer;
