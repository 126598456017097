import React, {FunctionComponent, useState, useEffect} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import PatientCaseHeader from './components/PatientCaseHeader';
import PatientCaseBody from './components/PatientCaseBody';
import CreateCaseFlow from './components/CreateCaseFlow';
import PatientSearch from './components/PatientSearch';
import { matchName } from '../../lib/utils/match-name';
import { CircularProgress } from '../../lib/components';
import PatientsEmpty from './components/PatientsEmpty';
import { colors } from '../../theme/colors';
import PatientCaseAlert from "./components/PatientCaseAlert";
import {Case} from "../../lib/interfaces/case.interface";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexFlow: 'column',
      maxWidth: 525,
      width: '100%',
      paddingBotton: 40,
      position: 'relative',
      backgroundColor: colors.white,
      borderRight: '1px solid rgba(158, 162, 165, 0.5)',
      boxShadow: '2px 0px 6px 0px rgba(0, 0, 0, 0.15)',
      clipPath: 'inset(0px -6px 0px 0px)',
      zIndex: 1,
    },
    patientContainer: {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    }
  })
);

interface Props {
  setSelectedCaseId: (value: string) => void;
  cases: Case[]|null;
}

const PatientsSidebar: FunctionComponent<Props> = ({ setSelectedCaseId, cases }) => {
  const styles = useStyles();

  const [patientsWrap, setPatientsWrap] = useState(cases);

  useEffect(() => {
    setPatientsWrap(cases);
  }, [cases]);

  const onSearch = (value: string) => {
    const filteredCases = cases && cases.filter((patientCase: Case) => {
      if (patientCase) {
        const firstNameMatch = matchName(patientCase.patientFirstName, value);
        const lastNameMatch = matchName(patientCase.patientLastName, value);
        const patientIdMatch = matchName(patientCase.patientId, value);
        return firstNameMatch || lastNameMatch || patientIdMatch;
      }
      return false;
    });
    setPatientsWrap(filteredCases);
  }

  return (
    <div className={styles.container}>
      <PatientCaseHeader />
      <PatientCaseAlert />
      <PatientSearch onSearch={onSearch} />
        {
          !cases ?
            <CircularProgress />
          :
            <>
              {
                (patientsWrap && patientsWrap.length) ?
                  <PatientCaseBody patients={patientsWrap} setSelectedCaseId={setSelectedCaseId} />
                :
                  <PatientsEmpty />
              }
            </>
        }
        <CreateCaseFlow />
    </div>
  );
};

export default PatientsSidebar;
