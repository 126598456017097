import React, {memo, FunctionComponent} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CompanyLogo, FormContainer } from '../../../lib/components';
import ResetPasswordForm from './components/ResetPasswordForm';
import { authService } from '../../../services';
import { take } from 'rxjs/operators';
import { NavRoute } from '../../../lib/constants/nav-route.constant';
import { ResetPasswordFormValues } from './interfaces/form-values.interface';

const ResetPassword: FunctionComponent = memo(() => {
  const history = useHistory();
  const location = useLocation<{email: string}>();

  const submitForm = ({code, pass}: ResetPasswordFormValues, onComplete: () => void): void => {
    const email = location.state.email;
    authService.resetPassword(email, code, pass).pipe(
      take(1)
    ).subscribe({
      next: () => {
        history.push(NavRoute.AUTH.SIGN_IN);
        onComplete();
      },
      error: () => onComplete()
    })
  }

  return (
    <FormContainer>
      <CompanyLogo />
      <ResetPasswordForm submitForm={submitForm} />
    </FormContainer>
  );
});

export default ResetPassword;
