import React, { FunctionComponent } from 'react';

interface Props {
  width: number;
  height: number;
  color: string;
}

export const ActivityMediumIcon: FunctionComponent<Props> = ({width, height, color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none">
      <path
        d="M10.25 30L10.8998 29.1337C11.9385 27.7488 12.5 26.0645 12.5 24.3334V24.0002M20.75 30L20.2284 28.841C19.0886 26.3083 17.4338 24.0407 15.3694 22.1827L13.5 20.5002L14.5 16.5001L15.5 12.5M8 22.0002L9.73852 18.5232C10.8905 16.2193 12.6557 14.2778 14.84 12.9125L15.5 12.5M15.5 12.5L17.9102 16.2877C18.6254 17.4116 19.6099 18.3391 20.7745 18.9861L23.5 20.5002"
        stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M21 5.5C21 6.42826 20.6313 7.3185 19.9749 7.97487C19.3185 8.63125 18.4283 9 17.5 9C16.5717 9 15.6815 8.63125 15.0251 7.97487C14.3687 7.3185 14 6.42826 14 5.5C14 4.57174 14.3687 3.6815 15.0251 3.02513C15.6815 2.36875 16.5717 2 17.5 2C18.4283 2 19.3185 2.36875 19.9749 3.02513C20.6313 3.6815 21 4.57174 21 5.5Z"
        stroke={color} strokeWidth="2.5"/>
    </svg>
  );
};


