import React, {memo, FunctionComponent, ChangeEvent, FocusEvent} from 'react';
import { makeStyles, createStyles, Theme, InputProps } from '@material-ui/core';
import MUITextField from '@material-ui/core/TextField'
import { colors } from '../../../theme/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 280,
      'label + &': {
        marginTop: theme.spacing(3),
      },
      '& input:valid': {
        backgroundColor: colors.white
      },
      '& input:invalid': {
        backgroundColor: colors.white
      },
      '& input:valid:focus': {
        backgroundColor: colors.white
      },
      '& input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& input[type="number"]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& input[type="number"]': {
        '-moz-appearance': 'textfield',
      },
    },
  })
);

interface Props {
  value: string;
  onChange: (value: string) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  label?: string;
  type?: string;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  InputProps?: Partial<InputProps>;
  className?: any;
}

const TextField: FunctionComponent<Props> = memo(({ 
  value, onChange, label, placeholder, onBlur,
  type, error, disabled, required, helperText, InputProps,
  className
}) => {
  const styles = useStyles();
  const valueChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }

  return (
    <MUITextField
      classes={{
        root: `${styles.root} ${className}`,
      }}
      variant="outlined"
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={valueChange}
      onBlur={onBlur}
      type={type}
      error={error}
      disabled={disabled}
      required={required}
      helperText={helperText}
      InputProps={InputProps}
      InputLabelProps={{
        style: {
          color: colors.placeholderText
        }
      }}
    />
  );
});

export default TextField;
