import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import { colors } from "../../../theme/colors";
import { createStyles, makeStyles } from "@material-ui/core";
import OutsideClickHandler from "react-outside-click-handler";
import { AggregationDropdown } from "../../interfaces/metric-history.interface";
import BottomArrow from "../../icons/BottomArrow";
import { AppContext } from "../../../state/AppContext";

interface Props {
  setFilterIsChanged: (value: boolean) => void;
  selectedAggregation: AggregationDropdown;
  setSelectedAggregation: Dispatch<SetStateAction<AggregationDropdown>>;
}

interface DropDownProps {
  onClick?: (e: any) => void;
  value?: string
}

const dropDownItems = [
  {key: 'none', value: 'By minutes'},
  {key: 'hour', value: 'Hourly'},
  {key: 'day', value: 'Daily'},
]

const DropdownItem = ({onClick, value}: DropDownProps) => {
  const styles = useStyles();
  const { theme } = useContext(AppContext);
  return (
    <div
      className={styles.container}
      onClick={onClick}
    >
      <span className={`${styles.label} ${styles[`label--${theme}`]}`}>{value}</span>
    </div>
  )
}

const HistoryChartAggregationDropdown = ({ setFilterIsChanged, selectedAggregation, setSelectedAggregation }: Props) => {
  const styles = useStyles();
  const { theme } = useContext(AppContext);
  const [isActive, setIsActive] = useState(false);

  const onItemClick = (data: AggregationDropdown) => {
    setSelectedAggregation(data);
    setFilterIsChanged(true);
    setIsActive(false);
  };

  return (
    <div className={`${styles.filterSelectWrapper}`}>
      <div
        className={`${styles.filterSelect} ${styles.metric} ${styles[`filterSelect--${theme}`]}`}
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <div className={styles[`textContainer--${theme}`]}>
          {selectedAggregation.value}
        </div>
        <BottomArrow />
      </div>
      {isActive && (
        <div className={`${styles.dropdownMenu} ${styles[`dropdownMenu--${theme}`]}`}>
          <OutsideClickHandler
            onOutsideClick={() => {
              setIsActive(false);
            }}
          >
            {dropDownItems.map((item, index) => {
              return <DropdownItem
                key={index}
                onClick={() => onItemClick(item)}
                value={item.value}
              />
            })}
          </OutsideClickHandler>
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    filterSelect: {
      height: 40,
      borderRadius: 7,
      fontSize: 16,
      fontWeight: 600,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "filterSelect--light": {
      border: `1px solid ${colors.lightTheme.chartFilterSelectBorder}`,
      backgroundColor: colors.lightTheme.chartFilterSelectBackground,
    },
    "filterSelect--dark": {
      border: `1px solid ${colors.blackTheme.chartFilterSelectBorder}`,
      backgroundColor: colors.blackTheme.chartFilterSelectBackground,
    },
    "textContainer--light": {
      color: colors.lightTheme.toggleText,
    },
    "textContainer--dark": {
      color: colors.blackTheme.toggleText,
    },
    metric: {
      width: 234,
      paddingLeft: 12,
      paddingRight: 8,
    },
    textContainer: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    filterSelectWrapper: {
      position: "relative",
      cursor: "pointer",
    },
    dropdownMenu: {
      position: "absolute",
      top: "100%",
      marginTop: 5,
      left: "50%",
      transform: "translateX(-50%)",
      width: 234,
      zIndex: 9999,
      borderRadius: 10,
      paddingTop: 10,
      paddingRight: 10,
      paddingLeft: 10,
    },
    "dropdownMenu--light": {
      border: `1px solid ${colors.lightTheme.chartFilterSelectBorder}`,
      backgroundColor: colors.lightTheme.chartFilterSelectBackground,
    },
    "dropdownMenu--dark": {
      border: `1px solid ${colors.blackTheme.chartFilterSelectBorder}`,
      backgroundColor: colors.blackTheme.chartFilterSelectBackground,
    },
    container: {
      marginBottom: 13,
      cursor: "pointer",
    },
    label: {
      color: colors.black,
      fontFamily: "Raleway",
      fontWeight: 600,
      fontSize: 16,
      paddingLeft: 10,
    },
    "label--light": {
      color: colors.lightTheme.toggleText,
    },
    "label--dark": {
      color: colors.blackTheme.toggleText,
    },
  })
);

export default HistoryChartAggregationDropdown;
