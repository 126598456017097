import { useState, useEffect } from 'react';
import { errorService } from '../../services';
import { debounceTime, tap, switchMap } from 'rxjs/operators';
import { timer } from 'rxjs';

const useError = () => {
  const [error, setError]: [any, (arg: any) => void] = useState(null);

  useEffect(() => {
    const sub = errorService.state.pipe(
      debounceTime(100),
      tap(setError),
      switchMap(() => timer(3000).pipe(
        tap(() => setError(null))
      ))
    ).subscribe()

    return () => sub.unsubscribe();
    /* eslint-disable */
  }, []);

  return [error, setError];
};

export default useError;
