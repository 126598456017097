import {createContext, Dispatch, SetStateAction} from 'react';
import {SelectedPatient} from "../lib/interfaces/selected-patient.interface";
import {Case} from "../lib/interfaces/case.interface";
import {Theme} from "../lib/interfaces/theme.type";

type AppContextType = {
  //deprecated - was used for the unfinished dark design
  selectedPatient: SelectedPatient | null;
  //deprecated - was used for the unfinished dark design
  setSelectedPatient: Dispatch<SetStateAction<SelectedPatient | null>>;

  isFullScreen: boolean;
  setIsFullScreen: Dispatch<SetStateAction<boolean>>;

  cases: Case[]|null;
  setCases: Dispatch<SetStateAction<Case[]|null>>;

  theme: Theme;
  setTheme: Dispatch<SetStateAction<Theme>>;
}

export const AppContext = createContext<AppContextType>({
  selectedPatient: null,
  setSelectedPatient: () => {},
  isFullScreen: false,
  setIsFullScreen: () => {},
  cases: null,
  setCases: () => {},
  theme: 'light',
  setTheme: () => {},
});
