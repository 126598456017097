export type UnitsExtension = typeof MetricExtension | typeof ImperialExtension;

export type UnitKey = 'bs' | // battery status
  'bt' | // body temperature
  'hr' | // heart rate
  'hrv' | // heart rate variability
  'spo2' | // oxygen saturation level
  'rr' | // respiration rate
  'ce' | // chest expansion
  'p' | // position
  'af' | // activity level (true/false)
  'af2' | // activity level value
  'P_CSV' // position grade

export const MetricExtension: Record<UnitKey, string> = {
  'bs': '%',
  'bt': `${String.fromCharCode(0x00B0)}C`,
  'hr': 'bpm',
  'hrv': 'ms',
  'spo2': '%',
  'rr': 'bpm',
  'ce': 'cm',
  'p': '',
  'af': '',
  'af2': '',
  'P_CSV': String.fromCharCode(0x00B0)
}

export const ImperialExtension: Record<UnitKey, string> = {
  'bs': '%',
  'bt': `${String.fromCharCode(0x00B0)}F`,
  'hr': 'bpm',
  'hrv': 'ms',
  'spo2': '%',
  'rr': 'bpm',
  'ce': 'in',
  'p': '',
  'af': '',
  'af2': '',
  'P_CSV': String.fromCharCode(0x00B0)
}


export const MetricExtensionHistory: {[key: string]: string} = {
  'body-temperature': `${String.fromCharCode(0x00B0)}C`,
  'heart-rate': 'bpm',
  'oxygen-saturation-level': '%',
  'respiration-rate': 'bpm',
  'chest-expansion': 'cm',
}

export const ImperialExtensionHistory: {[key: string]: string} = {
  'body-temperature': `${String.fromCharCode(0x00B0)}F`,
  'heart-rate': 'bpm',
  'oxygen-saturation-level': '%',
  'respiration-rate': 'bpm',
  'chest-expansion': 'in',
}