import React, { FunctionComponent } from 'react';

interface Props {
  width: number;
  height: number;
}

export const BellRingIcon: FunctionComponent<Props> = ({width, height}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M12 5.46461V3.09961M12 5.46461C13.3851 5.51781 14.6952 6.10771 15.6531 7.10953C16.6111 8.11135 17.1418 9.44652 17.133 10.8326V12.6326C17.133 15.0186 19 15.6146 19 16.8076C19 17.4006 19 18.0006 18.462 18.0006H5.538C5 18.0006 5 17.4006 5 16.8076C5 15.6146 6.867 15.0186 6.867 12.6326V10.8326C6.85819 9.44652 7.38889 8.11135 8.34685 7.10953C9.30481 6.10771 10.6149 5.51781 12 5.46461ZM6 5.00061L5 4.00061M4 9.00061H3M18 5.00061L19 4.00061M20 9.00061H21M8.54 18.0006C8.6319 18.8541 9.03604 19.6435 9.67472 20.2172C10.3134 20.7908 11.1416 21.1081 12 21.1081C12.8584 21.1081 13.6866 20.7908 14.3253 20.2172C14.964 19.6435 15.3681 18.8541 15.46 18.0006H8.54Z"
        stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
