import { createMuiTheme } from '@material-ui/core';
import { colors } from './colors';

export const theme = createMuiTheme({
  typography: {
    fontFamily: 'Raleway, sans-serif',
    allVariants: {
      fontVariantNumeric: 'lining-nums proportional-nums',
    },
  },
  palette: {
    primary: {
      main: colors.blue,
      light: colors.lightBlue,
      contrastText: colors.white
    },
    secondary: {
      main: colors.backgroundGray,
      contrastText: colors.black
    },
    error: {
      main: colors.red,
      light: colors.lightRed
    }
  }
});
