import React, { FunctionComponent } from 'react';

interface Props {
  width: number;
  height: number;
}

export const PositionHorizontalIcon: FunctionComponent<Props> = ({width, height}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 30" fill="none">
      <path
        d="M1.74414 7.85721V24.1429M28.2558 24.1429V20.9441C28.2558 18.2366 28.2558 16.8822 27.8846 15.7951C27.5556 14.8324 27.0189 13.958 26.3144 13.2369C25.6098 12.5158 24.7555 11.9666 23.8151 11.6301C22.7546 11.2501 21.4317 11.2501 18.7871 11.2501C17.7293 11.2501 17.2004 11.2501 16.7749 11.4021C16.3991 11.5368 16.0577 11.7564 15.7761 12.0447C15.4945 12.333 15.28 12.6825 15.1484 13.0673C15 13.5029 15 14.0458 15 15.1274V21.4286M1.74414 21.4286H28.2558"
        stroke="#EF7A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M11.6858 14.643C11.6858 15.5428 11.3366 16.4058 10.7151 17.0421C10.0936 17.6784 9.25073 18.0358 8.37182 18.0358C7.4929 18.0358 6.64998 17.6784 6.0285 17.0421C5.40701 16.4058 5.05786 15.5428 5.05786 14.643C5.05786 13.7431 5.40701 12.8802 6.0285 12.2439C6.64998 11.6076 7.4929 11.2501 8.37182 11.2501C9.25073 11.2501 10.0936 11.6076 10.7151 12.2439C11.3366 12.8802 11.6858 13.7431 11.6858 14.643Z"
        stroke="#EF7A04" strokeWidth="2"/>
    </svg>
  );
};


