import { DATE_FORMAT } from './../../lib/constants/date-format.constant';
import { AggregateBy } from './../../lib/constants/aggregation.enum';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import {
  LastHourMetrics,
  MetricHistory, MetricHistoryItem, MetricOptions, MetricResponse, UIMetricOptions
} from '../../lib/interfaces/metric-history.interface';
import { Api } from '../../lib/api/api';
import { API_ROUTES } from '../../lib/constants/api-routes.constant';
import { catchError, expand, takeWhile, reduce, map } from 'rxjs/operators';
import errorService from '../errorService';
import { AggregationState } from '../../lib/interfaces/aggregation-state.interface';
import moment from 'moment';

const initialAggregationState = {
  aggregateBy: AggregateBy.HOURS,
  from: '',
  to: ''
}

const MetricService = () => {
  const aggregationState$ = new BehaviorSubject<AggregationState>(initialAggregationState);

  const setAggregation = (aggregateBy: AggregateBy|string, from: string, to: string) => {
    aggregationState$.next({aggregateBy, from, to});
  }

  const resetAggregation = (aggregateBy?: AggregateBy|string) => {
    aggregationState$.next({ 
      ...initialAggregationState,
      aggregateBy: aggregateBy || ''
    });
  }

  const getHistoryRequest = (
    fieldName: string, patientId: string,
    caseId: string, options?: Partial<MetricOptions>
  ): Observable<MetricHistory> => {
    const urlParams = { ...options };
    return Api.get(
      API_ROUTES.METRIC.GET_HISTORY(fieldName, patientId, caseId),
      urlParams ? {urlParams} : undefined
    ).pipe(
      catchError(e => {
        const error = e.message || e.error || e;
        errorService.addError(error);
        return throwError(e);
      })
    );
  };

  const getLastHourMetrics = (caseId: string, hoursOffset: number): Observable<LastHourMetrics> => {
    return Api.get(
      API_ROUTES.METRIC.LAST_HOUR_METRICS(caseId),
{
        urlParams: {
          timezoneOffset: hoursOffset.toString()
        }
      }
    ).pipe(
      catchError(e => {
        const error = e.message || e.error || e;
        errorService.addError(error);
        return throwError(e);
      })
    );
  }

  const getAggregatedHistory = (
    caseId: string, from: string, to: string, metrics: string, timezoneOffset: number, aggregationType?: string, options?: Partial<MetricOptions>
  ): Observable<MetricResponse> => {
    const urlParams = {
      ...options,
      from,
      to,
      timezoneOffset: timezoneOffset.toString(),
      metrics: metrics,
      aggregationType: aggregationType ?? 'hour',
      sort: 'ASC'
    };
    return Api.get(
      API_ROUTES.METRIC.GET_AGGREGATED_HISTORY(caseId),
      urlParams ? {urlParams} : undefined
    )
  }

  const getNonAggregatedHistory = (
    caseId: string, from: string, to: string, metrics: string, timezoneOffset: number, options?: Partial<MetricOptions>
  ): Observable<MetricResponse> => {
    const urlParams = {
      ...options,
      from,
      to,
      timezoneOffset: timezoneOffset.toString(),
      metrics: metrics,
      sort: 'ASC'
    };
    return Api.get(
      API_ROUTES.METRIC.GET_NON_AGGREGATED_HISTORY(caseId),
      urlParams ? {urlParams} : undefined
    )
  }

  const getAllHistory = (
    fieldName: string, patientId: string,
    caseId: string, options: Partial<UIMetricOptions>
  ): Observable<MetricHistoryItem[]> => {
    return getHistoryRequest(fieldName, patientId, caseId, options).pipe(
      expand((value: MetricHistory) => {
        if (value.lastEvaluatedKey) {
          const evaluationOptions = {
            'lastEvaluatedKey[id].s': value.lastEvaluatedKey.id.s || '',
            'lastEvaluatedKey[timestamp].n': value.lastEvaluatedKey.timestamp.n || '',
            ...options
          };

          return getHistoryRequest(fieldName, patientId, caseId, evaluationOptions);
        }
        return of(value);
      }),
      takeWhile((metrics => Boolean(metrics.lastEvaluatedKey)), true),
      reduce((acc, val) => {
        return acc.concat(val.results)
      }, [] as MetricHistoryItem[]),
      map((metrics: MetricHistoryItem[]) => {
        return metrics.length ? 
          metrics.map(item => ({
            ...item,
            timestamp: moment(item.timestamp).format(DATE_FORMAT),
            aggregatingTime: moment(item.aggregatingTime).format(DATE_FORMAT)
          })) 
        :
          metrics;
      })
    );
  };

  return {
    aggregationState: aggregationState$,
    setAggregation,
    resetAggregation,
    getAllHistory,
    getAggregatedHistory,
    getNonAggregatedHistory,
    getLastHourMetrics
  };
};

const singleton = MetricService();
export default Object.freeze(singleton);
