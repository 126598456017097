import React, {memo, FunctionComponent, useState, useEffect, useContext} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import PatientsSidebar from '../PatientsSidebar';
import PatientMetrics from '../PatientMetrics';
import {caseService} from "../../services";
import {AppContext} from "../../state/AppContext";
import useRequestUserSettings from "../../hooks/useRequestUserSettings";
import useUserSettings from "../../hooks/useUserSettings";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      height: '100vh',
      display: 'flex'
    }
  })
);

const Main: FunctionComponent = memo(() => {
  const { isFullScreen, cases, setCases } = useContext(AppContext);
  const styles = useStyles();
  const [selectedCaseId, setSelectedCaseId] = useState<string|null>(null);
  useRequestUserSettings();
  const userSettings = useUserSettings();
  const selectedCase = cases?.find(c => c.caseId === selectedCaseId) || null;

  // Get cases
  useEffect(() => {
    const sub = caseService.getCasesList().subscribe(setCases)

    return () => sub.unsubscribe();
  }, [userSettings?.measurementFormat]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      {isFullScreen || <PatientsSidebar setSelectedCaseId={setSelectedCaseId} cases={cases}/>}
      <PatientMetrics patientCase={selectedCase}/>
    </div>
  );
});

export default Main;
