import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Typography from '../Typography';
import PasswordValidationIcon from '../PasswordValidationIcon';

const useStyles = makeStyles(() =>
  createStyles({
    passwordVerification: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      width: '100%',
      marginTop: 7
    },
    passwordVerificationItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    passwordVerificationItemText: {
      paddingRight: 20,
      opacity: 0.5
    },
    verified: {
      opacity: 0.9,
    }
  })
);


interface Props {
  validation: {
    isLength: boolean;
    isLetter: boolean;
    isNumber: boolean;
  }
}

const PasswordCheck: FunctionComponent<Props> = memo(({ validation }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.passwordVerification}>
      <div className={styles.passwordVerificationItem}>
        <PasswordValidationIcon value={validation.isLength} />
        <Typography className={`${styles.passwordVerificationItemText} ${validation.isLength && styles.verified}`}>
          {t('validation.passwordLength')}
        </Typography>
      </div>

      <div className={styles.passwordVerificationItem}>
        <PasswordValidationIcon value={validation.isLetter} />
        <Typography className={`${styles.passwordVerificationItemText} ${validation.isLetter && styles.verified}`}>
          {t('validation.passwordLetters')}
        </Typography>
      </div>

      <div className={styles.passwordVerificationItem}>
        <PasswordValidationIcon value={validation.isNumber} />
        <Typography className={`${styles.passwordVerificationItemText} ${validation.isNumber && styles.verified}`}>
          {t('validation.passwordDigits')}
        </Typography>
      </div>
    </div>
  );
});

export default PasswordCheck;
