import { Limit } from '../interfaces/limit.interface';

export const ValueLimit: {[key: string]: Limit} = {
  'bt': {min: null, max: null},
  'bs': {min: null, max: null},
  'ce': {min: null, max: null},
  'hr': {min: null, max: null},
  'rr': {min: null, max: null},

  // 'bt': {min: 36, max: 37},
  // 'bs': {min: 10, max: null},
  // 'ce': {min: 3, max: 9},
  // 'HR': {min: 50, max: 120},
  // 'rr': {min: 12, max: 25},
};
