import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Paper } from '../../../../lib/components';
import EmptyMetrics from '../../../../lib/components/EmptyMetrics';
import { MetricsView } from '../../../../lib/interfaces/metric-history.interface';
import HistoryTableHeader from '../HistoryTableHeader';
import HistoryTableItem from '../HistoryTableItem';
import Skeleton from '@material-ui/lab/Skeleton';
import { useAggreationState, useLoader } from '../../../../hooks';
import { LoaderName } from '../../../../lib/constants/loader-name.constant';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
      borderRadius: 8,
      overflowY: 'auto',
      height: '100%'
    },
    header: {
      width: '100'
    },
    body: {
      overflowY: 'auto',
      width: '100%'
    }
  })
);

interface Props {
  metrics: MetricsView[]|null;
  fieldName: string|null;
  chosenElement: number;
  setTableView: (value: boolean) => void;
}

const HistroryTable: FunctionComponent<Props> = memo(({ metrics, fieldName, chosenElement, setTableView }) => {
  const styles = useStyles();
  const loader = useLoader();
  const aggregationState = useAggreationState();

  return (
    <Paper className={styles.container}>
      {
        loader && loader.id === LoaderName.METRICS ? 
          <Skeleton height="100%" width="100%" variant="rect" animation="wave" />
        :
          <>
            {
              fieldName && metrics && metrics.length ?
                <div className={styles.body}>
                  <HistoryTableHeader fieldName={fieldName} aggregateBy={aggregationState.aggregateBy} chosenElement={chosenElement} setTableView={setTableView} />

                  {
                    metrics.map(item => 
                      <HistoryTableItem 
                        fieldName={fieldName}
                        aggregateBy={aggregationState.aggregateBy}
                        historyItem={item}
                        key={item.aggregatedTime}
                      />)
                  }

                </div>
              :
                <EmptyMetrics />
                
            }
          </>
      }
    </Paper>
  );
});

export default HistroryTable;
