import React from "react";

function UncheckedIconDark() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="1" width="15" height="15" rx="1.5" fill="#3E3E3E" />
      <rect x="0.5" y="1" width="15" height="15" rx="1.5" stroke="#9C9A9A" />
      <path
        d="M11.4629 4.77702L6.6805 10.0621L4.55721 7.71762C4.1946 7.31724 3.63054 7.31724 3.27196 7.71762C2.90935 8.11801 2.90935 8.74083 3.27196 9.13677L6.04795 12.2019C6.22925 12.4021 6.45488 12.5 6.6805 12.5C6.90612 12.5 7.13175 12.4021 7.31305 12.2019L12.728 6.22285C13.0907 5.82247 13.0907 5.19965 12.728 4.80371C12.3896 4.40333 11.8255 4.40333 11.4629 4.77702Z"
        fill="#404040"
      />
    </svg>
  );
}

export default UncheckedIconDark;
