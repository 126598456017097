import React, {memo, FunctionComponent} from 'react';
import MUICircularProgress from '@material-ui/core/CircularProgress'
import { createStyles, makeStyles } from '@material-ui/core';


const useStyles = makeStyles(() =>
  createStyles({
    root: {}
  })
);

interface Props {
  size?: number;
  value?: number;
}

const CircularProgress: FunctionComponent<Props> = memo(({ size, value }) => {
  const styles = useStyles();
  
  return (
    <div className={styles.root}>
      <MUICircularProgress 
        size={size}
        value={value}
      />
    </div>
  );
});

export default CircularProgress;
