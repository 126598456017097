import { BehaviorSubject } from 'rxjs';

function UamService() {
  const state$ = new BehaviorSubject<{[id: string]: {previousValue: boolean}|null}>({});

  const _updateStorage = (id: string, value: boolean|null) => {
    state$.next({
      ...state$.value,
      [id]: value !== null ? {previousValue: value} : null
    });
  };

  const addToStorage = (id: string, value: boolean): void => {
    _updateStorage(id, value);
  };

  const removeFromStorage = (id: string): void => {
    _updateStorage(id, null)
  };


  return {
    state: state$,
    addToStorage,
    removeFromStorage
  }
}

const singleton = UamService();
export default Object.freeze(singleton);
