const {
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_APP_CLIENT_ID
} = process.env;

export const AWSConfig = {
  Auth: {
    region: REACT_APP_COGNITO_REGION,
    userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
    // userClientId: REACT_APP_COGNITO_APP_CLIENT_ID,
    // userSecretId: REACT_APP_COGNITO_USER_SECRET_ID,
    userPoolWebClientId: REACT_APP_COGNITO_APP_CLIENT_ID,
    mandatorySignIn: true,
    // oauth: {
    //   domain: REACT_APP_COGNITO_DOMAIN,
    //   scope: ['email', 'openid', 'profile'],
    //   redirectSignIn: `${HOST}/auth`,
    //   redirectSignOut: `${HOST}/logout`,
    //   responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    // }
  }
};
