
import {useContext, useEffect} from "react";
import { authService } from "../../services";
import {AppContext} from "../../state/AppContext";

const useRequestUserSettings = () => {
  const { setTheme } = useContext(AppContext);
  useEffect(() => {
    const sub = authService.getUserSettings().subscribe({
      next: userSettings => {
        setTheme(userSettings.theme);
      },
      error: err => {
        console.log(err);
      }
    });

    return () => sub.unsubscribe();
    // eslint-disable-next-line
  }, []);
};

export default useRequestUserSettings;
