import {MetricName} from "../interfaces/metric-history.interface";
import {getPosition} from "./get-position";
import {getActivityFactor} from "./get-activity-factor";

// Base function to handle common logic
function getBaseMetricValue(metricName: MetricName, value: number): string {
  if (metricName === undefined) {
    return '';
  }
  const convertors: Record<MetricName, (value: number) => string> = {
    HeartRate: (value: number) => value.toFixed(0),
    BodyTemperature: (value: number) => value.toFixed(2),
    ChestExpansion: (value: number) => value.toFixed(2),
    RespirationRate: (value: number) => value.toFixed(0),
    OxygenSaturationLevel: (value: number) => value.toFixed(0),
  }
  return convertors[metricName](value);
}

// getMetricValue now calls the base function and adds additional logic
export function getMetricValue(metricName: MetricName, value: number, t: (key: string) => string): string {
  if (metricName === 'Position') {
    return t(getPosition(value));
  }
  if (metricName === 'ActivityIntensity') {
    return t(getActivityFactor(value));
  }
  return getBaseMetricValue(metricName, value);
}

// getNumericMetricValue now simply calls the base function
export function getNumericMetricValue(metricName: MetricName, value: number): string {
  return getBaseMetricValue(metricName, value);
}
