import React, { FunctionComponent } from "react";
import { makeStyles, createStyles } from "@material-ui/core";

interface Props {
  isSelected: boolean;
  label: string;
  onSelect: () => void;
}

const RadioButton: FunctionComponent<Props> = ({
  isSelected,
  label,
  onSelect,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.radioButtonContainer} onClick={onSelect}>
      <div className={styles.outerCircle}>
        {isSelected && <div className={styles.innerCircle} />}
      </div>
      <label className={styles.label}>{label}</label>
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    radioButtonContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: 10,
      cursor: "pointer",
    },
    outerCircle: {
      height: 20,
      width: 20,
      borderRadius: "50%",
      backgroundColor: "#E4E4E4",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 10,
    },
    innerCircle: {
      height: 10,
      width: 10,
      borderRadius: "50%",
      backgroundColor: "#A0A0A0",
    },
    label: {
      fontSize: 16,
    },
  })
);

export default RadioButton;
