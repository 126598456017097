import React, {memo, FunctionComponent} from 'react';
import { ReactComponent as TEKLogo } from '../../../theme/tek-logo.svg';

const CompanyLogo: FunctionComponent = memo(() => {
  return (
    <TEKLogo style={{width: 130, height: 45}}  />
  );
});

export default CompanyLogo;
