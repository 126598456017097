import React, { memo, FunctionComponent, useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { MetricsView } from "../../../../lib/interfaces/metric-history.interface";
import { MeasurementKeys } from "../../../../lib/interfaces/measurement-keys.type";
import { Button, Typography } from "../../../../lib/components";
import { AggregateBy } from "../../../../lib/constants/aggregation.enum";
import {
  MetricExtension,
  ImperialExtension, UnitKey,
} from "../../../../lib/constants/value-extension.constant";
import useAggreationState from "../../../../hooks/useAggreationState";
import { AggregationState } from "../../../../lib/interfaces/aggregation-state.interface";
import { isSimplifiedHistory } from "../../../../lib/utils/is-simplified-history";
import useRequestUserSettings from "../../../../hooks/useRequestUserSettings";
import useUserSettings from "../../../../hooks/useUserSettings";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      paddingTop: 25,
    },
    container: {
      width: "100%",
      height: "65%",
    },
    oneGraphContainer: {
      height: "100%",
    },
    chartHead: {
      paddingLeft: 30,
      paddingRight: 10,
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    button: {
      marginRight: 16,
      width: 104,
      padding: 15,
    },
    input: {
      padding: 10,
    },
    tootlip: {
      borderRadius: "",
    },
    referenceArea: {
      backgroundColor: theme.palette.primary.main,
    },
    graphLegend: {
      display: "flex",
      alignItems: "center",
    },
    graphLegendItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 10,
      "&:last-child": {
        marginRight: 30,
      },
    },
    text: {
      margin: 0,
    },
    line: {
      width: 36,
      height: 2,
      marginBottom: 7,
    },
  })
);

interface Props {
  metrics: MetricsView[];
  hrMetrics: MetricsView[];
  keyName: MeasurementKeys;
  date?: string;
  hrField: boolean;
  setGraphClick: (value: boolean) => void;
  setButtonDisable: (value: boolean) => void;
  setTableView: (value: boolean) => void;
  tableView: boolean;
}

const notFilteredByHour = (aggregationState: AggregationState) => {
  return aggregationState.aggregateBy !== AggregateBy.MINUTES;
};

/*const MinBar = ({ fill, x, y, width, height }: RectangleProps) => {
  return <rect x={x} y={y} width={width} height={height && 3} fill={fill} />;
};*/

/*const AvgBar = ({ fill, x, y, width, height }: RectangleProps) => {
  const horizontalX = (width && x) && (x + width / 2) - 1;
  const newWidth = width && width / 2;
  const newX = x && newWidth && x + (newWidth / 2);
  return (
    <g>
      <rect x={newX} y={y} width={newWidth} height={height && 3} fill={fill} />
      <rect x={horizontalX} y={y} width={width && 2} height={height && (height)} fill={fill} opacity={0.5} />
    </g>
  )
};*/

/*const MaxBar = ({ fill, x, y, width, height }: RectangleProps) => {
  const horizontalX = (width && x) && (x + width / 2) - 1;
  return (
    <g>
      <rect x={x} y={y} width={width} height={height && 3} fill={fill} />
      <rect x={horizontalX} y={y && y + 3} width={width && 2} height={height && (height - 3)} fill="#9EA2A5" opacity={0.5} />
    </g>
  )
};*/

const DisplayHistoryChart: FunctionComponent<Props> = memo(
  ({
    keyName,
    hrField,
    tableView,
    setTableView,
  }) => {
    const styles = useStyles();
    const { t } = useTranslation();

    const aggregationState = useAggreationState();

    useRequestUserSettings();
    const userSettings = useUserSettings();

    const [measurementFormat, setMeasurementFormat] = useState(
      userSettings?.measurementFormat
    );

    const extension = (key: UnitKey) => {
      return measurementFormat === "metric"
        ? MetricExtension[key]
        : ImperialExtension[key];
    }

    useEffect(() => {
      setMeasurementFormat(userSettings?.measurementFormat);
      // eslint-disable-next-line
    }, [userSettings?.measurementFormat]);

    const renderGraphLegend = (dataKey: string) => {
      const graphLegendText: any = [
        {
          text: "min",
          color: dataKey === "hr" || dataKey === "spo2" ? "#9BF39D" : "#1FA1E9",
        },
        {
          text: "avg",
          color:
            dataKey === "hr"
              ? "#F17176"
              : dataKey === "spo2"
              ? "#1FA1E9"
              : "#9EA2A5",
        },
        {
          text: "max",
          color: dataKey === "hr" || dataKey === "spo2" ? "#000000" : "#F17176",
        },
      ];

      return (
        <div className={styles.graphLegend}>
          {notFilteredByHour(aggregationState) && (
            <div className={styles.graphLegend}>
              {graphLegendText.map((item: any, index: number) => {
                return (
                  <div className={styles.graphLegendItem} key={index}>
                    <div
                      className={styles.line}
                      style={{ background: item.color }}
                    />
                    <p className={styles.text}>{item.text}</p>
                  </div>
                );
              })}
            </div>
          )}
          {!isSimplifiedHistory(keyName) && dataKey === "hr" && (
            <Button
              rootClass={styles.button}
              onClick={() => setTableView(!tableView)}
            >
              {t("button.toggleHistoryTable")}
            </Button>
          )}
        </div>
      );
    };

    const renderGraph = (dataKey: string) => {
      // const greenColorBlocks = ["spo2", "hr"];
      // const keyForDisableTooltip = ["p", "af2"];
      return (
        <div/>
      );
    };

    const renderHRGraphBlock = () => {
      const hrKey = "hr";
      return (
        <div className={styles.container}>
          <div className={styles.chartHead}>
            <Typography>
              {t(`healthData.${hrKey}`)}
              {MetricExtension[hrKey] && `, ${MetricExtension[hrKey]}`}
            </Typography>
            {!isSimplifiedHistory(keyName) && renderGraphLegend(hrKey)}
          </div>
          {renderGraph(hrKey)}
        </div>
      );
    };

    return (
      <div className={styles.mainContainer}>
        {!hrField && renderHRGraphBlock()}
        <div className={hrField ? styles.oneGraphContainer : styles.container}>
          <div className={styles.chartHead}>
            <Typography>
              {t(`healthData.${keyName}`)}
              {extension(keyName as UnitKey) && `, ${extension(keyName as UnitKey)}`}
            </Typography>
            {!isSimplifiedHistory(keyName) && renderGraphLegend(keyName)}
          </div>
          {renderGraph(keyName)}
        </div>
      </div>
    );
  }
);

export default DisplayHistoryChart;
