import { useState, useEffect } from 'react';
import { authService } from '../../services';

const useAuthState = () => {
  const [state, setState] = useState(null);

  useEffect(() => {
    const sub = authService.getStatus().subscribe(setState);
    return () => sub.unsubscribe();
  }, []);

  return state;
};

export default useAuthState;
