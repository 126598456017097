import React, { memo, FunctionComponent, useState, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Case } from "../../../../lib/interfaces/case.interface";
import { colors } from "../../../../theme/colors";
import { ButtonBase, InformationItem } from "../../../../lib/components";
import { formatDateTimeFullForm } from "../../../../lib/utils/format-date";
// import { CHEST_SIZE } from '../../../../lib/constants/chest-size.constant';
import DeletePatientButton from "../DeletePatientButton";
import EditPatientButton from "../EditPatientButton";
import csvService from "../../../../services/csvService";
import { downloadCSV } from "../../../../lib/utils/download-csv";
import { TFunction } from "i18next";
import usePatientCaseLoader from "../../../../hooks/usePatientCaseLoader";
import CircularProgressWithLabel from "../../../../lib/components/CircularProgressWithLabel";
import { LOADERT_CSV_MAIN } from "../../../../lib/constants/loader-name.constant";
import moment from "moment";
import { ReactComponent as DownloadCsv } from '../../../../theme/icons/download-csv.svg';
import {UserSettings} from "../../../../lib/interfaces/user-settings.interface";
import {TIME_FORMAT} from "../../../../lib/constants/date-format.constant";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingLeft: 23,
      paddingRight: 25,
      paddingTop: 20,
      paddingBottom: 20,
      display: "flex",
      alignItems: "center",
      backgroundColor: colors.white,
      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.15)',
      clipPath: 'inset(0px 0px -6px 0px)',
    },
    info: {
      display: "flex",
      flexWrap: "wrap",
      gap: 20,
    },
    actions: {
      display: "flex",
      width: "10%",
      alignItems: "flex-start",
      justifyContent: "flex-end",
      marginLeft: "auto",
      gap: 24
    },
    progress: {
      padding: 5,
      marginLeft: 10,
    },
    actionButton: {
      borderRadius: "50%",
    },
  })
);

interface Props {
  patientCase: Case;
  canShowButton: boolean;
  userSettings: UserSettings | null;
}

const PatientInfo: FunctionComponent<Props> = memo(
  ({ patientCase, canShowButton, userSettings }) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const csvLoader = usePatientCaseLoader();
    const patientName = `${patientCase.patientFirstName} ${patientCase.patientLastName}`;

    const [measurementFormat, setMeasurementFormat] = useState(
      userSettings?.measurementFormat
    );

    useEffect(() => {
      setMeasurementFormat(userSettings?.measurementFormat);
      // eslint-disable-next-line
    }, [userSettings?.measurementFormat]);

    const downloadPatientCSV =
      (patientCase: Case, t: TFunction) => () => {
        const { patientId } = patientCase;
        measurementFormat && csvService
          .getCaseData(patientCase, t, measurementFormat)
          .subscribe({
            next: (data: any[]) =>
              downloadCSV(`Export of patient ${patientId}`, data),
            complete: csvService.resetLoader,
          });
      };

    return (
      <div className={styles.container}>
        <div className={styles.info}>
          <InformationItem
            labelText={t("label.patientsName")}
            dataText={patientName}
          />

          <InformationItem
            labelText={t("label.patientsMedicalId")}
            dataText={patientCase.patientId}
          />

          <InformationItem
            labelText={t("label.sex")}
            dataText={patientCase.sex ? t(`sex.${patientCase.sex}`) : "-"}
          />

          <InformationItem
            labelText={t("label.age")}
            dataText={patientCase.age ? `${patientCase.age}` : "-"}
          />

          <InformationItem
            labelText={t("label.dateCreated")}
            dataText={formatDateTimeFullForm(
              patientCase ? patientCase.initiationDate : null
            )}
          />

          <InformationItem
            labelText={t("label.dataLastUpdated")}
            dataText={formatDateTimeFullForm(
              patientCase ? patientCase.lastMeasurementDate : null
            )}
          />

          <InformationItem
            labelText={t("label.currenTime")}
            dataText={moment().format(TIME_FORMAT)}
          />
        </div>
        <div className={styles.actions}>
          {canShowButton && (
            <div className={styles.actionButton}>
              <EditPatientButton patientCase={patientCase} />
            </div>
          )}

          {csvLoader && csvLoader.name === patientCase.patientId ? (
            <div className={styles.progress}>
              <CircularProgressWithLabel
                size={40}
                value={Number(csvLoader.value)}
              />
            </div>
          ) : (
            <ButtonBase
              className={styles.actionButton}
              onClick={
                csvLoader && csvLoader.name === LOADERT_CSV_MAIN
                  ? () => {}
                  : downloadPatientCSV(patientCase, t)
              }
            >
              <DownloadCsv />
            </ButtonBase>
          )}

          {canShowButton && (
            <div className={styles.actionButton}>
              <DeletePatientButton patientId={patientCase.patientId} />
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default PatientInfo;
