import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { object as yupObject, string as yupString } from 'yup';
import { TFunction } from 'i18next';
import { formSubmission } from '../../../../lib/utils/form-submission';
import {CircularProgress, IconButton, Select, TextField} from '../../../../lib/components';
import { DeviceForm } from '../../interfaces/form.interface';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      marginTop: 15
    },
    item: {
      marginRight: 15,
    },
    select: {
      width: 280,
    }
  })
);

interface Props {
  name?: string;
  attribute?: string;
  type?: string;
  shouldDisableAttribute?: boolean;
  submitForm: (values: DeviceForm, event: () => void) => void;
  cancel: () => void;
}

const validationSchema = (t: TFunction) => yupObject().shape({
  name: yupString().required(t('validation.deviceNameRequired')),
  attribute: yupString()
    .required(t('validation.deviceIdRequired'))
    .matches(
      /^[a-zA-Z0-9]*$/, //todo: extract to constant
      t('validation.onlyLettersAndNumbersAreAllowed')
    ),
  type: yupString().required(t('validation.defaultRequired')),
});

const DeviceAddNewForm: FunctionComponent<Props> = memo(({
  name, attribute, type, submitForm, cancel, shouldDisableAttribute
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const renderForm = ({
    values,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
    isSubmitting,
  }: FormikProps<DeviceForm>) => {

    const onBlur = (name: string) => () => setFieldTouched(name);
    const onChangeText = (name: string) => (value: string) => setFieldValue(name, value);
    const deviceList = ['medical', 'eBeat'].map(type => ({value: type, text: t(`deviceType.${type}`)}));

    return (
      <div className={styles.container}>
        <div className={styles.item}>
          <TextField
            label={t('placeholder.deviceAttribute')}
            onChange={onChangeText('attribute')}
            onBlur={onBlur('attribute')}
            value={values.attribute}
            error={Boolean(touched.attribute && errors.attribute)}
            disabled={isSubmitting || shouldDisableAttribute}
            required={true}
            helperText={(touched.attribute && errors.attribute) ? errors.attribute : undefined}
          />
        </div>

        <div className={styles.item}>
          <TextField
            label={t('placeholder.deviceName')}
            onChange={onChangeText('name')}
            onBlur={onBlur('name')}
            value={values.name}
            error={Boolean(touched.name && errors.name)}
            disabled={isSubmitting}
            required={true}
            helperText={(touched.name && errors.name) ? errors.name : undefined}
          />
        </div>
        <div className={styles.item}>
          <div className={styles.select}>
            <Select
              elements={deviceList}
              placeholder={t('placeholder.deviceType')}
              value={String(values.type)}
              onChange={onChangeText('type')}
              required
              disabled={isSubmitting}
              error={Boolean(touched.type && errors.type)}
              helperText={(touched.type && errors.type) ? errors.type : undefined}
            />
          </div>
        </div>

        {
          isSubmitting ?
            <CircularProgress /> 
          :
            <>
              <div className={styles.item}>
                <IconButton
                  onClick={() => handleSubmit()}
                >
                  check
                </IconButton>
              </div>

              <div className={styles.item}>
                <IconButton
                  onClick={() => cancel()}
                >
                  clear
                </IconButton>
              </div>
            </>
        }
        
      </div>
  )};

  return (
    <Formik
      initialValues={{ name: name || '', attribute: attribute || '', type: type || '' }}
      onSubmit={formSubmission<DeviceForm>(submitForm)}
      validationSchema={validationSchema(t)}
    >
      {renderForm}
    </Formik>
  );
});

export default DeviceAddNewForm;
