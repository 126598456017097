import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
// import { useTranslation } from 'react-i18next';
import MUIChip from '@material-ui/core/Chip'

const useStyles = makeStyles(() =>
  createStyles({
    root: {}
  })
);

interface Props {
  label: string;
}

const Chip: FunctionComponent<Props> = memo(({ label }) => {
  const styles = useStyles();

  return (
    <MUIChip className={styles.root} color="primary" clickable={false} label={label} />
  );
});

export default Chip;
