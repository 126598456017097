import React, { FunctionComponent } from 'react';

interface Props {
  width: number;
  height: number;
}

export const PositionUprightIcon: FunctionComponent<Props> = ({width, height}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none">
      <path d="M10.25 30L15.5 19.5M15.5 19.5L20.75 30M15.5 19.5V12.5M5 9L15.5 12.5M15.5 12.5L26 9" stroke="#EF7A04"
            strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M18.5 5C18.5 5.79565 18.1839 6.55871 17.6213 7.12132C17.0587 7.68393 16.2956 8 15.5 8C14.7044 8 13.9413 7.68393 13.3787 7.12132C12.8161 6.55871 12.5 5.79565 12.5 5C12.5 4.20435 12.8161 3.44129 13.3787 2.87868C13.9413 2.31607 14.7044 2 15.5 2C16.2956 2 17.0587 2.31607 17.6213 2.87868C18.1839 3.44129 18.5 4.20435 18.5 5Z"
        stroke="#EF7A04" strokeWidth="2.5"/>
    </svg>
  );
};


