import React, { FunctionComponent } from 'react';
import { ReactComponent as Spinner } from '../common/spinner.svg';
import {createStyles, makeStyles} from '@material-ui/core';
import {colors} from "../../../theme/colors";

const useStyles = makeStyles(() =>
  createStyles({
    spinner: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 20,
      flex: 1,
      justifyContent: 'center'
    },
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    },
    spinnerIcon: {
      width: 45,
      height: 45,
      animation: '$spin 2s linear infinite',
    },
    spinnerText: {
      fontFamily: 'Raleway, sans-serif',
      fontSize: 18,
      fontWeight: 500,
    },
  })
);

interface Props {
  text: string;
  textColor?: string;
}

const LoadingDataSpinner: FunctionComponent<Props> = ({text, textColor = colors.blackTheme.popupTextActiveColor}) => {
  const styles = useStyles();

  return (
    <div className={styles.spinner}>
      <div className={styles.spinnerIcon}>
        <Spinner/>
      </div>
      <div className={styles.spinnerText} style={{color: textColor}}>
        {text}
      </div>
    </div>
  );
};

export default LoadingDataSpinner;
