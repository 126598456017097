import React, { FunctionComponent, } from 'react';
import {makeStyles, createStyles} from '@material-ui/core';
import {colors} from "../../../theme/colors";

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      height: 40,
      backgroundColor: colors.blackTheme.buttonPrimaryBackground,
      borderColor: colors.blackTheme.buttonPrimaryBackground,
      borderWidth: 1,
      borderStyle: 'solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 51,
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: 17,
      color: colors.blackTheme.buttonTextColor,
      fontFamily: 'Raleway, sans-serif',
    },
  })
);

type ButtonType = 'primary' | 'secondary';

interface Props {
  title: string;
  onClick?: () => void;
  grow?: boolean;
  height?: number;
  width?: number;
  bold?: boolean;
  type?: ButtonType;
  disabled?: boolean;
  backgroundColor?: string;
  fontColor?: string;
  borderColor?: string;
}

const Button: FunctionComponent<Props> = (
  {
    title,
    onClick,
    grow = false,
    height,
    bold = false,
    type = 'primary',
    disabled = false,
    width = 150,
    backgroundColor,
    fontColor,
    borderColor
  }) => {
    type EnablingType = 'disabled' | 'enabled';
    const buttonStyles: Record<ButtonType, Record<EnablingType, any>> = {
      'primary': {
        'disabled': {
          backgroundColor: colors.blackTheme.buttonPrimaryDisabledBackground,
          color: colors.blackTheme.buttonTextColor,
          borderColor: colors.blackTheme.buttonPrimaryDisabledBackground,
        },
        'enabled': {
          backgroundColor: colors.blackTheme.buttonPrimaryBackground,
          color: colors.blackTheme.buttonTextColor,
          borderColor: colors.blackTheme.buttonPrimaryBackground,
        },
      },
      'secondary': {
        'disabled': {
          backgroundColor: colors.blackTheme.buttonSecondaryBackground,
          color: colors.blackTheme.buttonSecondaryTextColor,
          borderColor: colors.blackTheme.buttonPrimaryBackground,
        },
        'enabled': {
          backgroundColor: colors.blackTheme.buttonSecondaryBackground,
          color: colors.blackTheme.buttonSecondaryTextColor,
          borderColor: colors.blackTheme.buttonPrimaryBackground,
        },
      },
    }
    const enablingState: EnablingType = disabled ? 'disabled' : 'enabled';
    const styles = useStyles();
    return (
      <div
        className={styles.button}
        style={{
          flexBasis: grow ? '100%' : 'auto',
          fontWeight: bold ? 'bold' : 600,
          backgroundColor: backgroundColor ? backgroundColor : buttonStyles[type][enablingState].backgroundColor,
          color: fontColor ? fontColor : buttonStyles[type][enablingState].color,
          borderColor: borderColor ? borderColor : buttonStyles[type][enablingState].borderColor,
          height: height ? height : 40,
          width
      }}
        onClick={disabled ? undefined : onClick}
      >
        {title}
      </div>
    );
};

export default Button;
