import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '../../../../lib/components';
import { useHistory } from 'react-router-dom';
import { NavRoute } from '../../../../lib/constants/nav-route.constant';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingLeft: 22,
      paddingTop: 50
    },
    title: {
      width: 300,
      whiteSpace: 'normal',
      fontSize: 18,
      lineHeight: '149.3%'
    },
    button: {
      width: 120,
      marginTop: 45,
      paddingLeft: 5,
      paddingRight: 5,
      color: '#5A69AE',
      fontSize: 16,
      fontWeight: 'bold'
    }
  })
);

const PatientsEmpty: FunctionComponent = memo(() => {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const navigateToCreationFlow = () => {
    history.push({
      pathname: NavRoute.MAIN.CREATE
    });
  }

  return (
    <div className={styles.container}>
      <Typography className={styles.title}>{t('text.noUserTitle')}</Typography>
      <div>
        <Button
          rootClass={styles.button}
          onClick={navigateToCreationFlow}
          variant="text"
        >
          +{t('button.addPatient')}
        </Button>
      </div>
    </div>
  );
});

export default PatientsEmpty;
